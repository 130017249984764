import { LitElement, html, css } from 'lit';
import './spinner-element';
import './news-element';
import './specific-news-element';
import './news-filter-element';

const FINANCE_API_KEY = 'f27bbd28829cfd17713d89497a16ae75';
const GOOGLE_API_KEY = 'AIzaSyAcwLc7fO1RjJxq7a3aFAFUHafO04rdDPc';
const DISCOVERY_DOC = 'https://sheets.googleapis.com/$discovery/rest?version=v4';

class NewsWrapperElement extends LitElement {
  static get properties() {
    return {
      activeTab: Number,
      allCryptoNews: Array,
      allStockNews: Array,
      canKeepScrolling: Boolean,
      filteredCryptoNews: Array,
      filteredStockNews: Array,
      isFetchingStockNews: Boolean,
      loadingMore: Boolean,
      selectedPageNumber: Number,
      selectedSymbol: String,
      sheetData: Array,
      symbols: Array,
      spreadsheetId: String,
    };
  }

  constructor() {
    super();
    this.activeTab = 0;
    this.allCryptoNews = [];
    this.canKeepScrolling = true;
    this.filteredCryptoNews = [];
    this.filteredStockNews = [];
    this.allStockNews = [];
    this.loadingMore = false;
    this.selectedPageNumber = 1;
    this.sheetData = [];
    this.spreadsheetId = localStorage.getItem('spreadsheet_id');
    this.symbols = [];

    this._boundPageChanged = this.handlePageChanged.bind(this);
    this._boundViewAll = this.handleViewAll.bind(this);
    this._boundFilteredArticles = this.handleFilteredArticles.bind(this);
  }

  async connectedCallback() {
    super.connectedCallback();

    this.filteredStockNews = await this.getStockNewsForPage(this.selectedPageNumber);
    this.filteredCryptoNews = await this.getCryptoNewsForPage(this.selectedPageNumber);

    document.addEventListener('page-changed', this._boundPageChanged);
    document.addEventListener('view-all', this._boundViewAll);
    document.addEventListener('filtered-articles', this._boundFilteredArticles);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    document.removeEventListener('page-changed', this._boundPageChanged);
    document.removeEventListener('view-all', this._boundViewAll);
    document.removeEventListener('filtered-articles', this._boundFilteredArticles);
  }

  async firstUpdated() {
    this.getSheetData();
  }

  async getAllStockNews(limit) {
    return await fetch(`https://financialmodelingprep.com/api/v3/stock_news?limit=${limit}&apikey=${FINANCE_API_KEY}`)
      .then(response => response.json());
  }

  async getAllCryptoNews(page) {
    return await fetch(`https://financialmodelingprep.com/api/v4/crypto_news?page=${page}&apikey=${FINANCE_API_KEY}`)
      .then(response => response.json());
  }

  async getCryptoNewsForPage(page) {
    const articlesPerPage = 15;
    const startIndex = (page - 1) * articlesPerPage;
    const endIndex = startIndex + articlesPerPage;
    const groupNumber = Math.ceil(page / 4);
    const offsetWithinGroup = (page - 1) % 4;

    if (offsetWithinGroup === 0 && !this.isFetchingStockNews) {
      this.isFetchingStockNews = true;
      await this.fetchAndAppendCryptoNews(groupNumber);
      this.isFetchingStockNews = false;
    }

    return this.allCryptoNews.slice(0, endIndex);
  }
  
  async fetchAndAppendCryptoNews(groupNumber) {
    const newCryptoNews = [];

    for (let i = 1; i <= 3; i++) {
      const result = await this.getAllCryptoNews(groupNumber * 4 + i);

      if (result && result.length > 0) {
        // Filter out articles with an empty string image
        const filteredResult = result.filter(article => article.image !== '');

        newCryptoNews.push(...filteredResult);
      }
    }

    return this.allCryptoNews.push(...newCryptoNews);
  }

  async getSheetData() {
    if (!this.spreadsheetId) {
      return;
    }

    try {
      await gapi.load('client', () => {
        gapi.client.init({
          apiKey: GOOGLE_API_KEY,
          discoveryDocs: [DISCOVERY_DOC],
        }).then(() => {
          gapi.client.load('sheets', 'v4', () => {
            gapi.client.sheets.spreadsheets.values.get({
              spreadsheetId: this.spreadsheetId,
              range: 'Sheet1!A:M',
            }).then((response) => {
              this.sheetData = response.result.values;
            }).catch((error) => {
              // Handle the error from fetching sheet data
              console.error("Error fetching sheet data:", error);
            });
          });
        });
      });
    } catch (error) {
      console.error(error);
    }
  }

  async getStockNewsForPage(page) {
    const articlesPerPage = 60;
    const startIndex = (page - 1) * articlesPerPage;
    const endIndex = startIndex + articlesPerPage;
  
    // Check if articles for this page have already been fetched
    if (!this.isFetchingStockNews) {
      this.isFetchingStockNews = true;
      await this.fetchAndAppendStockNews();
      this.isFetchingStockNews = false;
    }
  
    return this.allStockNews.slice(0, endIndex);
  }
  
  async fetchAndAppendStockNews() {
    const result = await this.getAllStockNews(300);
  
    // Filter out articles with an empty string image
    const filteredResult = result.filter(article => article.image !== '');
  
    this.allStockNews.push(...filteredResult);
  }

  handleFilteredArticles({detail}) {
    const isStockNews = Boolean(this.activeTab === 1);
    this.canKeepScrolling = detail.canKeepScrolling;
    if (isStockNews) {
      this.filteredStockNews = detail.filteredNews;
    } else {
      this.filteredCryptoNews = detail.filteredNews;
    }
  }

  async handlePageChanged({ detail }) {
    this.selectedPageNumber = detail.page;

    this.loadingMore = true;

    if (this.activeTab === 1) {
      this.filteredStockNews = await this.getStockNewsForPage(this.selectedPageNumber);
    } else if (this.activeTab === 2) {
      this.filteredCryptoNews = await this.getCryptoNewsForPage(this.selectedPageNumber);
    }
  }

  handleViewAll({detail}) {
    if (detail.text === 'Stock News') {
      this.activeTab = 1;
    } else {
      this.activeTab = 2;
    }
  }

  selectTab(tabIndex) {
    this.activeTab = tabIndex;
  }

  static get styles() {
    return css`
      .news-container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin: 48px;
      }

      .header-container {
        display: flex;
        width: 1324px;
        height: 46px;
        flex-shrink: 0;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
      }

      .tabs-container {
        height: 32px;
        padding: 6px;
        flex-shrink: 0;
        background: #FFF;
        backdrop-filter: blur(10px);
      }

      .tab {
        display: inline-flex;
        color: #2E2E3A;
        fill: #2E2E3A;
        text-decoration: none;
        padding: 8px 10px;
        justify-content: center;
        align-items: center;
        gap: 8px;
      }

      .tab.active {
        color: #FFFFFF;
        fill: #FFFFFF;
        background: #3346E7;
      }

      .icon {
        vertical-align: middle;
        width: 16px;
        height: 16px;
      }

      .tab-text {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: -0.14px;
      }

      .card {
        width: 1260px;
        flex-shrink: 0;
        padding: 32px;
        background: #FFF;
      }
    `;
  }

  render() {
    return html`
      <div class="news-container">
        <div class="header-container">
          <div class="tabs-container">
            <a href="#" name="tab1" class="tab ${this.activeTab === 0 ? 'active' : ''}" @click="${() => this.selectTab(0)}">
              <box-icon class="icon" name='news'></box-icon>
              <span class="tab-text">All</span>
            </a>
            <a href="#" name="tab2" class="tab ${this.activeTab === 1 ? 'active' : ''}" @click="${() => this.selectTab(1)}">
              <box-icon class="icon" type='solid' name='book-content'></box-icon>
              <span class="tab-text">Stock News</span>
            </a>
            <a href="#" name="tab2" class="tab ${this.activeTab === 2 ? 'active' : ''}" @click="${() => this.selectTab(2)}">
              <box-icon class="icon" type='solid' name='book-content'></box-icon>
              <span class="tab-text">Crypto News</span>
            </a>
          </div>
        </div>
        <news-filter-element
          .activeTab=${this.activeTab}
          .allCryptoNews=${this.allCryptoNews}
          .allStockNews=${this.allStockNews}
          .filteredCryptoNews=${this.filteredCryptoNews}
          .filteredStockNews=${this.filteredStockNews}
          .sheetData=${this.sheetData}>
        </news-filter-element>
        <div class="card" style=${this.activeTab === 0 ? '' : 'display:none'}>
          <news-element .cryptoNews=${this.filteredCryptoNews} .stockNews=${this.filteredStockNews}></news-element>
        </div>
        <div class="card" style=${this.activeTab === 1 ? '' : 'display:none'}>
          <specific-news-element .canKeepScrolling=${this.canKeepScrolling} .news=${this.filteredStockNews} .loadingMore=${this.loadingMore} .selectedPageNumber=${this.selectedPageNumber} .title=${'Stock News'}></specific-news-element>
        </div>
        <div class="card" style=${this.activeTab === 2 ? '' : 'display:none'}>
          <specific-news-element .canKeepScrolling=${this.canKeepScrolling} .news=${this.filteredCryptoNews} .loadingMore=${this.loadingMore} .selectedPageNumber=${this.selectedPageNumber} .title=${'Crypto News'}></specific-news-element>
        </div>
      </div>
    `;
  }
}

customElements.define('news-wrapper-element', NewsWrapperElement);