import { LitElement, html, css } from 'lit';
import carbonArrow from './images/carbon_arrow-up.svg';
import './spinner-element';
import { addCommasToNumber } from './util';

const GOOGLE_API_KEY = 'AIzaSyAcwLc7fO1RjJxq7a3aFAFUHafO04rdDPc';
const DISCOVERY_DOC = 'https://sheets.googleapis.com/$discovery/rest?version=v4';

class PortfolioStatsCardElement extends LitElement {
  static get properties() {
    return {
      capitalGain: { type: Number },
      capitalGainPercent: { type: Number },
      dataTable: { type: Object },
      dividendCount: { type: Number },
      dividendCountPercent: { type: Number },
      from: { type: String },
      loadingStats: { type: Boolean },
      portfolioReturn: { type: Number },
      portfolioReturnPercent: { type: Number },
      spreadsheetId: { type: String },
      to: { type: String },
    };
  }

  constructor() {
    super();
    this.capitalGain = 0;
    this.capitalGainPercent = 0;
    this.dividendCount = 0;
    this.dividendCountPercent = 0;
    this.loadingStats = true;
    this.portfolioReturn = 0;
    this.portfolioReturnPercent = 0;
    this.spreadsheetId = localStorage.getItem('spreadsheet_id');
  }

  async connectedCallback() {
    super.connectedCallback();
    await this.loadDateRangeStats();
  }

  updated(changedProperties) {
    if (changedProperties.has('from') || changedProperties.has('to') || changedProperties.has('dataTable')) {
      this.loadDateRangeStats();
    }
  }

  async loadDateRangeStats() {
    if (!this.spreadsheetId) {
      return;
    }

    await gapi.load('client', async () => {
      await gapi.client.init({
        apiKey: GOOGLE_API_KEY,
        discoveryDocs: [DISCOVERY_DOC],
      });
  
      await gapi.client.load('sheets', 'v4');
      const spreadsheetId = this.spreadsheetId;
      const portfolioRange = 'Sheet1!A2:L';

      let portfolioData;
      try {
        portfolioData = await gapi.client.sheets.spreadsheets.values.get({
          spreadsheetId,
          range: portfolioRange,
        });
      } catch (portfolioError) {
        // Handle the error from the portfolio data fetch
        console.error("Error fetching portfolio data:", portfolioError);
        // You can implement specific error-handling logic here.
        return;
      }

      const portfolioRows = portfolioData.result.values;
  
      if (!portfolioRows) {
        return;
      }

      let startValue = 0;
      let endValue = 0;

      if (Object.values(this.dataTable).length !== 0 && this.dataTable.getNumberOfRows() > 0) {
        const numRows = this.dataTable.getNumberOfRows();
        const numColmns = this.dataTable.getNumberOfColumns();

        for (let i = 1; i < numColmns; i++) {
          startValue += parseFloat(this.dataTable.getValue(0, i));
          endValue += parseFloat(this.dataTable.getValue(numRows - 1, i));
        }
      }

      const fromDate = new Date(this.from);
      fromDate.setHours(0, 0, 0, 0);
      const toDate = new Date(this.to);
      toDate.setHours(0, 0, 0, 0);
  
      let totalContributions = 0;
      let weightedContributions = 0;
      let dividendAmount = 0;
  
      for (const row of portfolioRows) {
        const type = row[1];
        const date = new Date(row[0]);
        const purchaseAmount = parseFloat(row[7]);
  
        if (date >= fromDate && date <= toDate) {
          totalContributions += purchaseAmount;
  
          const daysWeight = (toDate - date) / (24 * 60 * 60 * 1000); // Number of days from purchase to end date
          weightedContributions += purchaseAmount * (daysWeight / 365); // Assuming 365 days in a year
  
          if (type === 'Reinvestment') {
            dividendAmount += purchaseAmount;
          }
        }
      }
  
      // Calculate the money-weighted return (MWR)
      const mwr = (endValue - startValue - totalContributions) / (startValue + weightedContributions);

      this.portfolioReturn = addCommasToNumber((endValue - startValue).toFixed(2));
      this.portfolioReturnPercent = addCommasToNumber((mwr * 100).toFixed(2));
      this.dividendCount = addCommasToNumber((dividendAmount).toFixed(2));
      if (startValue !== 0) {
        this.dividendCountPercent = addCommasToNumber(((dividendAmount / startValue) * 100).toFixed(2));
      } else {
        this.dividendCountPercent = addCommasToNumber(((dividendAmount / endValue) * 100).toFixed(2));
      }
      this.capitalGain = addCommasToNumber(((endValue - startValue) - dividendAmount).toFixed(2));
      this.capitalGainPercent = addCommasToNumber((this.portfolioReturnPercent - this.dividendCountPercent).toFixed(2));
    });
  }
  

  static get styles() {
    return css`
      .portfolio-stats-container {
        display: inline-flex;
        align-items: flex-start;
        flex-direction: column;
        gap: 32px;
      }

      .portfolio-stats-card {
        display: inline-flex;
        width: 302px;
        height: 124px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        overflow: hidden;
        background-color: #fff;
        flex-direction: column;
        justify-content: space-between;
      }

      .stats-card-details {
        display: flex;
        width: 262px;
        height: 124px;
        flex-direction: row;
        justify-content: space-between;
        padding: 20px;
      }
  
      .content-container {
        display: flex;
        width: 313px;
        justify-content: space-between;
        align-items: flex-start;
      }

      .content-block {
        display: inline-flex;
        flex-direction: column;
        gap: 20px;
      }
      
      .content-container:first-child {
        text-align: left;
      }
  
      .content-header {
        color: #9A9AAF;
        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 16px;
        letter-spacing: -0.16px;
      }

      spinner-element {
        width: 16px;
        height: 16px;
      }
  
      .content-value {
        color: #2E2E3A;
        font-family: 'Montserrat', sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: -0.24px;
      }

      .content-value-small {
        align-items: end;
        color: #F84439;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: -0.14px;
      }

      .positive {
        color: #24CA49;
      }
      
      .negative {
        color: #F84439;
      }

      img {
        margin-top: 6px;
        margin-left: 6px;
      }
      
      .circle {
        width: 32px;
        height: 32px;
        flex-shrink: 0;
        border-radius: 16px;
        border: 1px solid #F2F3F7;
        background: #FFF;
      }

      .circle.arrow-1 {
        color: #F84439;
      }

      .circle.arrow-2 {
        fill: #F84439;
      }

      .circle.arrow-3 {
        fill: #24CA49;
      }
    `;
  }
  
  render() {
    const dividendCountPercent = parseFloat(this.dividendCountPercent);
    const capitalGainPercent = parseFloat(this.capitalGainPercent);
    const portfolioReturnPercent = parseFloat(this.portfolioReturnPercent);

    return html`
      <div class="portfolio-stats-container">
        <div class="portfolio-stats-card">
          <div class="stats-card-details">
            <div class="content-block">
              <text class="content-header">Dividends</text>
              ${this.loadingStats
                ? html`<spinner-element></spinner-element>`
                : html`
                  <div class="content-value-container">
                    <div class="content-value">$${this.dividendCount == 0 ? '0.00' : this.dividendCount}</div>
                    <div class="content-value-small ${dividendCountPercent >= 0 ? 'positive' : 'negative'}">(${this.dividendCountPercent == 0 ? '0.00' : this.dividendCountPercent}%)</div>
                  </div>
                `
              }
            </div>
            <div class="circle arrow-1">
              <img src="${carbonArrow}" alt="Arrow" />
            </div>
          </div>
        </div>
        <div class="portfolio-stats-card">
          <div class="stats-card-details">
            <div class="content-block">
              <text class="content-header">Capital Gains</text>
              ${this.loadingStats
                ? html`<spinner-element></spinner-element>`
                : html`
                  <div class="content-value-container">
                    <div class="content-value">$${this.capitalGain == 0 ? '0.00' : this.capitalGain}</div>
                    <div class="content-value-small ${capitalGainPercent >= 0 ? 'positive' : 'negative'}">(${this.capitalGainPercent == 0 ? '0.00' : this.capitalGainPercent}%)</div>
                  </div>
                `
              }
            </div>
            <div class="circle arrow-2">
              <img src="${carbonArrow}" alt="Arrow" />
            </div>
          </div>
        </div>
        <div class="portfolio-stats-card">
          <div class="stats-card-details">
            <div class="content-block">
              <text class="content-header">Total Return</text>
              ${this.loadingStats
                ? html`<spinner-element></spinner-element>`
                : html`
                  <div class="content-value-container">
                    <div class="content-value">$${this.portfolioReturn == 0 ? '0.00' : this.portfolioReturn}</div>
                    <div class="content-value-small ${portfolioReturnPercent >= 0 ? 'positive' : 'negative'}">(${this.portfolioReturnPercent == 0 ? '0.00' : this.portfolioReturnPercent}%)</div>
                  </div>
                `
              }
            </div>
            <div class="circle arrow-3">
              <img src="${carbonArrow}" alt="Arrow" />
            </div>
          </div>
        </div>
      </div>
    `;
  }
}

customElements.define('portfolio-stats-card-element', PortfolioStatsCardElement);
