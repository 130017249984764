import { LitElement, html, css } from 'lit';

class NewsArticleElement extends LitElement {
  static get properties() {
    return {
      article: Object,
    };
  }

  constructor() {
    super();
  }

  connectedCallback() {
    super.connectedCallback();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }

  formatDate(dateString) {
    const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', timeZone: 'America/Chicago' };
    const date = new Date(dateString);
    const formattedDate = date.toLocaleString('en-US', options);

    return `${formattedDate}`;
  }

  handleArticleClick(url) {
    window.open(url, '_blank');
  }

  static get styles() {
    return css`
      .article {
        display: flex;
        width: 360px;
        height: 428px;
        padding-top: 20px;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        cursor: pointer;
        background: #F2F3F7;
        position: relative;
      }

      .article-image {
        flex: 1;
        max-height: 187px;
        position: relative;
      }
  
      .article-image img {
        width: 287px;
        height: 183px;
        flex-shrink: 0;
      }
  
      .bubble {
        position: absolute;
        height: 16px;
        top: 10px;
        right: 10px;
        color: #ffffff;
        display: inline-flex;
        padding: 4px 16px;
        align-items: center;
        gap: 10px;
        border-radius: 40px;
        background: #3346E7;
        font-family: 'Montserrat', sans-serif;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.3px;
      }

      .article-content {
        display: flex;
        padding: 0px 20px;
        flex-direction: column;
        align-items: flex-start;
        gap: 19px;
        flex-grow: 1;
      }

      .article-text-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
      }
  
      .article-title {
        overflow: hidden;
        max-height: 70px;
        color: #2E2E3A;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: 'Montserrat', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 120% */
      }
  
      .article-text {
        overflow: hidden;
        max-height: 80px;
        color: #2E2E3A;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 20px;
        letter-spacing: -0.28px;
      }

      .article-footer {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        padding: 16px 20px;
        justify-content: space-between;
        align-items: center;
      }
  
      .article-site {
        font-size: 14px;
        color: #666;
      }
  
      .article-date {
        font-size: 14px;
        color: #666;
        text-align: right;
      }
    `;
  }

  render() {
    return html`
      <div class="article" @click="${() => this.handleArticleClick(this.article.url)}">
        <div class="article-image">
          <img src="${this.article.image}" alt="Article Image" />
          ${this.article.symbol
            ? html`<div class="bubble">${this.article.symbol}</div>`
            : ''}
        </div>
        <div class="article-content">
          <div class="article-text-container">
            <div class="article-title">${this.article.title}</div>
            <div class="article-text">${this.article.text}</div>
          </div>
          <div class="article-footer">
            <div class="article-site">${this.article.site}</div>
            <div class="article-date">${this.formatDate(this.article.publishedDate)}</div>
          </div>
        </div>
      </div>
    `;
  }
}

customElements.define('news-article-element', NewsArticleElement);