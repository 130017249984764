import { LitElement, html, css } from 'lit';

class SpinnerElement extends LitElement {
  static get styles() {
    return css`
      :host {
        display: inline-block;
        width: 1.5rem;
        height: 1.5rem;
        position: relative;
        border-radius: 50%;
        border: 0.25rem solid rgba(0, 0, 0, 0.1);
        border-top-color: #333;
        animation: spinner 1s linear infinite;
      }

      @keyframes spinner {
        to {
          transform: rotate(360deg);
        }
      }
    `;
  }

  render() {
    return html``;
  }
}

customElements.define('spinner-element', SpinnerElement);