import { LitElement, html, css } from 'lit';
import './modal-element';

class WhatsNewModal extends LitElement {
  static get properties() {
    return {
      currentVersion: String,
      releaseDate: String,
    };
  }

  constructor() {
    super();
  }

  connectedCallback() {
    super.connectedCallback();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }

  handleCloseClick() {
    const modalElement = this.shadowRoot.querySelector('modal-element');
    modalElement.removeAttribute('show');
  }

  static get styles() {
    return css`
      .whats-new-text {
        margin-bottom: 20px;
        color: #2E2E3A;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: -0.14px;
      }

      .modal-element {
        --text-alignment: flex-start;
      }

      .sub-header {
        display: flex;
        align-items: center;
        gap: 10px;
      }

      .version-text {
        color: #9A9AAF;
        font-size: 10px;
      }

      .paragraph-body {
        width: 100%;
        max-width: 100%;
        overflow-wrap: break-word;
      }
    `;
  }
  
  render() {
    return html`
      <modal-element class="modal-element" @close=${this.handleCloseClick} isOneButton="${true}">
        <div slot="modal-slot">
          <div class="whats-new-text">
            <h1>What's New</h1>
            <div class="sub-header">
              <h3>${this.releaseDate}</h3>
              <p class="version-text">v${this.currentVersion}</p>
            </div>
            <p class="paragraph-body">
              Welcome beta testers!<br><br>
              The first beta version of Finovva is now LIVE and (probably) full of bugs! Help me find them!<br><br>
              You will see this pop-up when new features/bug fixes get released (there will be tons!)<br><br>
              There will be a few main goals of this beta period:<br>
              <ol>
                <li>Find any bugs that might exist in the current features - this means you should TRY to push the limits of the product.</li>
                <li>Give feedback for new features or features you may find unnecessary.</li>
              </ol><br>
              Expect things to break. :)<br><br>
              aaaaand... Enjoy a FREE portfolio analyzer for the time being.
            </p>
          </div>
        </div>
      </modal-element>
    `;
  }
}

customElements.define('whats-new-modal', WhatsNewModal);