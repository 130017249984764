import { LitElement, html, css } from 'lit';
import Fuse from 'fuse.js';
import './modal-element';
import '../datepicker-input-element';

const FINANCE_API_KEY = 'f27bbd28829cfd17713d89497a16ae75';

class AddBenchmarkModal extends LitElement {
  static get properties() {
    return {
      activeTicker: Object,
      filteredStockList: Array,
      searchText: String,
      slicedStockList: Array,
      stockList: Array,
    };
  }

  constructor() {
    super();
    this.activeTicker = null;
    this.filteredStockList = [];
    this.searchText = '';
    this.stockList = [];
    this.loadStockList();
  }

  connectedCallback() {
    super.connectedCallback();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }

  handleCloseClick() {
    const modalElement = this.shadowRoot.querySelector('modal-element');
    modalElement.removeAttribute('show');
    this.searchText = '';
  }

  handleListItemClick(evt, ticker) {
    this.activeTicker = ticker;
  }

  handleSearchInput(event) {
    this.searchText = event.target.value;
    this.performFiltering();
  }

  handleSubmit() {
    this.searchText = '';
    this.filteredStockList = this.slicedStockList;
    this.dispatchEvent(new CustomEvent('add-benchmark', { bubbles: true, composed: true, detail: this.activeTicker }));
  }

  async loadStockList() {
    try {
      const response = await fetch(`https://financialmodelingprep.com/api/v3/available-traded/list?&apikey=${FINANCE_API_KEY}`);
      if (response.ok) {
        const data = await response.json();
        this.stockList = data;
        this.slicedStockList = data.slice(0, 1000);
        this.filteredStockList = this.slicedStockList;
        this.requestUpdate(); // Update the component to trigger re-render
      } else {
        console.error('Failed to fetch stock data');
      }
    } catch (error) {
      console.error(error);
    }
  }

  performFiltering() {
    if (!this.searchText) {
      // If search text is empty, show all rows
      this.filteredStockList = this.slicedStockList;
    } else {
      let filteredStocks = this.stockList;
  
      if (this.searchText) {
        // Apply the Ticker search if search text is present
        const fuseOptions = {
          keys: ['symbol'],
          threshold: 0,
        };
        
        const fuse = new Fuse(filteredStocks, fuseOptions);
        filteredStocks = fuse.search(this.searchText).map(result => result.item);
      }
  
      this.filteredStockList = filteredStocks;
    }
  }

  static get styles() {
    return css`
      .modal-container {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        flex-direction: column;
        background: #FFF;
        gap: 20px;
      }

      .filter-search {
        display: inline-flex;
        width: 330px;
        padding: 10px;
        align-items: center;
        border: 1px solid #EFF1F7;
        background: #FAFAFC;
      }

      .list-container {
        display: inline-flex;
        overflow-y: auto;
        width: 350px;
        height: 400px;
        flex-direction: column;
        flex-shrink: 0;
        border-radius: 0px 0px 8px 8px;
        background: #FFF;
        margin-bottom: 20px;
        border: 1px solid #F2F3F7;
      }

      .list-item {
        display: inline-flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 10px;
        cursor: pointer;
        border-bottom: 1px solid #F2F3F7;
      }

      .list-item.active,
      .list-item:hover {
        background: #F2F3F7;
      }
    `;
  }
  
  render() {
    return html`
      <modal-element class="modal-element" @close=${this.handleCloseClick} @submit=${this.handleSubmit}>
        <div slot="modal-slot" class="modal-container">
          <div slot="modal-slot" class="header-container">
            <input class="filter-search" placeholder="Search for a benchmark" @input=${this.handleSearchInput} .value=${this.searchText}></input>
          </div>
          <div slot="modal-slot" class="list-container">
            ${this.filteredStockList.map(ticker => {
              return html`
                <div class="list-item ${ticker === this.activeTicker ? 'active' : ''}" @click=${(evt) => this.handleListItemClick(evt, ticker)}>
                  <text class="ticker-value">${ticker.symbol}</text>
                  <text class="ticker-name-value">${ticker.name}</text>
                </div>
              `
            })}
          </div>
        </div>
      </modal-element>
    `;
  }
}

customElements.define('add-benchmark-modal', AddBenchmarkModal);