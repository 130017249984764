import { LitElement, html, css } from 'lit';
import './modals/buy-form-modal';
import './modals/delete-row-modal';
import './modals/edit-row-modal';
import './spinner-element';
import './stocks-filter-element';
import './current-positions-dividends-element';
import trash from './images/trash.svg';
import edit from './images/edit.svg';
import {
  addCommasToNumber,
  addNewRows,
  calculateNumberFields,
  getFirstEmptyRowIndex,
  deleteRowsFromSpreadsheet
} from './util';
import { v4 as uuidv4 } from 'uuid';

const FINANCE_API_KEY = 'f27bbd28829cfd17713d89497a16ae75';
const GOOGLE_API_KEY = 'AIzaSyAcwLc7fO1RjJxq7a3aFAFUHafO04rdDPc';
const DISCOVERY_DOC = 'https://sheets.googleapis.com/$discovery/rest?version=v4';

class StocksBoughtElement extends LitElement {
  static get properties() {
    return {
      activeTab: Number,
      allSellRow: Object,
      currentRow: Object,
      currentTickers: Array,
      filteredSortedDividends: Array,
      filteredTableData: Array,
      todaysDividends: Array,
      todaysSplits: Array,
      interval: Number,
      isBoughtRow: Boolean,
      loadingData: Boolean,
      previousBuyRowData: Object,
      buyRowStorage: Object,
      removedBuyRow: Array,
      rowNumber: Number,
      sellError: Boolean,
      sellSheetData: Array,
      sheetId: String,
      spreadsheetId: String,
      storedDividendUpdates: Object,
      sortedDividends: Array,
      tableData: Array,
    };
  }

  constructor() {
    super();

    this.filteredSortedDividends = [];
    this.loadingData = true;
    this.previousBuyRowData = {};
    this.buyRowStorage = {};
    this.hasCurrentPrices = false;
    this.todaysDividends = [];
    this.todaysSplits = [];
    this.storedDividendUpdates = {};
    this.activeTab = 0;
    this.currentRow = {};
    this.sellError = false;
    this.sellSheetData = [];
    this.spreadsheetId = localStorage.getItem('spreadsheet_id');
    this.sortedDividends = [];
    this.tableData = [];
    this.isBoughtRow = false; 

    this._boundSubmit = this.onSubmit.bind(this);
    this._boundLoadCurrentPositions = this.loadCurrentPositions.bind(this);
    this._boundReloadDividends = this.handleReloadDividends.bind(this);
    this._boundFilteredDividendDataChanged = this.handleFilteredDividendDataChanged.bind(this);
    this._boundFilteredTableDataChanged = this.handleFilteredTableDataChanged.bind(this);
    this._boundSortedDividendDataChanged = this.handleSortedDividendDataChanged.bind(this);
    this._boundReinvestmentDividendsChanged = this.handleReinvestmentDividendsChanged.bind(this);

    this.selectedTransactionType = 'buy';
    gapi.load('client', async () => {
      await gapi.client.init({
        apiKey: GOOGLE_API_KEY,
        discoveryDocs: [DISCOVERY_DOC],
      });
    
      await gapi.client.load('sheets', 'v4');

      await this.getSheetId();
    
      await this.loadCurrentPositionsSpreadsheetData();
      await this.getSellSheetData();

      let lastTimePrices = localStorage.getItem('last_time_current_prices');
      const now = new Date().getTime() / 1000;
      const timeSinceCurrentPrices = now - lastTimePrices;
      // If it has been more than 10 minutes since last current prices
      if (timeSinceCurrentPrices >= 600) {
        this.getCurrentPrices();
      }

      let lastTimeDividendSplit = localStorage.getItem('last_time_dividend_split');
      const timeSinceDividendSplitCheck = now - lastTimeDividendSplit;
      // If it has been more than 24 hours since last dividend/split check
      if (timeSinceDividendSplitCheck >= 86400) {
        this.compareDividendSplitData();
      }
    });
  }

  connectedCallback() {
    super.connectedCallback();
    document.addEventListener('detailed-submit', this._boundSubmit);
    document.addEventListener('load-current-positions', this._boundLoadCurrentPositions);
    document.addEventListener('reload-dividends', this._boundReloadDividends);
    document.addEventListener('filtered-dividend-data', this._boundFilteredDividendDataChanged);
    document.addEventListener('filtered-table-data', this._boundFilteredTableDataChanged);
    document.addEventListener('sorted-dividend-data', this._boundSortedDividendDataChanged);
    document.addEventListener('saved-reinvested-dividends', this._boundReinvestmentDividendsChanged);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    document.removeEventListener('detailed-submit', this._boundSubmit);
    document.removeEventListener('load-current-positions', this._boundLoadCurrentPositions);
    document.removeEventListener('reload-dividends', this._boundReloadDividends);
    document.removeEventListener('filtered-dividend-data', this._boundFilteredDividendDataChanged);
    document.removeEventListener('filtered-table-data', this._boundFilteredTableDataChanged);
    document.removeEventListener('sorted-dividend-data', this._boundSortedDividendDataChanged);
    document.removeEventListener('saved-reinvested-dividends', this._boundReinvestmentDividendsChanged);

    clearInterval(this.interval);
  }

  willUpdate(changedProperties) {
    if (changedProperties.has('tableData')) {
      if (this.tableData) {
        this.filteredTableData = this.tableData;
        const uniqueTickers = new Set();
    
        this.currentTickers = this.tableData.slice(1).reduce((tickers, row) => {
          const ticker = row[2];
          if (!uniqueTickers.has(ticker)) {
            uniqueTickers.add(ticker);
            tickers.push(ticker);
          }
          return tickers;
        }, []);
      }
    }
  }

  calculateTotals(tableData) {
    tableData = tableData.slice(1);
    const totalValueNow = tableData.reduce((acc, row) => acc + parseFloat(row[8]), 0);
    const totalGainLoss = tableData.reduce((acc, row) => acc + parseFloat(row[9]), 0);
    const totalPurchaseAmount = tableData.reduce((acc, row) => acc + parseFloat(row[7]), 0);
    const totalGainLossPercent = totalPurchaseAmount !== 0 ? (totalGainLoss / totalPurchaseAmount) * 100 : 0;

    return {
      totalPurchaseAmount,
      totalValueNow,
      totalGainLoss,
      totalGainLossPercent
    };
  }

  async compareDividendSplitData() {
    const lastTimeDividendSplit = new Date().getTime() / 1000;
    localStorage.setItem('last_time_dividend_split', lastTimeDividendSplit);
    const tickers = new Set(this.tableData.slice(1).map(row => row[2]));
  
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1; // Months are zero-indexed, so we add 1
    const day = today.getDate();
    const formattedToday = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
  
    this.todaysDividends = await this.getHistoricDividendCalendar(formattedToday);
    this.todaysSplits = await this.getHistoricSplitCalendar(formattedToday);
  
    for (const ticker of tickers) {
      const newDividend = this.todaysDividends.find(dividend => dividend.symbol === ticker);
      const newSplit = this.todaysSplits.find(split => split.symbol === ticker);
  
      if ((newDividend && newDividend.symbol === ticker) || (newSplit && newSplit.symbol === ticker)) {
        const prices = await this.getStockHistoryRange(ticker, formattedToday);
        const profile = await this.getProfileData(ticker);
        if (Object.keys(prices).length === 0) {
          continue;
        }
        const historicalPrices = prices.historical;
  
        const shares = this.tableData.reduce((totalShares, row) => {
          const sharesForRow = parseFloat(row[4]);
          return row[2] === ticker ? totalShares + (isNaN(sharesForRow) ? 0 : sharesForRow) : totalShares;
        }, 0);

        if (newDividend) {
          const openingPrice = historicalPrices[0].open;
          const dividendPayout = shares * newDividend.dividend;
          const dividendShares = dividendPayout / openingPrice;
  
          const purchaseDate = new Date(newDividend.paymentDate);
          purchaseDate.setDate(purchaseDate.getDate() + 1);
          const month = purchaseDate.getMonth() + 1 < 10 ? `0${purchaseDate.getMonth() + 1}` : purchaseDate.getMonth() + 1;
          const formattedPurchaseDate = `${month}/${purchaseDate.getDate()}/${purchaseDate.getFullYear()}`;

          const {
            currentPrice,
            totalPurchaseAmount,
            totalValueNow,
            totalGainLoss,
            totalGainLossPercent,
            assetClass,
            sector
          } = await calculateNumberFields(0, dividendShares, openingPrice, profile);
  
          this.tableData.push([formattedPurchaseDate, 'Reinvestment', ticker, openingPrice, dividendShares, 0, parseFloat(currentPrice), totalPurchaseAmount, totalValueNow, totalGainLoss, totalGainLossPercent, assetClass, sector, uuidv4()]);
        }

        if (newSplit) {
          const openingPrice = historicalPrices[0].open;
          const splitShares = shares * (newSplit.numerator / newSplit.denominator) - shares;

          const purchaseDate = new Date(newSplit.date);
          purchaseDate.setDate(purchaseDate.getDate() + 1);
          const month = purchaseDate.getMonth() + 1 < 10 ? `0${purchaseDate.getMonth() + 1}` : purchaseDate.getMonth() + 1;
          const formattedPurchaseDate = `${month}/${purchaseDate.getDate()}/${purchaseDate.getFullYear()}`;

          const {
            assetClass,
            sector
          } = await calculateNumberFields(0, splitShares, openingPrice, profile);
  
          this.tableData.push([formattedPurchaseDate, 'Split', ticker, 0, splitShares, 0, 0, 0, 0, 0, 0, assetClass, sector, uuidv4()]);
        }
      }
    }
  
    this.tableData.sort((a, b) => new Date(a[0]) - new Date(b[0]));
    await deleteRowsFromSpreadsheet(this.sheetId, this.tableData, this.spreadsheetId);
    await this.batchUpdateSpreadsheet();
  }

  // TODO util function
  async batchUpdateSpreadsheet() {
    const updateRequest = {
      range: 'Sheet1!A1',
      values: this.tableData,
    };
  
    await gapi.client.sheets.spreadsheets.values.update({
      spreadsheetId: this.spreadsheetId,
      range: updateRequest.range,
      valueInputOption: 'RAW',
      resource: {
        values: updateRequest.values,
      },
    });
  }

  consolidateTrades(tableData) {
    // Create an object of tickers to their first trade date
    const tickerDates = {};
    tableData.forEach((row, index) => {
      if (index === 0) return; // Skip header row
      const ticker = row[2];
      const date = row[0];
      if (!tickerDates[ticker]) {
        tickerDates[ticker] = date;
      }
    });

    // TODO: I hate that we need to loop through twice. Can we refactor this better?

    // Consolidate trades with the same ticker (first loop, excluding Split rows from purchasePrice calculation)
    const firstPassConsolidatedRows = [];
    const firstPassConsolidatedTradeData = {};
    tableData.forEach((row, index) => {
      // Skip the title row
      if (index === 0) {
        firstPassConsolidatedRows.push(row);
        return;
      }

      const type = row[1];
      const ticker = row[2];
      const date = tickerDates[ticker];
      const key = `${ticker}${date}`;

      if (type !== 'Split') {
        if (firstPassConsolidatedTradeData[key]) {
          // Add to existing trade data
          const tradeData = firstPassConsolidatedTradeData[key];
          tradeData.shares += parseFloat(row[4]);
          tradeData.fee += parseFloat(row[5]);
          tradeData.totalPurchaseAmount += parseFloat(row[7]);
          tradeData.totalValueNow += parseFloat(row[8]);
          tradeData.totalGainLoss += parseFloat(row[9]);
          tradeData.totalGainLossPercent = (tradeData.totalGainLoss / tradeData.totalPurchaseAmount) * 100;
          tradeData.purchasePrice = (tradeData.totalPurchaseAmount - tradeData.fee) / tradeData.shares;
        } else {
          // Create new trade data
          firstPassConsolidatedTradeData[key] = {
            date,
            type,
            ticker,
            purchasePrice: (parseFloat(row[7]) - parseFloat(row[5])) / parseFloat(row[4]),
            shares: parseFloat(row[4]),
            fee: parseFloat(row[5]),
            currentValue: parseFloat(row[6]),
            totalPurchaseAmount: parseFloat(row[7]),
            totalValueNow: parseFloat(row[8]),
            totalGainLoss: parseFloat(row[9]),
            totalGainLossPercent: (parseFloat(row[9]) / parseFloat(row[7])) * 100,
          };
        }
      }
    });

    // Consolidate all trades with the same ticker (second loop, including Split rows)
    const secondPassConsolidatedRows = [];
    const secondPassConsolidatedTradeData = {};
    tableData.forEach((row, index) => {
      // Skip the title row
      if (index === 0) {
        secondPassConsolidatedRows.push(row);
        return;
      }

      const type = row[1];
      const ticker = row[2];
      const date = tickerDates[ticker];
      const key = `${ticker}${date}`;

      if (secondPassConsolidatedTradeData[key]) {
        // Add to existing trade data
        const tradeData = secondPassConsolidatedTradeData[key];
        tradeData.shares += parseFloat(row[4]);
        tradeData.fee += parseFloat(row[5]);
        tradeData.totalPurchaseAmount += parseFloat(row[7]);
        tradeData.totalValueNow += parseFloat(row[8]);
        tradeData.totalGainLoss += parseFloat(row[9]);
        tradeData.totalGainLossPercent = tradeData.totalPurchaseAmount !== 0 ? (tradeData.totalGainLoss / tradeData.totalPurchaseAmount) * 100 : 0;
      } else {
        // Create new trade data
        secondPassConsolidatedTradeData[key] = {
          date,
          type,
          ticker,
          shares: parseFloat(row[4]),
          fee: parseFloat(row[5]),
          currentValue: parseFloat(row[6]),
          totalPurchaseAmount: parseFloat(row[7]),
          totalValueNow: parseFloat(row[8]),
          totalGainLoss: parseFloat(row[9]),
          totalGainLossPercent: parseFloat(row[7]) !== 0 ? (parseFloat(row[9]) / parseFloat(row[7])) * 100 : 0,
        };
      }
    });

    // Format the second pass consolidated trade data into table rows
    Object.values(secondPassConsolidatedTradeData).forEach((tradeData) => {
      const {
        date,
        type,
        ticker,
        shares,
        fee,
        totalPurchaseAmount,
        currentValue,
        totalValueNow,
        totalGainLoss,
        totalGainLossPercent,
      } = tradeData;
      const purchasePrice = firstPassConsolidatedTradeData[`${ticker}${date}`] ? firstPassConsolidatedTradeData[`${ticker}${date}`].purchasePrice : 0;
      const newRow = [
        date,
        type,
        ticker,
        purchasePrice.toFixed(2),
        shares.toFixed(2),
        fee.toFixed(2),
        currentValue.toFixed(2),
        totalPurchaseAmount.toFixed(2),
        totalValueNow.toFixed(2),
        totalGainLoss.toFixed(2),
        totalGainLossPercent.toFixed(2) + '%',
      ];
      secondPassConsolidatedRows.push(newRow);
    });

    return secondPassConsolidatedRows;
  }

  async getCurrentPrices() {
    if (!this.spreadsheetId || !this.tableData) {
      return;
    }

    try {
      const lastTimeCurrentPrices = new Date().getTime() / 1000;
      localStorage.setItem("last_time_current_prices", lastTimeCurrentPrices);

      // Update the table data to reflect new current prices
      const profileCache = {};
      const updatedTableData = [];

      for (const row of this.tableData.slice(1)) {
        const ticker = row[2];

        let profile;

        if (ticker in profileCache) {
          profile = profileCache[ticker];
        } else {
          profile = await this.getProfileData(ticker);
          profileCache[ticker] = profile;
        }

        if (row[1] !== "Split" && profile[0] && profile[0].price) {
          const currentPrice = String(profile[0]?.price);
          const {
            totalPurchaseAmount,
            totalValueNow,
            totalGainLoss,
            totalGainLossPercent,
          } = await calculateNumberFields(row[5], row[4], row[3], profile);

          const updatedRow = [
            ...row.slice(0, 6),
            currentPrice,
            totalPurchaseAmount.toString(),
            totalValueNow.toString(),
            totalGainLoss.toString(),
            totalGainLossPercent.toString(),
            ...row.slice(11),
          ];

          updatedTableData.push(updatedRow);
        } else if (row[1] === "Split") { // If it's a "Split" row, keep it without updates
          updatedTableData.push(row);
        }
      }

      const params = {
        spreadsheetId: this.spreadsheetId,
        range: 'Sheet1!A2', // Update starting from the second row
        valueInputOption: 'RAW',
        resource: {
          values: updatedTableData,
        },
      };
      await gapi.client.sheets.spreadsheets.values.update(params);
    } catch (error) {
      console.error(error);
    }
  }

  handleFilteredDividendDataChanged(evt) {
    this.filteredSortedDividends = evt.detail;
  }

  handleFilteredTableDataChanged(evt) {
    this.filteredTableData = evt.detail;
  }

  handleReinvestmentDividendsChanged (evt) {
    this.tableData = evt.detail;
    this.loadCurrentPositionsSpreadsheetData();
  }

  handleSortedDividendDataChanged(evt) {
    this.sortedDividends = evt.detail;
  }

  populateTotalRow(tableData, columnAmount) {
    // Calculate the total weighted gain/loss values
    const totalValues = this.calculateTotals(tableData);
  
    // Map the total values to HTML elements for the total row
    return html`
      <tr class="total-row">
        <td class="total-cell" colspan="${columnAmount}">Total</td>
        <td class="total-cell">$${addCommasToNumber(totalValues.totalPurchaseAmount)}</td>
        <td class="total-cell">$${addCommasToNumber(totalValues.totalValueNow)}</td>
        <td class="total-cell">${totalValues.totalGainLoss < 0 ? '-$' : '$'}${addCommasToNumber(Math.abs(totalValues.totalGainLoss))}</td>
        <td class="total-cell">${addCommasToNumber(totalValues.totalGainLossPercent)}%</td>
      </tr>
    `;
  }

  async loadCurrentPositions() {
    this.loadingData = true;
    await this.loadCurrentPositionsSpreadsheetData();
  }

  async loadCurrentPositionsSpreadsheetData() {
    if (!this.spreadsheetId) {
      return;
    }

    try {
      await this.sortValuesByDate();
      await gapi.client.sheets.spreadsheets.values.get({
        spreadsheetId: this.spreadsheetId,
        range: 'Sheet1!A1:N',
      }).then((response) => {
        const tableData = response.result.values;
        this.tableData = tableData;
        this.loadingData = false;
      }, (response) => {
        console.error('Error: ' + response.result.error.message);
      });
    } catch (error) {
      console.error(error);
    }
  }

  async getSellSheetData() {
    if (!this.spreadsheetId) {
      return;
    }
  
    try {
      const response = await gapi.client.sheets.spreadsheets.values.get({
        spreadsheetId: this.spreadsheetId,
        range: 'Sheet2!A:Z',
      });

      this.sellSheetData = response.result.values;
    } catch (error) {
      console.error(error);
    }
  }

  onTransactionTypeChange(event) {
    this.selectedTransactionType = event.target.value;
  }

  // Get the historical stock prices for the given ticker and date
  async getStockHistoryRange(ticker, date) {
    return await fetch(`https://financialmodelingprep.com/api/v3/historical-price-full/${ticker}?from=${date}&to=${date}&apikey=${FINANCE_API_KEY}`)
      .then(response => response.json());
  }

  // Get the historical dividend information
  async getHistoricDividend(ticker) {
    return await fetch(`https://financialmodelingprep.com/api/v3/historical-price-full/stock_dividend/${ticker}?&apikey=${FINANCE_API_KEY}`)
      .then(response => response.json());
  }

  // Get the historical dividend calendar for a date
  async getHistoricDividendCalendar(date) {
    return await fetch(`https://financialmodelingprep.com/api/v3/stock_dividend_calendar?from=${date}&to=${date}&apikey=${FINANCE_API_KEY}`)
      .then(response => response.json());
  }

  // Get the historical stock split information
  async getHistoricStockSplit(ticker) {
    return await fetch(`https://financialmodelingprep.com/api/v3/historical-price-full/stock_split/${ticker}?&apikey=${FINANCE_API_KEY}`)
      .then(response => response.json());
  }

  // Get the historical Split calendar for a date
  async getHistoricSplitCalendar(date) {
    return await fetch(`https://financialmodelingprep.com/api/v3/stock_split_calendar?from=${date}&to=${date}&apikey=${FINANCE_API_KEY}`)
      .then(response => response.json());
  }

  async addStockSplitsToSpreadsheet(ticker) {
    try {
      const params = {
        spreadsheetId: this.spreadsheetId,
        range: 'Sheet1',
        valueRenderOption: 'UNFORMATTED_VALUE',
      };
      const response = await gapi.client.sheets.spreadsheets.values.get(params);
      const sheetData = response.result.values || [];
      const stockSplits = await this.getHistoricStockSplit(ticker);
  
      if (!stockSplits.historical || stockSplits.historical.length === 0) {
        return;
      }
  
      for (const split of stockSplits.historical) {
        const splitDate = new Date(split.date);
        splitDate.setHours(0, 0, 0, 0);
  
        let insertIndex = 1;
        let foundBuyType = false; // Track if a "Buy" type has been found for this ticker
        for (const row of sheetData) {
          const rowDate = new Date(row[0]);
          rowDate.setHours(0, 0, 0, 0);
          const rowTicker = row[2];
          const rowType = row[1];
  
          if (rowTicker === ticker) {
            if (rowType === 'Buy' && !foundBuyType) {
              // First "Buy" type found for this ticker, allow adding the split row
              foundBuyType = true;
            }
            if (splitDate > rowDate) {
              break;
            }
          }
          insertIndex++;
        }
  
        // Check if the split date already exists for the specific ticker
        const existingRow = sheetData.find((row) => {
          const rowDate = new Date(row[0]);
          rowDate.setHours(0, 0, 0, 0);
          return rowDate.getTime() === splitDate.getTime() && row[2] === ticker && row[1] === 'Split';
        });
  
        if (!foundBuyType || insertIndex > sheetData.length) {
          // Skip adding the split row if no "Buy" type found or insertIndex is out of bounds
          continue;
        }
  
        if (existingRow) {
          let accumulatedShares = 0;
  
          for (const row of sheetData) {
            const rowDate = new Date(row[0]);
            rowDate.setHours(0, 0, 0, 0); // Remove time information
            const rowTicker = row[2];
            const rowShares = parseFloat(row[4]);
  
            if (rowTicker === ticker && rowDate < splitDate) {
              accumulatedShares += rowShares;
            }
          }
  
          const newShares = accumulatedShares * (split.numerator / split.denominator) - accumulatedShares;
          existingRow[4] = parseFloat(newShares.toFixed(2));
        } else {
          // Create a new row for the split
          let accumulatedShares = 0;
          let previousAssetClass;
          let previousSector;
          for (const row of sheetData) {
            const rowDate = new Date(row[0]);
            rowDate.setHours(0, 0, 0, 0); // Remove time information
            const rowTicker = row[2];
            const rowShares = parseFloat(row[4]);
  
            if (rowTicker === ticker) {
              if (rowDate < splitDate) {
                accumulatedShares += rowShares;
              }
              previousAssetClass = row[11];
              previousSector = row[12];
            }
          }
  
          const newShares = accumulatedShares * (split.numerator / split.denominator) - accumulatedShares;
          const formattedDate = `${(splitDate.getMonth() + 1).toString().padStart(2, '0')}/${
            splitDate.getDate().toString().padStart(2, '0')}/${splitDate.getFullYear()}`;
  
          const newRow = [
            formattedDate,
            'Split',
            ticker,
            0,
            parseFloat(newShares.toFixed(2)),
            0,
            0,
            0,
            0,
            0,
            0,
            previousAssetClass,
            previousSector,
            uuidv4()
          ];
  
          sheetData.splice(insertIndex, 0, newRow);
        }
      }
  
      const updateRequest = {
        range: 'Sheet1',
        values: sheetData,
      };
  
      await gapi.client.sheets.spreadsheets.values.update({
        spreadsheetId: this.spreadsheetId,
        range: 'Sheet1',
        valueInputOption: 'RAW',
        resource: updateRequest,
      });
    } catch (error) {
      console.error('Error adding stock splits:', error);
    }
  }

  async onSubmit(evt) {
    this.loadingData = true;
    let formEnteredObj = evt.detail.formEnteredObj;
    let {
      transactionType,
      date,
      ticker,
      price,
      numberOfShares,
      fee
    } = formEnteredObj;
    evt.preventDefault();

    try {
      const sheetName = 'Sheet1';
      const totalRowCount = await this.getRowCount(sheetName);
      if (this.tableData && totalRowCount < this.tableData.length + 100) {
        await addNewRows(this.sheetId, this.spreadsheetId, totalRowCount);
      }
    } catch (error) {
      console.error('Error:', error);
    }

    try {
      // Check if headers already exist in the spreadsheet
      const headers = await gapi.client.sheets.spreadsheets.values.get({
        spreadsheetId: this.spreadsheetId,
        range: 'Sheet1!A1:N1'
      });

      if (!headers.result.values) {
        // If headers don't exist already, then add them first
        const ownedTitleRow = [
          'Purchase Date',
          'Type',
          'Ticker',
          'Purchase Price',
          'Number of Shares',
          'Fee',
          'Current Price',
          'Total Purchase Amount',
          'Total Value Now',
          'Total Gain/Loss',
          'Total Gain/Loss(%)',
          'Asset Class',
          'Sector',
          'UUID'
        ];
        const ownedRequest = {
            spreadsheetId: this.spreadsheetId,
            range: 'Sheet1!A1:N1',
            valueInputOption: 'USER_ENTERED',
            resource: {
                values: [ownedTitleRow]
            }
        };
        await gapi.client.sheets.spreadsheets.values.update(ownedRequest);

        const sellTitleRow = [
          'Purchase Date',
          'Sell Date',
          'Ticker',
          'Purchase Price',
          'Total Purchase Amount',
          'Sell Price',
          'Shares Sold',
          'Total Selling Amount',
          'Total Fee',
          'Total Gain/Loss',
          'Total Gain/Loss(%)',
          'Asset Class',
          'Sector',
          'UUID',
          'Old Type'
        ];
        const sellTitleRequest = {
            spreadsheetId: this.spreadsheetId,
            range: 'Sheet2!A1:O1',
            valueInputOption: 'USER_ENTERED',
            resource: {
                values: [sellTitleRow]
            }
        };
        await gapi.client.sheets.spreadsheets.values.update(sellTitleRequest);
      }

      // Get the stock quote for the ticker
      this.getProfileData(ticker).then(async profile => {
        if (transactionType === 'buy') {
          const {
            currentPrice,
            totalPurchaseAmount,
            totalValueNow,
            totalGainLoss,
            totalGainLossPercent,
            assetClass,
            sector
          } = await calculateNumberFields(fee, numberOfShares, price, profile);

          // Build the request body
          const request = {
            spreadsheetId: this.spreadsheetId,
            range: 'Sheet1!A:N',
            valueInputOption: 'RAW',
            insertDataOption: 'INSERT_ROWS',
            resource: {
              values: [
                [date, 'Buy', ticker, parseFloat(price), parseFloat(numberOfShares), parseFloat(fee), parseFloat(currentPrice), totalPurchaseAmount, totalValueNow, totalGainLoss, totalGainLossPercent, assetClass, sector, uuidv4()]
              ],
            },
          };

          await gapi.client.sheets.spreadsheets.values.append(request);
          await this.addStockSplitsToSpreadsheet(ticker);
          await this.loadCurrentPositionsSpreadsheetData();
        } else {
          this.updateSellStock(formEnteredObj, 1);
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  async sortValuesByDate() {
    try {
      await gapi.client.init({
        apiKey: GOOGLE_API_KEY,
        discoveryDocs: [DISCOVERY_DOC],
      });

      var range = 'Sheet1!A2:N';
      var response = await gapi.client.sheets.spreadsheets.values.get({
        spreadsheetId: this.spreadsheetId,
        range: range,
      });
      var values = response.result.values;
      if (values) {
        values.sort(function(a, b) {
          var dateA = new Date(a[0]);
          var dateB = new Date(b[0]);
          return dateA - dateB;
        });

        var updateValues = {
          range: range,
          values: values,
        };
        await gapi.client.sheets.spreadsheets.values.update({
          spreadsheetId: this.spreadsheetId,
          range: range,
          valueInputOption: 'RAW',
          resource: updateValues,
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  // Define a custom function that parses dates in the format MM/DD/YYYY
  // and returns a date object that can be used for sorting
  parseDate(dateString) {
    const dateParts = dateString.split('/');
    const year = parseInt(dateParts[2], 10);
    const month = parseInt(dateParts[0], 10) - 1;
    const day = parseInt(dateParts[1], 10);
    return new Date(year, month, day);
  }

  async getProfileData(ticker) {
    const profileEndpoint = `https://financialmodelingprep.com/api/v3/profile/${ticker}?apikey=${FINANCE_API_KEY}`;
    const quoteEndpoint = `https://financialmodelingprep.com/api/v3/quote/${ticker}?apikey=${FINANCE_API_KEY}`;
  
    try {
      // First, try to fetch data from the profile endpoint
      const profileResponse = await fetch(profileEndpoint);
      
      if (profileResponse.ok) {
        const profileData = await profileResponse.json();
        
        // Check if the profile data is empty, if not, return it
        if (Array.isArray(profileData) && profileData.length > 0) {
          return profileData;
        }
      }
  
      // If the profile data is empty or the request fails, fetch data from the quote endpoint
      const quoteResponse = await fetch(quoteEndpoint);
  
      if (quoteResponse.ok) {
        const quoteData = await quoteResponse.json();
        return quoteData;
      }
  
      throw new Error(`Failed to fetch profile data`);
    } catch (error) {
      console.error(`Error fetching profile data:`, error);
      throw error;
    }
  }

  createSellAllObject(buyRow, formEnteredObj, isOverflow, needsTotalFee) {
    const newTotalPurchaseAmount = (parseFloat(buyRow[3]) * parseFloat(formEnteredObj.numberOfShares));
    const shareAmount = isOverflow ? parseFloat(formEnteredObj.numberOfShares) : buyRow[4].toString();
    const totalFee = needsTotalFee ? 0 : parseFloat(formEnteredObj.fee) + parseFloat(buyRow[5]);
    const totalSellingAmount = parseFloat(formEnteredObj.price) * parseFloat(formEnteredObj.numberOfShares);
    const totalSellGainLoss = newTotalPurchaseAmount !== 0 ? (totalSellingAmount - parseFloat(newTotalPurchaseAmount)) : 0;
    const totalSellGainLossPercent = newTotalPurchaseAmount !== 0 ? (totalSellGainLoss / parseFloat(newTotalPurchaseAmount)) * 100 : 0;
    const allSellRow = {
      'Purchase Date': buyRow[0],
      'Sell Date': formEnteredObj.date,
      'Ticker': formEnteredObj.ticker,
      'Purchase Price': buyRow[3].toString(),
      'Total Purchase Amount': newTotalPurchaseAmount.toString(),
      'Sell Price': parseFloat(formEnteredObj.price),
      'Shares Sold': shareAmount, // Remaining shares from overflow or all for the row
      'Total Selling Amount': totalSellingAmount.toString(),
      'Total Fee': totalFee,
      'Total Gain/Loss': totalSellGainLoss.toString(),
      'Total Gain/Loss(%)': totalSellGainLossPercent.toString(),
      'Asset class': buyRow[11],
      'Sector': buyRow[12],
      'UUID': uuidv4(),
      'Old Type': buyRow[1]
    };
    return allSellRow;
  }

  async getRowCount(sheetName) {
    try {
      const response = await gapi.client.sheets.spreadsheets.get({
        spreadsheetId: this.spreadsheetId,
      });
  
      const sheets = response.result.sheets;
      let totalRowCount = 0;
  
      for (const sheet of sheets) {
        if (sheet.properties.title === sheetName) {
          totalRowCount = sheet.properties.gridProperties.rowCount;
          break; // Found the sheet, no need to continue the loop
        }
      }
  
      return totalRowCount;
    } catch (error) {
      console.error('Error fetching row count:', error);
      return -1;
    }
  }

  async updateSellStock(formEnteredObj, buyRowIndex) {
    try {
      const sheet = await gapi.client.sheets.spreadsheets.values.get({
        spreadsheetId: this.spreadsheetId,
        range: 'Sheet1!A:O',
      });
      const rows = sheet.result.values;
      const isExactShares = rows[buyRowIndex][4] ? rows[buyRowIndex][4] - formEnteredObj.numberOfShares === 0 : false;
    
      // If there are no more rows, return
      if (buyRowIndex > rows.length) {
        return;
      }

      // If the ticker doesn't match, call again
      if (rows[buyRowIndex][2] !== formEnteredObj.ticker) {
        this.updateSellStock(formEnteredObj, buyRowIndex + 1);
        return;
      }
    
      // If the number of shares in the current row is less than the number being sold
      if (rows[buyRowIndex][4] - formEnteredObj.numberOfShares <= 0) {
        const index = await getFirstEmptyRowIndex('Sheet2', this.spreadsheetId);

        // Get row that is about to be deleted, so we can find its values
        await gapi.client.sheets.spreadsheets.values.get({
          spreadsheetId: this.spreadsheetId,
          range: `Sheet1!A${buyRowIndex + 1}:Z${buyRowIndex + 1}`
        }).then((response) => {
          this.removedBuyRow = response.result.values[0];
        });

        const allSellRow = this.createSellAllObject(this.removedBuyRow, formEnteredObj, isExactShares, true);

        // Send request to update sheet2 with purchase data and new form sell data
        await gapi.client.sheets.spreadsheets.values.update({
          spreadsheetId: this.spreadsheetId,
          range: `Sheet2!A${index}:O${index}`,
          valueInputOption: 'RAW',
          resource: {
            values: [Object.values(allSellRow)]
          }
        });

        await gapi.client.sheets.spreadsheets.batchUpdate({
          spreadsheetId: this.spreadsheetId,
          requests: [{
            deleteDimension: {
              range: {
                sheetId: this.sheetId,
                dimension: "ROWS",
                startIndex: buyRowIndex,
                endIndex: buyRowIndex+1
              }
            }
          }]
        }).then(() => {
          if (!isExactShares) {
            const remainingShares = formEnteredObj.numberOfShares - rows[buyRowIndex][4];
            formEnteredObj.numberOfShares = remainingShares;
            this.updateSellStock(formEnteredObj, buyRowIndex);
          } else {
            this.loadCurrentPositionsSpreadsheetData();
          }
        });
      } else {
        // We don't need to remove a row
        // Handle multiple row sold shares
        // Handle different ticker sold shares

        // Update all information for the current buy order
        const fee = rows[buyRowIndex][5];
        const price = rows[buyRowIndex][3];
        const currentPrice = rows[buyRowIndex][6];
        const newShares = rows[buyRowIndex][4] - formEnteredObj.numberOfShares;
        const totalPurchaseAmount = (parseFloat(price) * parseFloat(newShares)) + parseFloat(fee);
        const totalValueNow = parseFloat(currentPrice) * parseFloat(newShares);
        const totalBuyGainLoss = totalValueNow - totalPurchaseAmount;
        const totalBuyGainLossPercent = (totalBuyGainLoss / totalPurchaseAmount) * 100;
        await gapi.client.sheets.spreadsheets.values.update({
          spreadsheetId: this.spreadsheetId,
          range: `Sheet1!E${buyRowIndex + 1}:K${buyRowIndex + 1}`,
          valueInputOption: 'RAW',
          resource: {
            values: [
              [parseFloat(newShares), parseFloat(fee), parseFloat(currentPrice), totalPurchaseAmount, totalValueNow, totalBuyGainLoss, totalBuyGainLossPercent],
            ]
          },
        });

        // Handle how many sell rows need to be updated
        let index, allSellRow;

        if (this.removedBuyRow) {
          index = await getFirstEmptyRowIndex('Sheet2', this.spreadsheetId);

          // This is the same ticker, but overflow
          await gapi.client.sheets.spreadsheets.values.get({
            spreadsheetId: this.spreadsheetId,
            range: `Sheet1!A${buyRowIndex + 1}:Z${buyRowIndex + 1}`
          }).then((response) => {
            this.overflowBuyRow = response.result.values[0];
          });

          allSellRow = this.createSellAllObject(this.overflowBuyRow, formEnteredObj, true, false);
        } else {
          // This is a different ticker
          // Get current buy order
          index = await getFirstEmptyRowIndex('Sheet2', this.spreadsheetId);

          await gapi.client.sheets.spreadsheets.values.get({
            spreadsheetId: this.spreadsheetId,
            range: `Sheet1!A${buyRowIndex + 1}:O${buyRowIndex + 1}`
          }).then((response) => {
            this.currentBuyOrder = response.result.values[0];
          });

          allSellRow = this.createSellAllObject(this.currentBuyOrder, formEnteredObj, true, true);
        }

        await gapi.client.sheets.spreadsheets.values.update({
          spreadsheetId: this.spreadsheetId,
          range: `Sheet2!A${index}:O${index}`,
          valueInputOption: 'RAW',
          resource: {
            values: [Object.values(allSellRow)]
          }
        });

        this.loadCurrentPositionsSpreadsheetData();
      }
    } catch (error) {
      console.error(error);
    }
  }

  async getSheetId() {
    try {
      const sheetName = this.isSellRow ? 'Sheet2' : 'Sheet1';
      const sheet = await gapi.client.sheets.spreadsheets.get({
        spreadsheetId: this.spreadsheetId,
        ranges: [sheetName],
        includeGridData: false,
      });
      this.sheetId = sheet.result.sheets[0].properties.sheetId;
    } catch (error) {
      console.error(error);
    }
  }

  handleNewTradeClick() {
    const BuyFormModal = this.shadowRoot.querySelector('buy-form-modal');
    const modalElement = BuyFormModal.shadowRoot.querySelector('modal-element');
    modalElement.setAttribute('show', '');
  }

  handleEditRow(rowIndex) {
    const strippedSheetData = this.filteredTableData.slice(1);
    const filteredRowData = strippedSheetData[rowIndex - 1];

    // Find the index of filteredRowData in this.tableData
    const tableDataIndex = this.tableData.findIndex((row) => {
      return JSON.stringify(row) === JSON.stringify(filteredRowData);
    });

    const [
      purchaseDate,
      type,
      ticker,
      purchasePrice,
      numberOfShares,
      fee,
      currentPrice,
      totalPurchaseAmount,
      totalValueNow,
      totalGainLoss,
      totalGainLossPercent,
      assetClass,
      sector,
      UUID
    ] = filteredRowData;
  
    this.currentRow = {
      'date': purchaseDate,
      'type': type,
      'ticker': ticker,
      'price': purchasePrice,
      'numberOfShares': numberOfShares,
      'fee': fee,
      'currentPrice': currentPrice,
      'totalPurchaseAmount': totalPurchaseAmount,
      'totalValueNow': totalValueNow,
      'totalGainLoss': totalGainLoss,
      'totalGainLossPercent': totalGainLossPercent,
      'assetClass': assetClass,
      'sector': sector,
      'UUID': UUID
    };
  
    this.rowNumber = tableDataIndex;
    const editRowModal = this.shadowRoot.querySelector('edit-row-modal');
    const modalElement = editRowModal.shadowRoot.querySelector('modal-element');
    modalElement.setAttribute('show', '');
  }

  handleDeleteRow(rowIndex) {
    const strippedSheetData = this.filteredTableData.slice(1);
    const filteredRowData = strippedSheetData[rowIndex - 1];

    // Find the index of filteredRowData in this.tableData
    const tableDataIndex = this.tableData.findIndex((row) => {
      return JSON.stringify(row) === JSON.stringify(filteredRowData);
    });

    const [purchaseDate, type, ticker, purchasePrice, numberOfShares, fee] = filteredRowData.slice(0, 6);
  
    this.currentRow = {
      'date': purchaseDate,
      'type': type,
      'ticker': ticker,
      'price': purchasePrice,
      'numberOfShares': numberOfShares,
      'fee': fee
    };

    this.rowNumber = tableDataIndex;
    const deleteRowModal = this.shadowRoot.querySelector('delete-row-modal');
    const modalElement = deleteRowModal.shadowRoot.querySelector('modal-element');
    modalElement.setAttribute('show', '');
  }

  async handleReloadDividends({ detail }) {
    const { deleteDividends, rowNumber, sheetId, updatedRow } = detail;
    const updatedData = [this.filteredTableData[0]];
    const ticker = updatedRow[2];

    this.loadingData = true;

    if (updatedRow[3]) {
      this.tableData[rowNumber] = updatedRow;
    }
  
    for (let i = 1; i < this.tableData.length; i++) {
      const row = this.tableData[i];
      if (row[2] !== ticker || (row[1] !== 'Split' && row[1] !== 'Reinvestment' && !deleteDividends && row[2] === ticker)) {
        updatedData.push(row);
      }
    }

    const deleteRequest = {
      deleteDimension: {
        range: {
          sheetId,
          dimension: 'ROWS',
          startIndex: 1,
          endIndex: this.tableData.length,
        },
      },
    };
  
    const updateRequest = {
      range: 'Sheet1!A1',
      values: updatedData,
    };
  
    try {
      await gapi.client.sheets.spreadsheets.batchUpdate({
        spreadsheetId: this.spreadsheetId,
        resource: {
          requests: [deleteRequest],
        },
      });
  
      await gapi.client.sheets.spreadsheets.values.update({
        spreadsheetId: this.spreadsheetId,
        range: updateRequest.range,
        valueInputOption: 'RAW',
        resource: {
          values: updateRequest.values,
        },
      });
  
      // Add dividends back if we are editing
      this.buyRowStorage = {};
      if (!deleteDividends) {
        await this.addStockSplitsToSpreadsheet(ticker);
        await this.loadCurrentPositionsSpreadsheetData();
      } else {
        await this.loadCurrentPositionsSpreadsheetData();
      }
    } catch (error) {
      console.error('Error updating spreadsheet:', error);
    }
  }

  selectTab(tabIndex) {
    this.activeTab = tabIndex;
  }

  static get styles() {
    return css`
      .stock-owned-container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin: 48px;
      }

      .header-container {
        display: flex;
        width: 1184px;
        height: 46px;
        flex-shrink: 0;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
      }

      .tabs-area {
        display: flex;
        gap: 12px;
      }

      .tabs-container {
        height: 32px;
        padding: 6px;
        flex-shrink: 0;
        background: #FFF;
        backdrop-filter: blur(10px);
      }

      .tab {
        display: inline-flex;
        color: #2E2E3A;
        fill: #2E2E3A;
        text-decoration: none;
        padding: 8px 10px;
        justify-content: center;
        align-items: center;
        gap: 8px;
      }

      .tab.active {
        color: #FFFFFF;
        fill: #FFFFFF;
        background: #3346E7;
      }

      .tab-icon {
        vertical-align: middle;
        width: 16px;
        height: 16px;
      }

      .tab-text {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: -0.14px;
      }

      .add-trade-btn {
        width: 126px;
        height: 36px;
        padding: 10px 20px;
        border-radius: 18px;
        border: none;
        background: #3346E7;
        box-shadow: 0px 6px 8px 0px rgba(51, 70, 231, 0.20);
        color: #FFF;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: -0.14px;
      }
  
      .add-trade-btn:hover {
        background-color: #003580;
      }

      .edit-icon,
      .trash-icon {
        width: 16px;
        height: 16px;
        flex-shrink: 0;
        cursor: pointer;
      }
      
      .all-dividend-card-body,
      .consolidated-card-body,
      .individual-card-body {
        display: inline-flex;
        width: 1184px;
        flex-shrink: 0;
      }

      .card {
        display: inline-flex;
        width: 1184px;
        flex-shrink: 0;
      }

      .data-table {
        width: 1184px;
        flex-shrink: 0;
        margin: 0;
        border-collapse: collapse;
      }

      .title-row {
        border-bottom: 1px solid #EFF1F7;
        background: #F2F3F7;
      }

      .title-cell {
        color: #2E2E3A;
        font-family: 'Montserrat', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 12px;
        letter-spacing: -0.12px;
        padding: 14px 0 14px 20px;
      }

      .data-row {
        border-bottom: 1px solid #EFF1F7;
        background: #FFF;
      }

      .data-row:hover {
        background-color: #f5f5f5;
      }

      .data-cell {
        color: #2E2E3A;
        font-family: 'Montserrat', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 12px;
        letter-spacing: -0.12px;
        padding: 14px 20px;
      }

      .total-row {
        border-bottom: 1px solid #EFF1F7;
        background: #FFF;
      }

      .total-cell {
        color: #2E2E3A;
        font-family: 'Montserrat', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 12px;
        letter-spacing: -0.12px;
        padding: 20px 20px;
      }

      .no-bought-stocks {
        text-align: center;
        font-size: 24px;
      }

      .buy-tag {
        background: #3346E7;
        padding: 4px 10px;
      }

      .reinvestment-tag {
        background: #F84439;
        padding: 4px 10px;
      }

      .split-tag {
        background: #FF9215;
        padding: 4px 10px;
      }

      .buy-tag, .reinvestment-tag, .split-tag {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 10px;
        color: #FFF;
        font-family: 'Montserrat', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 12px;
        letter-spacing: -0.12px;
      }

      .tag-wrapper {
        width: calc(100% - 10px);
      }

      .last-column {
        position: relative;
        width: 150px;
      }
      
      .last-column div {
        display: inline-block;
        width: calc(100% - 48px);
      }
      
      .last-column img {
        height: 16px;
        width: 16px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin-left: 10px;
        cursor: pointer;
      }
      
      .last-column img:last-child {
        right: 20px;
      }

      .spinner {
        margin: 32px auto auto auto;
      }
    `;
  }

  render() {
    return html`
      <buy-form-modal .currentTickers=${this.currentTickers} .sellSheetData=${this.sellSheetData} .tableData=${this.tableData}></buy-form-modal>
      <delete-row-modal .currentRow=${this.currentRow} .tableData=${this.tableData} .isBoughtRow=${this.isBoughtRow = this.activeTab === 1} .rowNumber=${this.rowNumber} .spreadsheetId=${this.spreadsheetId}></delete-row-modal>
      <edit-row-modal .currentRow=${this.currentRow} .rowNumber=${this.rowNumber} .spreadsheetId=${this.spreadsheetId}></edit-row-modal>
      <div class="stock-owned-container">
        <div class="header-container">
          <div class="tabs-area">
            <div class="tabs-container">
              <a href="#" name="tab1" class="tab ${this.activeTab === 0 ? 'active' : ''}" @click="${() => this.selectTab(0)}">
                <box-icon class="tab-icon" name='pie-chart-alt-2'></box-icon>
                <span class="tab-text">Consolidated</span>
              </a>
              <a href="#" name="tab2" class="tab ${this.activeTab === 1 ? 'active' : ''}" @click="${() => this.selectTab(1)}">
                <box-icon class="tab-icon" name='candles'></box-icon>
                <span class="tab-text">Individual</span>
              </a>
            </div>

            <div class="tabs-container">
              <a href="#" name="tab1" class="tab ${this.activeTab === 3 ? 'active' : ''}" @click="${() => this.selectTab(3)}">
                <box-icon class="tab-icon" type='solid' name='coin-stack'></box-icon>
                <span class="tab-text">All Dividends</span>
              </a>
            </div>
          </div>
          <button class="add-trade-btn" @click=${this.handleNewTradeClick}>New trade</button>
        </div>
        <stocks-filter-element .activeTab=${this.activeTab} .sortedDividends=${this.sortedDividends} .tableData=${this.tableData}></stocks-filter-element>
        <div class="card">
          <div class="consolidated-card-body" style=${this.activeTab === 0 ? '' : 'display:none'}>
            ${this.loadingData
              ? html`<spinner-element class="spinner"></spinner-element>`
              : this.filteredTableData && this.filteredTableData.length > 1
                ? html`
                  <table class="data-table">
                    ${this.filteredTableData && this.consolidateTrades(this.filteredTableData).map((row, rowIndex) => {
                      // Skip the first row from formatting
                      if (rowIndex === 0) {
                        return html`<tr class="title-row">${row.slice(2, row.length - 3).map(cell => html`<td class="title-cell">${cell}</td>`)}</tr>`;
                      }
                  
                      return html`
                        <tr class="data-row">
                          ${row.slice(2).map((cell, index) => {
                            if (index === row.length - 3) {
                              const lastCell = parseFloat(cell);
                              return html`
                                <td class="data-cell last-column">
                                  <div>${lastCell.toFixed(2)}%</div>
                                  <img src="${trash}" class="trash-icon" alt="Trash" @click=${() => this.handleDeleteRow(rowIndex)} />
                                </td>
                              `;
                            } else if (index >= 0 && index <= 8) {
                              const floatCell = parseFloat(cell);
                              if (index === 8) {
                                cell = addCommasToNumber(floatCell) + "%";
                              } else {
                                if (floatCell < 0) {
                                  cell = "-$" + addCommasToNumber(Math.abs(floatCell));
                                } else {
                                  // Skip over the ticker and number of shares
                                  cell = (index === 0 || index === 2) ? addCommasToNumber(cell) : "$" + addCommasToNumber(floatCell);
                                }
                              }
                            }
                            return html`<td class="data-cell">${cell}</td>`;
                          })}
                        </tr>
                      `;
                    })}
                    ${this.filteredTableData && this.populateTotalRow(this.filteredTableData, 5)}
                  </table>
                `
                : html`<p class="no-bought-stocks">You have not added any positions yet</p>`
            }
          </div>

          <div class="individual-card-body" style=${this.activeTab === 1 ? '' : 'display:none'}>
            ${this.loadingData
              ? html`<spinner-element class="spinner"></spinner-element>`
              : this.filteredTableData && this.filteredTableData.length > 1
              ? html`
                <table class="data-table">
                  ${this.filteredTableData && this.filteredTableData.map((row, rowIndex) => {
                    // Skip the first row from formatting
                    if (rowIndex === 0) {
                      return html`<tr class="title-row">${row.slice(0, row.length - 3).map(cell => html`<td class="title-cell">${cell}</td>`)}</tr>`;
                    }
                  
                    return html`
                      <tr class="data-row">
                        ${row.map((cell, index) => {
                          if (index > 10) {
                            return;
                          }

                          if (index === row.length - 4) {
                            const lastCell = parseFloat(cell);
                            return html`
                              <td class="data-cell last-column">
                                <div>${lastCell.toFixed(2)}%</div>
                                ${rowIndex !== 0 && row[1] !== 'Reinvestment' && row[1] !== 'Split'
                                  ? html`<img src="${edit}" class="edit-icon" alt="Edit" @click=${() => this.handleEditRow(rowIndex)} />`
                                  : ''}
                                ${rowIndex !== 0 && row[1] !== 'Reinvestment' && row[1] !== 'Split'
                                  ? html`<img src="${trash}" class="trash-icon" alt="Trash" @click=${() => this.handleDeleteRow(rowIndex)} />`
                                  : ''}
                              </td>
                            `;
                          } else if (index === 1) { // check if it's the second column (column B)
                            let tagClass = '';

                            switch (cell) {
                              case 'Buy':
                                tagClass = 'buy-tag';
                                break;
                              case 'Reinvestment':
                                tagClass = 'reinvestment-tag';
                                break;
                              case 'Split':
                                tagClass = 'split-tag';
                                break;
                              default:
                                // Handle other cases or set a default class if needed
                                break;
                            }

                            return html`
                              <td class="data-cell">
                                <div class="tag-wrapper">
                                  <div class="${tagClass}">${cell}</div>
                                </div>
                              </td>
                            `;
                          } else if (index >= 3 && index <= 10) {
                            const floatCell = parseFloat(cell);
                            if (index === 10) {
                              cell = addCommasToNumber(floatCell) + "%";
                            } else {
                              if (floatCell < 0) {
                                cell = "-$" + addCommasToNumber(Math.abs(floatCell));
                              } else {
                                // Skip over the number of shares
                                cell = (index === 4) ? addCommasToNumber(cell) : "$" + addCommasToNumber(floatCell);
                              }
                            }
                          }
                          return html`<td class="data-cell">${cell}</td>`;
                        })}
                      </tr>
                    `;
                  })}
                  ${this.filteredTableData && this.populateTotalRow(this.filteredTableData, 7)}
                </table>
              `
              : html`<p class="no-bought-stocks">You have not added any positions yet</p>`
            }
          </div>

          <div class="all-dividend-card-body" style=${this.activeTab === 3 ? '' : 'display:none'}>
            ${this.loadingData
              ? html`<spinner-element class="spinner"></spinner-element>`
              : this.tableData && this.tableData.length > 1
              ? html`
                <current-positions-dividends-element .currentTickers=${this.currentTickers} .filteredSortedDividends=${this.filteredSortedDividends} .spreadsheetId=${this.spreadsheetId} .sheetId=${this.sheetId} .tableData=${this.tableData}></current-positions-dividends-element>
              `
              : html`<p class="no-bought-stocks">You have not added any positions yet</p>`
            }
          </div>
        </div>
      </div>
    `;
  }
}

customElements.define('stocks-bought-element', StocksBoughtElement);