import { LitElement, html, css } from 'lit';

class DropdownElement extends LitElement {
  static get properties() {
    return {
      activeTab: { type: String },
      disabled: { type: Boolean },
      isOpen: { type: Boolean },
      items: { type: Array },
      resetDropdownSelection: { type: Boolean },
      selectedOption: { type: String },
      selectedText: { type: String }
    };
  }

  constructor() {
    super();
    this.disabled = false;
    this.isOpen = false;
    this.selectedText = '';
    this.selectedOption = '';
    this.items = [];
  }

  willUpdate(changedProperties) {
    if (changedProperties.has('items')) {
      if (this.items.length > 0 && this.selectedOption === '') {
        this.selectedOption = this.items[0];
      }
    }

    if (
      changedProperties.has('activeTab') ||
      changedProperties.has('resetDropdownSelection')
    ) {
      this.selectedOption = this.items[0];
    }

    if (changedProperties.has('resetDropdownSelection')) {
      this.selectedOption = this.items[0];
    }
  }

  selectOption(option) {
    this.selectedOption = option;
    this.isOpen = false;
    const chevronIcon = this.shadowRoot.querySelector('.chevron-down-icon');
    chevronIcon.classList.toggle('rotate');
    this.dispatchEvent(new CustomEvent('option-selected', { bubbles: true, composed: true, detail: option }));
  }

  toggleDropdown() {
    if (!this.disabled) {
      this.isOpen = !this.isOpen;
      const chevronIcon = this.shadowRoot.querySelector('.chevron-down-icon');
      chevronIcon.classList.toggle('rotate');
    }
  }

  static get styles() {
    return css`
      :host {
        --dropdown-background-color: #fff;
        --dropdown-button-border: 1px solid #ccc;
        --dropdown-button-radius: 4px;
        --dropdown-button-padding: 8px;
        --icon-width: 12px;
        --icon-height: 12px;
        --dropdown-list-width: calc(100% - 2px);
        --dropdown-list-border-top: none;
      }

      .dropdown {
        width: 100%;
        position: relative;
        display: inline-block;
        font-size: 16px;
        background-color: var(--dropdown-background-color);
        border-radius: var(--dropdown-button-radius);
      }
      
      .dropdown-button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: var(--dropdown-button-padding);
        border: var(--dropdown-button-border);
        border-radius: var(--dropdown-button-radius);
        color: #535362;
        fill: #535362;
        font-family: 'Montserrat', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 12px;
        letter-spacing: -0.12px;
        cursor: pointer;
      }
      
      .dropdown-button[disabled] {
        background-color: #eaeaea; /* Slightly darker greyed-out background color for disabled button */
        cursor: not-allowed; /* Change cursor to indicate disabled state */
      }
      
      .chevron-down-icon {
        margin-left: 8px;
        width: var(--icon-width);
        height: var(--icon-height);
        transition: transform 0.2s ease-in-out;
      }
      
      .dropdown-button[disabled] .chevron-down-icon {
        transition: none; /* Remove transform transition for disabled button */
      }
      
      .chevron-down-icon.rotate {
        transform: rotate(180deg);
      }
      
      .dropdown-list {
        width: var(--dropdown-list-width);
        max-height: 400px;
        overflow-y: auto;
        position: absolute;
        top: 100%;
        left: 0;
        margin: 0;
        padding: 0;
        list-style: none;
        background-color: #fff;
        border: 1px solid #ccc;
        border-top: var(--dropdown-list-border-top);
        border-radius: 0 0 4px 4px;
        z-index: 1;
      }
      
      .dropdown-list[disabled] {
        opacity: 0.5; /* Slightly greyed-out opacity for disabled dropdown list */
        pointer-events: none; /* Disable pointer events for disabled dropdown list */
      }
      
      .dropdown-item:hover {
        background-color: #eee;
      }
      
      .dropdown-item {
        padding: 8px;
        cursor: pointer;
      }
    `;
  }

  render() {
    return html`
      <div class="dropdown">
        <div class="dropdown-button" @click="${this.toggleDropdown}" ?disabled="${this.disabled}">
          ${this.disabled ? html`${this.selectedText} None` : (this.selectedText !== '' ? html`${this.selectedText} ${this.selectedOption}` : html`${this.selectedOption}`)}
          <box-icon name="chevron-down" class="chevron-down-icon"></box-icon>
        </div>
        ${this.isOpen
          ? html`
              <ul class="dropdown-list">
                ${this.items.map(
                  item => html`
                    <li class="dropdown-item" @click="${() => this.selectOption(item)}">
                      ${item}
                    </li>
                  `
                )}
              </ul>
            `
          : ''}
      </div>
    `;
  }
}

customElements.define('dropdown-element', DropdownElement);
