import { LitElement, html, css } from 'lit';
import { addCommasToNumber } from './util';

class DividendCalendarElement extends LitElement {
  static get properties() {
    return {
      dividends: Array,
      selectedMonth: Number,
      selectedMonthName: String,
      sheetData: Object,
      spreadsheetId: String,
    };
  }

  constructor() {
    super();
  }

  static get styles() {
    return css`
      .calendar {
        display: flex;
        flex-direction: row;
        width: 692px;
        height: 317px;
        padding: 36px;
      }
      
      .calendar-header-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
      }

      .calendar-header {
        color: #2E2E3A;
        font-family: 'Montserrat', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 100% */
        letter-spacing: -0.2px;
      }

      .calendar-arrows-container {
        display: flex;
        align-items: flex-start;
        gap: 12px;
      }

      .chevron-icon {
        width: 20px;
        height: 20px;
      }
      
      .calendar-container {
        width: 692px;
        height: 317px;
      }
      
      .weekdays {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        gap: 5px;
        text-align: center;
        margin-bottom: 2px;
        background: #F7F7F9;
      }

      .weekday-days {
        display: flex;
        padding: 10px 8px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        color: #2E2E3A;
        text-align: center;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px; /* 100% */
        letter-spacing: -0.16px;
      }
      
      .calendar-days {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        height: 236px;
        gap: 5px;
        position: relative; /* Ensure relative positioning for the tooltip's absolute position */
      }
      
      .day {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        color: #2E2E3A;
        text-align: center;
        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: -0.16px;
        position: relative; /* Ensure relative positioning for the tooltip's absolute position */
      }

      .day.active {
        border-radius: 32px;
        background: #3C49DE;
      }
      
      .tooltip {
        position: absolute;
        background-color: rgba(0, 0, 0, 0.8);
        color: #fff;
        padding: 10px;
        border-radius: 6px;
        z-index: 1;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.2s ease, visibility 0.2s ease;
        white-space: nowrap;
        left: 50%; /* Center the tooltip horizontally */
        transform: translateX(-50%) translateY(-100%); /* Position the tooltip above the day */
      }
      
      .tooltip::before {
        content: '';
        position: absolute;
        border-style: solid;
        border-width: 8px;
        border-color: rgba(0, 0, 0, 0.8) transparent transparent transparent;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
      }
      
      .day.active:hover .tooltip {
        opacity: 1;
        visibility: visible;
      }
    `;
  }

  render() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    
    const daysInMonth = new Date(currentYear, this.selectedMonth + 1, 0).getDate();
    const firstDayOfMonth = new Date(currentYear, this.selectedMonth, 1).getDay();
    const calendarDays = Array.from({ length: daysInMonth }, (_, i) => i + 1);
    const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    
    return html`
      <div class="calendar">
        <div class="calendar-container">
          <div class="calendar-header-container">
            <div class="calendar-header">${this.selectedMonthName} ${currentYear}</div>
          </div>
          <div class="weekdays">
            ${weekdays.map((day) => html`<div class="weekday-days">${day}</div>`)}
          </div>
          <div class="calendar-days">
            ${Array(firstDayOfMonth)
              .fill('')
              .map(() => html`<div class="day title"></div>`)}
            ${calendarDays.map((day) => this.renderDay(day))}
          </div>
        </div>
      </div>
    `;
  }

  renderDay(day) {
    // Find all dividends for the given day
    // TODO: We are doing some extra work for no timezone
    const dividendsForDay = this.dividends.filter(dividend => {
      const paymentDateParts = dividend.paymentDate.split('-');
      const dividendYear = parseInt(paymentDateParts[0], 10);
      const dividendMonth = parseInt(paymentDateParts[1], 10) - 1; // Month is zero-based
      const dividendDay = parseInt(paymentDateParts[2], 10);
    
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
    
      return (
        dividendDay === day &&
        dividendMonth === this.selectedMonth &&
        dividendYear === currentYear
      );
    });
  
    if (dividendsForDay.length === 0) {
      return html`
        <div class="day">
          ${day}
        </div>
      `;
    }
  
    // Calculate the total amount for the day based on dividend value multiplied by the number of shares
    const totalAmount = dividendsForDay.reduce((sum, dividend) => {
      const strippedSheetData = this.sheetData.slice(1);
      const totalShares = strippedSheetData.reduce((sum, row) => {
        if (row[2] === dividend.symbol) {
          return sum + parseFloat(row[4]); // Assuming the number of shares is in the 4th index of the row
        }
        return sum;
      }, 0);
      const dividendValue = dividend.dividend * totalShares;
      return sum + dividendValue;
    }, 0);
  
    const maxAmount = 100; // Define the maximum dividend amount (adjust as needed)
    const amountRatio = totalAmount / maxAmount;

    return html`
      <div class="day active" style="background-color: rgba(66, 133, 244, ${amountRatio});">
        <div class="tooltip">
          <div>$${addCommasToNumber(totalAmount)} in dividends</div>
        </div>
        ${day}
      </div>
    `;
  }
}

customElements.define('dividend-calendar-element', DividendCalendarElement);