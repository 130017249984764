import { LitElement, html, css } from 'lit';
import './spinner-element';
import './dropdown-element';
import './portfolio-stats-card-element';
import './benchmark-stats-card-element';
import './top-performing-card-element';
import './modals/add-benchmark-modal';
import plus from './images/plus.svg';
import { addCommasToNumber, createStockValuesObject } from './util';

const GOOGLE_API_KEY = 'AIzaSyAcwLc7fO1RjJxq7a3aFAFUHafO04rdDPc';
const DISCOVERY_DOC = 'https://sheets.googleapis.com/$discovery/rest?version=v4';
const FINANCE_API_KEY = 'f27bbd28829cfd17713d89497a16ae75';

class PerformanceElement extends LitElement {
  static get properties() {
    return {
      benchmarkPrices: Object,
      benchmarks: Array,
      dateRange: String,
      dateRangeChanged: Boolean,
      dataTable: Object,
      endDate: String,
      isBenchmarking: Boolean,
      latestDetail: String,
      loadingBenchmarks: Boolean,
      loadingPerformance: Boolean,
      loadingStats: Boolean,
      loadingTopData: Boolean,
      noBenchmarks: Boolean,
      sheetData: Object,
      spreadsheetId: String,
      startDate: String,
      tickerShares: Number,
    };
  }

  constructor() {
    super();
    this.benchmarkPrices = {};
    this.benchmarks = [];
    this.dateRange = 'Last 7 days';
    this.dateRangeChanged = false;
    this.dataTable = {};
    this.endDate = new Date().toISOString().split('T')[0];
    this.isBenchmarking = false;
    this.latestDetail = 'All';
    this.loadingBenchmarks = false;
    this.loadingPerformance = true;
    this.loadingStats = true;
    this.loadingTopData = true;
    this.noBenchmarks = true;
    this.spreadsheetId = localStorage.getItem('spreadsheet_id');
    this.startDate = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000).toISOString().split('T')[0];
    this.tickerShares = 0;

    this._boundAddBenchmark = this.handleAddBenchmark.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();
    document.addEventListener('add-benchmark', this._boundAddBenchmark);
    this.addEventListener('open-benchmark-modal', this.handleNewBenchmarkClick);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    document.removeEventListener('add-benchmark', this._boundAddBenchmark);
    this.removeEventListener('open-benchmark-modal', this.handleNewBenchmarkClick);
  }

  firstUpdated() {
    google.charts.load('current', {'packages':['corechart']});
  
    this.getSheetData();
  }

  async createLineChart() {
    google.charts.load('current', { 'packages': ['corechart'] });
    google.charts.setOnLoadCallback(this.drawAllChart.bind(this));
  }

  async drawAllChart(addingBenchmark, selectedOption) {
    const option = selectedOption || 'All';
    switch (option) {
      case 'Sector':
        this.dataTable = await this.getSectorData();
        break;
      case 'Asset class':
        this.dataTable = await this.getInvestmentTypeData();
        break;
      case 'All':
        this.dataTable = await this.getAllData();
        break;
      default:
        // nothing
    }

    if (this.isBenchmarking) {
      const prevDataTable = this.dataTable;
      if (!addingBenchmark) {
        for (const benchmark of this.benchmarks) {
          this.dataTable = await this.getBenchmarkData(prevDataTable, benchmark.symbol);
        }
        await this.updateAllBenchmarkData();
      }
    }

    if (this.dataTable.length === 0) {
      return;
    }
  
    const options = {
      curveType: 'function',
      chartArea: {
        left: 80,
        top: 30,
        right: 30,
        bottom: 50,
      },
      legend: 'none',
      colors: ['#FF8800', '#0088FF', '#FFC400', '#FF4D00', '#00FF88', '#8800FF', '#00FFFF', '#FF0088', '#88FF00'],
      areaOpacity: 0.15,
      animation: {
        duration: 1000,
        easing: 'out',
        startup: true,
      },
      hAxis: {
        textStyle: {
          color: '#9A9AAF',
        },
        format: 'MMM dd, yyyy',
        gridlines: {
          color: '#EFF1F7',
        },
        minorGridlines: {
          color: 'transparent',
        },
        maxTextLines: 1,
      },
      vAxis: {
        textStyle: {
          color: '#9A9AAF',
        },
        gridlines: {
          color: '#EFF1F7',
        },
      },
    };

    setTimeout(() => {    
      const chart = new google.visualization.AreaChart(this.shadowRoot.querySelector('#performance_chart_div'));
      chart.draw(this.dataTable, options);
    }, 1);
  }

  async getAllData() {
    if (!this.sheetData) {
      return [];
    }

    const strippedSheetData = this.sheetData.slice(1);
    const tickers = [...new Set(strippedSheetData.map(row => row[2]))];
    let { startDate, endDate } = this.getDateRange();
    startDate = new Date(startDate);
    endDate = new Date(endDate);
    const stockData = await Promise.all(tickers.map(ticker => this.getStockHistoryRange(ticker, endDate, startDate)));

    // Create an object for the buy/dividend values
    const currentStockValues = {};
    for (const row of strippedSheetData) {
      // Skip rows where the row type is "Split"
      if (row[1] === "Split") {
        continue;
      }

      const date = row[0];
      const [month, day, year] = date.split('/');
      const newDateStr = new Date(`${year}-${month}-${day}`).toISOString().split('T')[0];
      const ticker = row[2];
      const shares = parseFloat(row[4]);
      const totalPurchaseAmount = parseFloat(row[7]);
      const dateObj = {};
      const newValue = {shares, totalPurchaseAmount};
      dateObj[newDateStr] = newValue;
      // If that stock has multiple trades and already exists, add a new one
      if (ticker in currentStockValues) {
        if (newDateStr in currentStockValues[ticker]) {
          // If the newDateObj key already exists, add shares and totalPurchaseAmount together
          currentStockValues[ticker][newDateStr].shares += newValue.shares;
          currentStockValues[ticker][newDateStr].totalPurchaseAmount += newValue.totalPurchaseAmount;
        } else {
          // Otherwise, set a new key/value pair inside the ticker
          currentStockValues[ticker][newDateStr] = newValue;
        }
      } else {
        currentStockValues[ticker] = dateObj;
      }
    }

    let stockValues = createStockValuesObject(stockData, endDate, startDate);

    // Update stockValues with the buy/dividend values in currentStockValues
    for (const symbol in currentStockValues) {
      for (const date in currentStockValues[symbol]) {
        const boughtDateShares = currentStockValues[symbol][date].shares;

        for (const stockDate in stockValues) {
          if (new Date(stockDate) >= new Date(date)) {
            const totalShares = stockValues[stockDate][symbol].shares + boughtDateShares;
            stockValues[stockDate][symbol].shares = totalShares;
            const total = stockValues[stockDate][symbol].shares * stockValues[stockDate][symbol].openPrice;
            stockValues[stockDate][symbol].total = total;
          }
        }
      }
    }

    // Initialize an array to store the data for the chart
    const data = [];

    // Iterate over stockValues and add each date and total value to the data array
    for (const date in stockValues) {
      const dateParts = date.split('-');
      const dateObj = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
      if (dateObj >= startDate && dateObj <= endDate) {
        const totalValue = Object.values(stockValues[date]).reduce((acc, stock) => acc + stock.total, 0);
        data.push([dateObj, totalValue]);
      }
    }
  
    const dataTable = new google.visualization.DataTable();
    dataTable.addColumn('date', 'Date');
    dataTable.addColumn('number', 'Total Value');
    dataTable.addRows(data);
  
    this.loadingPerformance = false;
    this.loadingStats = false;
    return dataTable;
  }

  async getBenchmarkData(prevDataTable, selectedOption) {
    // Define the start and end dates for the historical prices
    let { startDate, endDate } = this.getDateRange();
    startDate = new Date(startDate);
    endDate = new Date(endDate);

    const strippedSheetData = this.sheetData.slice(1);
    const firstDate = new Date(strippedSheetData[0][0]);
  
    // Fetch the historical prices for the S&P 500 using the getStockHistoryRange function
    const firstHistoricalPrice = await this.getStockHistoryRange(selectedOption, endDate, firstDate);
    const historicalPrices = await this.getStockHistoryRange(selectedOption, endDate, startDate);
    this.benchmarkPrices[selectedOption] = historicalPrices.historical;
    const tickers = [...new Set(strippedSheetData.map(row => row[2]))];
    const stockData = await Promise.all(tickers.map(ticker => this.getStockHistoryRange(ticker, endDate, startDate)));

    prevDataTable.addColumn('number', selectedOption);

    // Find the first available index value in the historical prices
    const firstIndexValue = firstHistoricalPrice.historical[firstHistoricalPrice.historical.length - 1]?.open;
    const lastIndexValue = firstHistoricalPrice.historical[0].open;

    // Initialize the previous index value to the first index value
    let previousIndexValue = lastIndexValue;

    // Create an object for the buy/dividend values
    const currentStockValues = {};
    for (const row of strippedSheetData) {
      const date = row[0];
      const [month, day, year] = date.split('/');
      const newDateStr = new Date(`${year}-${month}-${day}`).toISOString().split('T')[0];
      const ticker = row[2];
      const shares = parseFloat(row[4]);
      const totalPurchaseAmount = parseFloat(row[3]) * shares;
      const dateObj = {};
      const newValue = {shares, totalPurchaseAmount};
      dateObj[newDateStr] = newValue;
      // If that stock has multiple trades and already exists, add a new one
      if (ticker in currentStockValues) {
        if (newDateStr in currentStockValues[ticker]) {
          // If the newDateObj key already exists, add shares and totalPurchaseAmount together
          currentStockValues[ticker][newDateStr].shares += newValue.shares;
          currentStockValues[ticker][newDateStr].totalPurchaseAmount += newValue.totalPurchaseAmount;
        } else {
          // Otherwise, set a new key/value pair inside the ticker
          currentStockValues[ticker][newDateStr] = newValue;
        }
      } else {
        currentStockValues[ticker] = dateObj;
      }
    }

    let stockValues = createStockValuesObject(stockData, endDate, startDate);

    // Update stockValues with the buy/dividend values in currentStockValues
    for (const symbol in currentStockValues) {
      for (const date in currentStockValues[symbol]) {
        const boughtDateShares = currentStockValues[symbol][date].shares;
        const boughtTotal = currentStockValues[symbol][date].totalPurchaseAmount;

        for (const stockDate in stockValues) {
          if (new Date(stockDate) >= new Date(date)) {
            const totalShares = stockValues[stockDate][symbol].shares + boughtDateShares;
            stockValues[stockDate][symbol].shares = totalShares;
            const total = stockValues[stockDate][symbol].total + boughtTotal;
            stockValues[stockDate][symbol].total = total;
          }
        }
      }
    }

    // Find the first date in stockValues
    // that will be the total up until the start date
    const stockValuesStartDate = Object.keys(stockValues)[Object.keys(stockValues).length - 1];
    let totalPurchaseAmount = 0;

    for (const ticker in stockValues[stockValuesStartDate]) {
      totalPurchaseAmount += stockValues[stockValuesStartDate][ticker].total;
    }

    for (let i = prevDataTable.getNumberOfRows() - 1; i >= 0; i--) {
      const date = prevDataTable.getValue(i, 0);

      // Check if the date exists in the stockValues object and has a total value
      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, '0');
      const day = String(date.getUTCDate()).padStart(2, '0');

      const formattedDate = `${year}-${month}-${day}`;

      if (formattedDate in stockValues) {
        totalPurchaseAmount = 0;
        for (const ticker in stockValues[formattedDate]) {
          if ('total' in stockValues[formattedDate][ticker]) {
            totalPurchaseAmount += stockValues[formattedDate][ticker].total;
          }
        }
      }

      // Find the corresponding S&P 500 index value for the current date
      const indexValueEntry = historicalPrices.historical.find(priceEntry => {
        const priceDate = new Date(priceEntry.date);
        return (
          priceDate.getFullYear() === date.getFullYear() &&
          priceDate.getMonth() === date.getMonth() &&
          priceDate.getDate() === date.getDate()
        );
      });

      let indexValue;
      if (indexValueEntry) {
        // If index value is found, update the previous index value
        indexValue = indexValueEntry.open;
        previousIndexValue = indexValue;
      } else {
        // If index value is not found, use the previous index value
        indexValue = previousIndexValue;
      }

      // Calculate the benchmark value using the formula
      const benchmarkValue = totalPurchaseAmount * indexValue / firstIndexValue;

      // Update the cell value with the benchmark value
      const numColmns = prevDataTable.getNumberOfColumns();
      prevDataTable.setCell(i, numColmns - 1, benchmarkValue);
    }

  
    this.loadingPerformance = false;
    this.loadingStats = false;
    return prevDataTable;
  }

  async getInvestmentTypeData() {
    if (!this.sheetData) {
      return [];
    }
  
    const strippedSheetData = this.sheetData.slice(1);
    const investmentTypes = [...new Set(strippedSheetData.map(row => row[11]))];
    let { startDate, endDate } = this.getDateRange();
    startDate = new Date(startDate);
    endDate = new Date(endDate);

    const currentStockValues = {};
    for (const row of strippedSheetData) {
      const date = row[0];
      const dateString = new Date(date);
      const ticker = row[2];
      const shares = parseFloat(row[4]);
      const investmentType = row[11];

      const stockHistory = await this.getStockHistoryRange(ticker, endDate, startDate);

      if (ticker in currentStockValues) {
        currentStockValues[ticker][dateString] = { shares };
      } else {
        currentStockValues[ticker] = { ...stockHistory, investmentType, [dateString]: { shares } };
      }
    }

    let stockValues = createStockValuesObject(currentStockValues, endDate, startDate);

    // Update stockValues with the buy/dividend values in currentStockValues
    for (const symbol in currentStockValues) {
      for (const date in currentStockValues[symbol]) {
        const boughtDateShares = currentStockValues[symbol][date].shares;

        for (const stockDate in stockValues) {
          if (new Date(stockDate) >= new Date(date)) {
            const totalShares = stockValues[stockDate][symbol].shares + boughtDateShares;
            stockValues[stockDate][symbol].shares = totalShares;
            const total = stockValues[stockDate][symbol].shares * stockValues[stockDate][symbol].openPrice;
            stockValues[stockDate][symbol].total = total;
          }
        }
      }
    }
  
    const dataTable = new google.visualization.DataTable();
    dataTable.addColumn('date', 'Date');

    for (const investmentType of investmentTypes) {
      dataTable.addColumn('number', investmentType);
    }

    for (const date in stockValues) {
      const dateParts = date.split('-');
      const dateObj = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);

      if (dateObj >= startDate && dateObj <= endDate) {
        const dateData = [dateObj];

        for (const investmentType of investmentTypes) {
          let investmentTypeTotal = 0;

          for (const ticker in stockValues[date]) {
            if (stockValues[date][ticker].investmentType === investmentType) {
              investmentTypeTotal += stockValues[date][ticker].total;
            }
          }

          dateData.push(investmentTypeTotal);
        }

        dataTable.addRow(dateData);
      }
    }
  
    this.loadingPerformance = false;
    this.loadingStats = false;
    return dataTable;
  }

  // TODO: This is duplicate code of investmentTypeData
  async getSectorData() {
    if (!this.sheetData) {
      return [];
    }
  
    const strippedSheetData = this.sheetData.slice(1);
    const sectors = [...new Set(strippedSheetData.map(row => row[12]))];
    let { startDate, endDate } = this.getDateRange();
    startDate = new Date(startDate);
    endDate = new Date(endDate);

    const currentStockValues = {};
    for (const row of strippedSheetData) {
      const date = row[0];
      const dateString = new Date(date);
      const ticker = row[2];
      const shares = parseFloat(row[4]);
      const sector = row[12];

      const stockHistory = await this.getStockHistoryRange(ticker, endDate, startDate);

      if (ticker in currentStockValues) {
        currentStockValues[ticker][dateString] = { shares };
      } else {
        currentStockValues[ticker] = { ...stockHistory, sector, [dateString]: { shares } };
      }
    }

    let stockValues = createStockValuesObject(currentStockValues, endDate, startDate);

    // Update stockValues with the buy/dividend values in currentStockValues
    for (const symbol in currentStockValues) {
      for (const date in currentStockValues[symbol]) {
        const boughtDateShares = currentStockValues[symbol][date].shares;

        for (const stockDate in stockValues) {
          if (new Date(stockDate) >= new Date(date)) {
            const totalShares = stockValues[stockDate][symbol].shares + boughtDateShares;
            stockValues[stockDate][symbol].shares = totalShares;
            const total = stockValues[stockDate][symbol].shares * stockValues[stockDate][symbol].openPrice;
            stockValues[stockDate][symbol].total = total;
          }
        }
      }
    }
  
    const dataTable = new google.visualization.DataTable();
    dataTable.addColumn('date', 'Date');

    for (const sector of sectors) {
      dataTable.addColumn('number', sector);
    }

    for (const date in stockValues) {
      const dateParts = date.split('-');
      const dateObj = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);

      if (dateObj >= startDate && dateObj <= endDate) {
        const dateData = [dateObj];

        for (const sector of sectors) {
          let sectorTotal = 0;

          for (const ticker in stockValues[date]) {
            if (stockValues[date][ticker].sector === sector) {
              sectorTotal += stockValues[date][ticker].total;
            }
          }

          dateData.push(sectorTotal);
        }

        dataTable.addRow(dateData);
      }
    }
  
    this.loadingPerformance = false;
    this.loadingStats = false;
    return dataTable;
  }

  async getSheetData() {
    if (!this.spreadsheetId) {
      return;
    }

    try {
      await gapi.load('client', () => {
        gapi.client.init({
          apiKey: GOOGLE_API_KEY,
          discoveryDocs: [DISCOVERY_DOC],
        }).then(() => {
          gapi.client.load('sheets', 'v4', async () => {
            try {
              const response = await gapi.client.sheets.spreadsheets.values.get({
                spreadsheetId: this.spreadsheetId,
                range: 'Sheet1!A:M',
              });
      
              this.sheetData = response.result.values;
              this.loadingPerformance = false;
              this.createLineChart();

              if (!this.sheetData || this.sheetData.length <= 1) {
                this.loadingStats = false;
                this.loadingTopData = false;
              }
            } catch (sheetDataError) {
              // Handle the error from the sheet data fetch
              console.error("Error fetching sheet data:", sheetDataError);
              // You can implement specific error-handling logic here.
            }
          });
        });
      });
    } catch (error) {
      throw new Error(`Error: ${error.result.error.message}`);
    }
  }

  getDateRange() {
    const now = new Date();
    let startDate, endDate;

    switch (this.dateRange) {
      case 'Last 7 days':
        startDate = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000).toISOString().split('T')[0];
        endDate = now.toISOString().split('T')[0];
        break;
      case 'This month so far':
        startDate = new Date(now.getFullYear(), now.getMonth(), 1).toISOString().split('T')[0];
        endDate = now.toISOString().split('T')[0];
        break;
      case 'Last month':
        startDate = new Date(now.getFullYear(), now.getMonth() - 1, 1).toISOString().split('T')[0];
        endDate = new Date(now.getFullYear(), now.getMonth(), 0).toISOString().split('T')[0];
        break;
      case 'Last 3 months':
        startDate = new Date(now.getFullYear(), now.getMonth() - 2, 1).toISOString().split('T')[0];
        endDate = now.toISOString().split('T')[0];
        break;
      case 'Last 6 months':
        startDate = new Date(now.getFullYear(), now.getMonth() - 5, 1).toISOString().split('T')[0];
        endDate = now.toISOString().split('T')[0];
        break;
      case 'Last 1 year':
        startDate = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate()).toISOString().split('T')[0];
        endDate = now.toISOString().split('T')[0];
        break;
      case 'Last 5 years':
        startDate = new Date(now.getFullYear() - 5, now.getMonth(), now.getDate()).toISOString().split('T')[0];
        endDate = now.toISOString().split('T')[0];
        break;
      default:
        startDate = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000).toISOString().split('T')[0];
        endDate = now.toISOString().split('T')[0];
    }

    this.startDate = startDate;
    this.endDate = endDate;

    return {startDate, endDate};
  }

  async getHistoricDividend(ticker) {
    return await fetch(`https://financialmodelingprep.com/api/v3/historical-price-full/stock_dividend/${ticker}?&apikey=${FINANCE_API_KEY}`)
      .then(response => response.json());
  }

  // Get the historical stock prices for the given ticker and date
  async getStockHistoryRange(ticker, endDate, startDate) {
    const from = startDate.toISOString().split('T')[0];
    const to = endDate.toISOString().split('T')[0];
    return await fetch(`https://financialmodelingprep.com/api/v3/historical-price-full/${ticker}?from=${from}&to=${to}&apikey=${FINANCE_API_KEY}`)
      .then(response => response.json());
  }

  async handleAddBenchmark(evt) {
    this.isBenchmarking = true;
    this.loadingBenchmarks = true;
    this.noBenchmarks = false;
    
    // Check if any object in this.benchmarks has the same symbol
    const hasSymbol = this.benchmarks.some(benchmark => benchmark.symbol === evt.detail.symbol);
    
    if (!hasSymbol) {
      this.dataTable = await this.getBenchmarkData(this.dataTable, evt.detail.symbol);
      // Minus 3 to account for adding 2 later
      const index = this.dataTable.getNumberOfColumns() - 3;
      this.updateBenchmarkData(evt.detail, index, true);
    }
  }

  async calculateBenchmarkData(benchmarkObj, index) {
    if (!this.spreadsheetId) {
      return;
    }

    const spreadsheetId = this.spreadsheetId;
    const portfolioRange = 'Sheet1!A2:L';

    const portfolioData = await gapi.client.sheets.spreadsheets.values.get({
      spreadsheetId,
      range: portfolioRange,
    });
    const portfolioRows = portfolioData.result.values;

    if (!portfolioRows) {
      return;
    }

    let startValue = 0;
    let endValue = 0;

    startValue = Object.keys(this.dataTable).length !== 0 ? this.dataTable.getValue(0, index + 2) : 0;
    endValue = Object.keys(this.dataTable).length !== 0 ? this.dataTable.getValue(this.dataTable.getNumberOfRows() - 1, index + 2) : 0;

    const fromDate = new Date(this.startDate);
    fromDate.setHours(0, 0, 0, 0);
    const toDate = new Date(this.endDate);
    toDate.setHours(0, 0, 0, 0);

    let totalContributions = 0;
    let weightedContributions = 0;
    let dividendAmount = 0;

    const dividendHistory = await this.getHistoricDividend(benchmarkObj.symbol);

    // Get historical prices for the specific symbol
    const historicalPricesForSymbol = this.benchmarkPrices[benchmarkObj.symbol];

    dividendHistory.historical.forEach(dividendData => {
      // Check if the dividend date is within the specified date range
      const dividendDate = new Date(dividendData.date);
      dividendDate.setHours(0, 0, 0, 0);

      if (dividendDate >= fromDate && dividendDate <= toDate) {
        // Find the index of the row that matches the dividend date
        let dividendDateIndex = -1;
        for (let row = 0; row < this.dataTable.getNumberOfRows(); row++) {
          const rowDataDate = this.dataTable.getValue(row, 0); // Assuming date is in the first column
          if (rowDataDate.getTime() === dividendDate.getTime()) {
            dividendDateIndex = row;
            break;
          }
        }

        if (dividendDateIndex !== -1) {
          const matchingPriceData = historicalPricesForSymbol.find(priceData => {
            const priceDate = new Date(priceData.date);
            priceDate.setHours(0, 0, 0, 0);

            return priceDate.getTime() === dividendDate.getTime();
          });

          if (matchingPriceData) {
            // Calculate dividend amount based on the historical price and total amount
            const priceOfDay = matchingPriceData.open;

            if (priceOfDay) {
              const lastColumn = this.dataTable.getNumberOfColumns() - 1;
              const totalAmount = this.dataTable.getValue(dividendDateIndex, lastColumn);
              if (totalAmount) {
                const numberOfShares = totalAmount / priceOfDay;
                const dividendForSymbol = dividendData.dividend * numberOfShares;
                dividendAmount += dividendForSymbol;
              }
            }
          }
        }
      }
    });

    for (const row of portfolioRows) {
      const date = new Date(row[0]);
      const purchaseAmount = parseFloat(row[7]);

      if (date >= fromDate && date <= toDate) {
        totalContributions += purchaseAmount;

        const daysWeight = (toDate - date) / (24 * 60 * 60 * 1000); // Number of days from purchase to end date
        weightedContributions += purchaseAmount * (daysWeight / 365); // Assuming 365 days in a year
      }
    }

    // Calculate the money-weighted return (MWR)
    const mwr = (endValue - startValue - totalContributions) / (startValue + weightedContributions);

    this.portfolioReturn = addCommasToNumber((endValue - startValue).toFixed(2));
    this.portfolioReturnPercent = addCommasToNumber((mwr * 100).toFixed(2));
    this.dividendCount = addCommasToNumber((dividendAmount).toFixed(2));
    if (startValue !== 0) {
      this.dividendCountPercent = addCommasToNumber(((dividendAmount / startValue) * 100).toFixed(2));
    } else {
      this.dividendCountPercent = addCommasToNumber(((dividendAmount / endValue) * 100).toFixed(2));
    }
    this.capitalGain = addCommasToNumber(((endValue - startValue) - dividendAmount).toFixed(2));
    this.capitalGainPercent = addCommasToNumber((this.portfolioReturnPercent - this.dividendCountPercent).toFixed(2));

    const updatedBenchmark = {
      symbol: benchmarkObj.symbol,
      name: benchmarkObj.name,
      dividendCount: this.dividendCount,
      dividendCountPercent: this.dividendCountPercent,
      capitalGain: this.capitalGain,
      capitalGainPercent: this.capitalGainPercent,
      portfolioReturn: this.portfolioReturn,
      portfolioReturnPercent: this.portfolioReturnPercent,
    };

    return updatedBenchmark;
  }

  async updateAllBenchmarkData() {
    if (this.benchmarks.length === 0) {
      return;
    }

    const benchmarks = this.benchmarks.map(benchmark => {
      return {
          name: benchmark.name,
          symbol: benchmark.symbol
      };
    });

    this.benchmarks = [];

    benchmarks.forEach((benchmarkObj, index) => {
      (async () => {
          await this.updateBenchmarkData(benchmarkObj, index, false);
      })();
    });
  }

  async updateBenchmarkData(benchmarkObj, index, needsDrawn) {
    await gapi.load('client', async () => {
      await gapi.client.init({
        apiKey: GOOGLE_API_KEY,
        discoveryDocs: [DISCOVERY_DOC],
      });
  
      await gapi.client.load('sheets', 'v4');

      const updatedBenchmark = await this.calculateBenchmarkData(benchmarkObj, index);
      
      this.benchmarks = [...this.benchmarks, updatedBenchmark];

      if (needsDrawn) {
        this.drawAllChart(true, benchmarkObj.symbol);
        this.dateRangeChanged = false;
      }
    });
  }

  handleNewBenchmarkClick() {
    const addBenchmarkModal = this.shadowRoot.querySelector('add-benchmark-modal');
    const modalElement = addBenchmarkModal.shadowRoot.querySelector('modal-element');
    modalElement.setAttribute('show', '');
  }

  async handleOptionChanged(evt) {
    let selectedOption = evt.detail;
    this.loadingPerformance = true;
    this.loadingStats = true;

    if (selectedOption.includes('This') || selectedOption.includes('Last')) {
      this.dateRange = selectedOption;
      this.dateRangeChanged = true;
      selectedOption = this.latestDetail;
    }

    this.latestDetail = selectedOption;
    this.drawAllChart(false, selectedOption);
  }

  static get styles() {
    return css`
      .performance-chart-spinner {
        margin: auto;
      }

      .performance-container {
        display: flex;
        flex-wrap: wrap;
        gap: 28px;
        margin: 48px;
      }

      .performance-row {
        display: flex;
        width: 100%;
        gap: 28px;
        justify-content: center;
      }

      .performance-card-header {
        color: #2E2E3A;
        font-family: 'Montserrat', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }

      .performance-card {
        display: flex;
        width: 854px;
        height: 436.4px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        overflow: hidden;
        background-color: #fff;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 16px;
      }
  
      .header-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 790px;
        height: 32px;
        flex-shrink: 0;
        border-bottom: 1px solid #F2F3F7;
        background: #FFF;
        padding: 16px 32px;
      }

      .dropdown-container {
        display: flex;
      }

      .dropdown {
        display: flex;
        width: 220px;
        --dropdown-background-color: #FAFAFC;
        --dropdown-button-border: 1px solid #F2F3F7;
        --dropdown-button-padding: 10px;
      }

      .dropdown:first-child {
        margin-right: 12px;
      }

      .plus-icon {
        width: 12px;
        height: 12px;
      }

      .add-benchmark-btn {
        display: inline-flex;
        width: 126px;
        height: 36px;
        padding: 10px;
        border: none;
        align-items: center;
        cursor: pointer;
        gap: 6px;
        background: #3346E7;
        box-shadow: 0px 6px 8px 0px rgba(51, 70, 231, 0.20);
        color: #FFF;
        font-family: 'Montserrat', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 12px;
        letter-spacing: -0.12px;
      }
  
      .add-benchmark-btn:hover {
        background-color: #003580;
      }
  
      .chart {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
      }

      .chart.hidden {
        display: none;
      }

      .portfolio-stats-card-container {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }

      .benchmark-stats-card-container {
        display: flex;
        flex-direction: column;
      }

      .top-performing-card-container {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
  
      .no-data {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        color: #999999;
      }
  
      .no-data box-icon {
        width: 36px;
        height: 36px;
        fill: #999999;
      }
    `;
  }
  
  render() {
    return html`
      <add-benchmark-modal></add-benchmark-modal>
      <div class="performance-container">
        <div class="performance-row">
          <div class="performance-card-container">
            <text class="performance-card-header">Current Performance</text>
            <div class="performance-card">
              <div class="header-container">
                <div class="dropdown-container">
                  <dropdown-element class="dropdown" @option-selected=${this.handleOptionChanged} .items=${['Last 7 days', 'This month so far', 'Last month', 'Last 3 months', 'Last 6 months', 'Last 1 year', 'Last 5 years']}></dropdown-element>
                  <dropdown-element class="dropdown" @option-selected=${this.handleOptionChanged} .selectedText=${"Group by"} .items=${['All', 'Asset class', 'Sector']}></dropdown-element>
                </div>
                <button class="add-benchmark-btn" @click=${this.handleNewBenchmarkClick}>
                  <img src="${plus}" class="plus-icon" alt="Plus" />
                  Add Benchmark
                </button>
              </div>
              <div class="chart ${this.loadingPerformance || (!this.sheetData || this.sheetData.length <= 1) ? 'hidden' : ''}" id="performance_chart_div"></div>
              ${this.loadingPerformance
                ? html`<spinner-element class="performance-chart-spinner"></spinner-element>`
                : !this.sheetData || this.sheetData.length <= 1
                  ? html`
                    <div class="no-data">
                      <box-icon name='sad'></box-icon>
                      <p>You have not added any positions yet</p>
                    </div>
                  `
                  : ''}
            </div>
          </div>
          <div class="portfolio-stats-card-container">
            <text class="performance-card-header">Your Portfolio</text>
            <portfolio-stats-card-element .dataTable=${this.dataTable} .from=${this.startDate} .loadingStats=${this.loadingStats} .to=${this.endDate}></portfolio-stats-card-element>
          </div>
        </div>
        <div class="performance-row">
          <div class="benchmark-stats-card-container">
            <text class="performance-card-header">Your Benchmarks</text>
            ${this.loadingBenchmark
              ? html`<spinner-element></spinner-element>`
              : html`
                <benchmark-stats-card-element .benchmarks=${this.benchmarks} .isBenchmarking=${this.isBenchmarking} .loadingBenchmarks=${this.loadingBenchmarks} .noBenchmarks=${this.noBenchmarks}></benchmark-stats-card-element>
              `
            }
          </div>
          <div class="top-performing-card-container">
            <text class="performance-card-header">Top Trades</text>
            ${this.loadingBenchmark
              ? html`<spinner-element></spinner-element>`
              : html`
                <top-performing-card-element .loadingTopData=${this.loadingTopData} .sheetData=${this.sheetData}></top-performing-card-element>
              `
            }
          </div>
        </div>
      </div>
    `;
  }
}

customElements.define('performance-element', PerformanceElement);