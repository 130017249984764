import { LitElement, html, css } from 'lit';
import { Router } from '@vaadin/router';
import carbonArrow from './images/carbon_arrow-up.svg';
import './spinner-element';
import { addCommasToNumber } from './util';

const FINANCE_API_KEY = 'f27bbd28829cfd17713d89497a16ae75';
const GOOGLE_API_KEY = 'AIzaSyAcwLc7fO1RjJxq7a3aFAFUHafO04rdDPc';
const DISCOVERY_DOC = 'https://sheets.googleapis.com/$discovery/rest?version=v4';

class SummaryCardsElement extends LitElement {
  static get properties() {
    return {
      portfolioAmount: { type: Number },
      portfolioReturn: { type: Number },
      latestDividends: { type: Number },
      loadingCardDetails: { type: Boolean },
      taxableGainLoss: { type: Number },
      spreadsheetId: { type: String },
    };
  }

  constructor() {
    super();
    this.portfolioAmount = 0;
    this.portfolioReturn = 0;
    this.latestDividends = 0;
    this.taxableGainLoss = 0;
    this.spreadsheetId = localStorage.getItem('spreadsheet_id');
    this.loadingCardDetails = true;
  }

  async connectedCallback() {
    super.connectedCallback();
    await this.loadPortfolioSummary();
  }

  async getHistoricDividend(ticker) {
    return await fetch(`https://financialmodelingprep.com/api/v3/historical-price-full/stock_dividend/${ticker}?&apikey=${FINANCE_API_KEY}`)
      .then(response => response.json());
  }

  handleArrowClick(evt) {
    const grandparentElement = evt.target.parentElement.parentElement;
    const contentElement = grandparentElement.querySelector('.content-block');
    const contentHeaderElement = contentElement.querySelector('.content-header');
    const textValue = contentHeaderElement.textContent;

    switch (textValue) {
      case 'Total':
        Router.go('/app/positions');
        break;
      case 'Return':
        Router.go('/app/analytics/performance');
        break;
      case 'Annual Dividends':
        Router.go('/app/analytics/dividends');
        break;
      case 'Taxable Gain/Loss':
        Router.go('/app/sold');
        break;
      default:
        // Nothing
    }
  }

  async loadPortfolioSummary() {
    if (!this.spreadsheetId) {
      return;
    }
  
    try {
      gapi.load('client', async () => {
        await gapi.client.init({
          apiKey: GOOGLE_API_KEY,
          discoveryDocs: [DISCOVERY_DOC],
        });
  
        await gapi.client.load('sheets', 'v4');
  
        const spreadsheetId = this.spreadsheetId;
        const portfolioRange = 'Sheet1!A2:L';
        const taxableGainLossRange = 'Sheet2!J2:J';
  
        let portfolioData;
        try {
          portfolioData = await gapi.client.sheets.spreadsheets.values.get({
            spreadsheetId,
            range: portfolioRange,
          });
        } catch (portfolioError) {
          // Handle the error from the portfolio data fetch
          console.error("Error fetching portfolio data:", portfolioError);
          // You can implement specific error-handling logic here.
          return;
        }

        const portfolioRows = portfolioData.result.values;
  
        if (!portfolioRows) {
          this.loadingCardDetails = false;
          return;
        }
  
        this.portfolioAmount = addCommasToNumber(portfolioRows.reduce((acc, row) => acc + parseFloat(row[8]), 0));
        const totalGainLoss = portfolioRows.reduce((acc, row) => acc + parseFloat(row[9]), 0);
        const totalPurchaseAmount = portfolioRows.reduce((acc, row) => acc + parseFloat(row[7]), 0);
        this.portfolioReturn = addCommasToNumber(((totalGainLoss / totalPurchaseAmount) * 100));
  
        let tickerDividends = {};
        for (const row of portfolioRows) {
          const ticker = row[2];
          const shares = parseFloat(row[4]);
          const type = row[1];
          if ((type === 'Split' || type === 'Reinvestment' || type === 'Buy') && !tickerDividends[ticker]) {
            const dividendResponse = await this.getHistoricDividend(ticker);
            tickerDividends[ticker] = {dividend: dividendResponse.historical[0] ? dividendResponse.historical[0].dividend : 0};
          }
          tickerDividends[ticker]['shares'] = (tickerDividends[ticker]['shares'] || 0) + shares;
          tickerDividends[ticker]['annualPay'] = tickerDividends[ticker]['shares'] * tickerDividends[ticker]['dividend'];
        }
  
        this.latestDividends = addCommasToNumber((Object.keys(tickerDividends).reduce((total, key) => {
          const stock = tickerDividends[key];
          return total + stock.annualPay;
        }, 0) * 4));
  
        const taxableGainLossData = await gapi.client.sheets.spreadsheets.values.get({
          spreadsheetId,
          range: taxableGainLossRange,
        });
        const taxableGainLossRow = taxableGainLossData.result.values;
        this.taxableGainLoss = addCommasToNumber((taxableGainLossRow ? taxableGainLossRow.reduce((acc, row) => acc + parseFloat(row[0]), 0) : 0));
        this.loadingCardDetails = false;
      });
    } catch (error) {
      // Handle the error here
      console.error("Error in loadPortfolioSummary:", error);
    }
  }

  static get styles() {
    return css`
      .summary-card-container {
        margin-top: 16px;
      }

      .summary-card {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        overflow: hidden;
        background-color: #FFFFFF;
        margin: 12px 0;
      }

      .summary-card:last-child {
        margin: 12px 0 0 0;
      }

      .summary-card-details {
        display: flex;
        width: 262px;
        justify-content: space-between;
        align-items: flex-start;
        padding: 24px 20px;
      }

      .square {
        width: 32px;
        height: 32px;
        flex-shrink: 0;
        cursor: pointer;
      }

      .square.arrow-1 {
        background: #E0E3FB;
      }

      .square.arrow-2 {
        background: #F3D0D5;
      }

      .square.arrow-3 {
        background: #FFE9D0;
      }

      .square.arrow-4 {
        background: #D0F7FF;
      }

      img {
        width: 20px;
        height: 20px;
        margin-left: 6px;
        margin-top: 6px;
        flex-shrink: 0;
      }

      spinner-element {
        width: 16px;
        height: 16px;
      }
  
      .content-block {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
      }

      .portfolio-header {
        color: #2E2E3A;
        font-family: 'Montserrat', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }
  
      .content-header {
        color: #9A9AAF;
        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 16px;
        letter-spacing: -0.16px;
      }
  
      .content-value {
        color: #2E2E3A;
        font-family: 'Montserrat', sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: -0.24px;
      }
    `;
  }
  
  render() {
    return html`
      <text class="portfolio-header">Your Portfolio</text>
      <div class="summary-card-container">
        <div class="summary-card">
          <div class="summary-card-details">
            <div class="content-block">
              <text class="content-header">Total</text>
              ${this.loadingCardDetails
                ? html`<spinner-element></spinner-element>`
                : html`
                  <div class="content-value">$${this.portfolioAmount == 0 ? '0.00' : this.portfolioAmount}</div>
                `
              }
            </div>
            <div class="square arrow-1" @click="${this.handleArrowClick}">
              <img src="${carbonArrow}" alt="Arrow" />
            </div>
          </div>
        </div>
        <div class="summary-card">
          <div class="summary-card-details">
            <div class="content-block">
              <text class="content-header">Return</text>
              ${this.loadingCardDetails
                ? html`<spinner-element></spinner-element>`
                : html`
                  <div class="content-value">${this.portfolioReturn == 0 ? '0.00' : this.portfolioReturn}%</div>
                `
              }
            </div>
            <div class="square arrow-2" @click="${this.handleArrowClick}">
              <img src="${carbonArrow}" alt="Arrow" />
            </div>
          </div>
        </div>
        <div class="summary-card">
          <div class="summary-card-details">
            <div class="content-block">
              <text class="content-header">Annual Dividends</text>
              ${this.loadingCardDetails
                ? html`<spinner-element></spinner-element>`
                : html`
                  <div class="content-value">$${this.latestDividends == 0 ? '0.00' : this.latestDividends}</div>
                `
              }
            </div>
            <div class="square arrow-3" @click="${this.handleArrowClick}">
              <img src="${carbonArrow}" alt="Arrow" />
            </div>
          </div>
        </div>
        <div class="summary-card">
          <div class="summary-card-details">
            <div class="content-block">
              <text class="content-header">Taxable Gain/Loss</text>
              ${this.loadingCardDetails
                ? html`<spinner-element></spinner-element>`
                : html`
                  <div class="content-value">$${this.taxableGainLoss == 0 ? '0.00' : this.taxableGainLoss}</div>
                `
              }
            </div>
            <div class="square arrow-4" @click="${this.handleArrowClick}">
              <img src="${carbonArrow}" alt="Arrow" />
            </div>
          </div>
        </div>
      </div>
    `;
  }
}

customElements.define('summary-cards-element', SummaryCardsElement);
