import { LitElement, html, css } from 'lit';
import { addCommasToNumber, dashDateToFullDate } from './util';

class DividendCalendarItemsElement extends LitElement {
  static get properties() {
    return {
      dividends: Array,
      loadingDividends: Boolean,
      selectedMonth: Number,
      selectedMonthName: String,
      sheetData: Object,
      spreadsheetId: String,
    };
  }

  constructor() {
    super();
  }

  static get styles() {
    return css`
      .list-container {
        display: inline-flex;
        flex-direction: column;
        gap: 8px;
      }

      .dividend-payout-card {
        width: 432px;
        height: 91.25px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        overflow: hidden;
        background-color: #fff;
        flex-direction: row;
      }

      .card-container {
        display: flex;
        flex-direction: row;
      }

      .left-content-container {
        width: 69px;
        height: 59.25px;
        background: linear-gradient(270deg, rgba(242, 243, 247, 0.49) 0%, rgba(255, 255, 255, 0.00) 100%);
        padding: 16px;
      }

      .calendar-content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 59.25px;
        gap: 4px;
        left: 20px;
        top: 16px;
      }

      .calendar-month {
        color: #2E2E3A;
        text-align: center;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 114.286% */
        letter-spacing: -0.14px;
      }

      .calendar-day {
        color: #2E2E3A;
        text-align: center;
        font-family: 'Montserrat', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 120% */
        letter-spacing: -0.2px;
      }

      .right-content-container {
        display: flex;
        justify-content: space-between;
        width: 299px;
        height: 45.25px;
        padding: 23px 16px;
      }

      .company-information {
        display: inline-flex;
        flex-direction: column;
        gap: 4px;
        width: 222px;
        height: 59.25px;
        flex-shrink: 0;
      }

      .company-name {
        color: #2E2E3A;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: -0.14px;
      }

      .payout-interval {
        color: #535362;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: -0.14px;
      }

      .loading-card {
        display: flex;
        justify-content: center;
        width: 400px;
        min-height: 40px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        background-color: #fff;
        padding: 16px;
      }

      .no-data {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        color: #999999;
      }

      .no-data box-icon {
        width: 36px;
        height: 36px;
        fill: #999999;
      }
    `;
  }

  render() {    
    return html`
      <div class="list-container">
        ${this.loadingDividends
          ? html`
            <div class="loading-card">
              <spinner-element></spinner-element>
            </div>
          `
          : this.dividends.length > 0
            ? this.dividends.map(dividend => {
              const strippedSheetData = this.sheetData.slice(1);
              const totalShares = strippedSheetData.reduce((sum, row) => {
                if (row[2] === dividend.symbol) {
                  return sum + parseFloat(row[4]); // Assuming the number of shares is in the 4th index of the row
                }
                return sum;
              }, 0);
              const dividendValue = dividend.dividend * totalShares;
  
              const paymentDate = dividend.paymentDate;
              const parsedDate = dashDateToFullDate(paymentDate); // Define and implement the dashDateToFullDate function
  
              // Extract month name and day from parsedDate
              const months = [
                'January', 'February', 'March', 'April', 'May', 'June',
                'July', 'August', 'September', 'October', 'November', 'December'
              ];
  
              const monthName = months[parsedDate.getMonth()];
              const day = parsedDate.getDate();
  
              return html`
                <div class="dividend-payout-card">
                  <div class="card-container">
                    <div class="left-content-container">
                      <div class="calendar-content-container">
                        <text class="calendar-month">${monthName}</text>
                        <text class="calendar-day">${day}</text>
                      </div>
                    </div>
                    <div class="right-content-container">
                      <div class="company-information">
                        <div class="company-name">${dividend.companyName}</div>
                        <div class="payout-interval">${dividend.isQuarterly ? 'Quarterly' : 'Monthly'}</div>
                      </div>
                      <div class="dividend-payout-value">$${addCommasToNumber(dividendValue)}</div>
                    </div>
                  </div>
                </div>
              `;
            })
            : html`
              <div class="loading-card">
                <div class="no-data">
                  <box-icon name='sad'></box-icon>
                  <p>There are no dividends for this month</p>
                </div>
              </div>
            `
        }
      </div>
    `;
  }  
}

customElements.define('dividend-calendar-items-element', DividendCalendarItemsElement);