import { html, css, LitElement } from 'lit';

class ToggleSwitch extends LitElement {
  static get properties() {
    return {
      on: { type: Boolean },
      title: { type: String },
    };
  }

  constructor() {
    super();
    this.on = true;
    this.title = '';
  }

  static get styles() {
    return css`
      :host {
        --background-color: green;
      }

      .toggle-switch-container {
        display: flex;
        align-items: center;
      }

      .toggle-switch {
        position: relative;
        display: inline-block;
        width: 40px;
        height: 20px;
        border-radius: 10px;
        background-color: grey;
        transition: background-color 0.3s ease;
        cursor: pointer;
      }

      .toggle-switch::before {
        content: '';
        position: absolute;
        top: 2px;
        left: 2px;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: white;
        transition: transform 0.3s ease;
      }

      .toggle-switch.on {
        background-color: var(--background-color);
      }

      .toggle-switch.on::before {
        transform: translateX(20px);
      }

      .title {
        margin-left: 8px;
        color: #2E2E3A;
        font-family: Outfit;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 12px; /* 85.714% */
        letter-spacing: -0.14px;
      }
    `;
  }

  render() {
    return html`
      <div class="toggle-switch-container">
        <div
          class="toggle-switch ${this.on ? 'on' : ''}"
          @click="${this.toggleSwitch}"
        ></div>
        <span class="title">${this.title}</span>
      </div>
    `;
  }

  toggleSwitch() {
    this.on = !this.on;
    this.dispatchEvent(new CustomEvent('switch-toggled', { bubbles: true, composed: true, detail: this.on }));
  }
}

customElements.define('toggle-switch-element', ToggleSwitch);
