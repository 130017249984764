import { LitElement, html, css } from 'lit';
import './modal-element';
import '../datepicker-input-element';
import '../ticker-search-element';

import {
  createMasterListObject,
  sortPositionMasterList
} from '../util';

const FINANCE_API_KEY = 'f27bbd28829cfd17713d89497a16ae75';

class BuyFormModal extends LitElement {
  static get properties() {
    return {
      canBeSold: Boolean,
      currentTickers: Array,
      date: String,
      historicalOpenPrice: Number,
      selectedTransactionType: String,
      sellSheetData: Array,
      sortedPositionMasterList: Array,
      submitDisabled: Boolean,
      tableData: Array,
      totalValue: Number,
      ticker: String,
      showHistoricalPrice: Boolean,
    };
  }

  constructor() {
    super();
    this.canBeSold = false;
    this.currentTickers = [];
    this.ticker = '';
    this.tableData = [];
    this.selectedTransactionType = 'buy';
    this.sellSheetData = [];
    this.sortedPositionMasterList = [];
    this.submitDisabled = true;
    this._boundDateChange = this.handleDateChanged.bind(this);
    this._boundHistoricalPriceChanged = this.handleHistoricPriceChanged.bind(this);
    this._boundSubmitChange = this.updateSubmitDisabled.bind(this);
    this._boundTickerCleared = this.handleTickerCleared.bind(this);
    this.date = new Date().toLocaleDateString('en-US');
    this.totalValue = 0;
    this.showHistoricalPrice = false;
  }

  connectedCallback() {
    super.connectedCallback();
    document.addEventListener('date-changed', this._boundDateChange);
    document.addEventListener('historical-price-changed', this._boundHistoricalPriceChanged);
    document.addEventListener('submit-changed', this._boundSubmitChange);
    document.addEventListener('ticker-cleared', this._boundTickerCleared);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    document.removeEventListener('date-changed', this._boundDateChange);
    document.removeEventListener('historical-price-changed', this._boundHistoricalPriceChanged);
    document.addEventListener('submit-changed', this._boundSubmitChange);
    document.removeEventListener('ticker-cleared', this._boundTickerCleared);
  }

  async willUpdate(changedProperties) {
    if (changedProperties.has('ticker')) {
      // Reset price when changing tickers
      const priceInput = this.shadowRoot.querySelector('#price');
      if (priceInput) {
        priceInput.value = ''; 
      }
      this.historicalOpenPrice = null;
      this.showHistoricalPrice = false;

      if (this.currentTickers && this.currentTickers.includes(this.ticker)) {
        this.canBeSold = true;
      } else {
        // We have to manually check the radio button for some reason
        const buyRadioButton = this.shadowRoot.querySelector('input[value="buy"]');
        if (buyRadioButton) {
          buyRadioButton.checked = true;
        }

        this.selectedTransactionType = 'buy';
        this.canBeSold = false;
      }
    }

    if (changedProperties.has('date')) {
      this.historicalOpenPrice = null;
      this.showHistoricalPrice = false;
    }

    if ((changedProperties.has('sellSheetData') || changedProperties.has('tableData')) && this.tableData && this.sellSheetData) {
      const positionMasterList = await createMasterListObject(true, this.tableData, this.sellSheetData);
      this.sortedPositionMasterList = sortPositionMasterList(positionMasterList);
    }
  }

  calculateTotalValue() {
    return this.historicalOpenPrice;
  }

  // Get the historical stock split information
  async getHistoricStockSplit(ticker) {
    return await fetch(`https://financialmodelingprep.com/api/v3/historical-price-full/stock_split/${ticker}?apikey=${FINANCE_API_KEY}`)
      .then(response => response.json());
  }

  async getStockHistoryRange(ticker, date) {
    const dateStr = new Date(date);
    const year = dateStr.getFullYear();
    const month = (dateStr.getMonth() + 1).toString().padStart(2, '0');
    const day = dateStr.getDate().toString().padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    return await fetch(`https://financialmodelingprep.com/api/v3/historical-price-full/${ticker}?from=${formattedDate}&to=${formattedDate}&apikey=${FINANCE_API_KEY}`)
      .then(response => response.json());
  }

  handleCloseClick() {
    const modalElement = this.shadowRoot.querySelector('modal-element');
    modalElement.removeAttribute('show');
    this.resetValues();
  }

  async handleDateChanged(evt) {
    this.date = evt.detail.date;
    this.keysTypedHistoricalPriceDebounce();
  }

  handleHistoricPriceChanged({ detail }) {
    const {
      symbol
    } = detail;

    this.ticker = symbol;
    this.keysTypedHistoricalPriceDebounce();
  }

  async handleHistoricPriceInput() {
    if (!this.ticker || !this.date) {
      return;
    }

    const stockHistory = await this.getStockHistoryRange(this.ticker, this.date);
    this.historicalOpenPrice = Object.values(stockHistory).length !== 0 ? stockHistory.historical[0].open : null;
  }

  populatePriceInput() {
    const priceInput = this.shadowRoot.querySelector('input[name="price"]');

    if (priceInput) {
      priceInput.value = this.historicalOpenPrice;
    }

    this.showHistoricalPrice = true;
  }

  handleTickerCleared() {
    const clearedField = event.detail.field;

    if (clearedField === 'ticker') {
      this.ticker = '';
    }
  }

  handleNumberOfSharesInput() {
    try {
      const numberOfSharesInput = this.shadowRoot.querySelector('#number_of_shares');
      const tickerSearchElement = this.shadowRoot.querySelector('#ticker');
      const tickerInput = tickerSearchElement.shadowRoot.querySelector('#tickerInput');
      const selectedTicker = tickerInput.value.toUpperCase(); // Assuming ticker values are case-insensitive
      const dateStr = new Date(this.date);
      
      // Iterate through the sortedPositionMasterList to calculate the exact shares
      let totalShares = 0;
      for (let i = 0; i < this.sortedPositionMasterList.length; i++) {
        const transaction = this.sortedPositionMasterList[i];
        const transactionDate = transaction.date;

        // Skip transactions for other tickers or after the specified date
        if (transaction.ticker !== selectedTicker || new Date(transactionDate) > dateStr) {
          continue;
        }

        // Skip transactions after the specified date
        if (new Date(transactionDate) > dateStr) {
          continue;
        }

        // Update totalShares based on transaction type
        switch (transaction.type) {
          case 'Buy':
            totalShares += parseFloat(transaction.shares);
            break;
          case 'Sell':
            totalShares -= parseFloat(transaction.shares);
            break;
          case 'Reinvestment':
            totalShares += parseFloat(transaction.shares);
            break;
          case 'Split':
            totalShares += parseFloat(transaction.shares);
            break;
        }
      }
  
      // Check if the user is trying to sell more shares than they own
      if (parseFloat(numberOfSharesInput.value) > totalShares) {
        this.sellError = true;
      } else {
        this.sellError = false;
      }
  
      this.updateSubmitDisabled();
    } catch (error) {
      console.error(error);
    }
  }

  handleSubmit() {
    const form = this.shadowRoot.querySelector('form');
    const formData = new FormData(form);

    const transactionType = formData.get("transactionType");

    const dateStr = new Date(this.date);
    const month = (dateStr.getMonth() + 1).toString().padStart(2, '0');
    const day = dateStr.getDate().toString().padStart(2, '0');
    const year = dateStr.getFullYear().toString();
    const formattedDate = `${month}/${day}/${year}`;
    const date = formattedDate;

    const ticker = this.ticker.toUpperCase();
    const price = formData.get('price');
    const numberOfShares = formData.get('number_of_shares');
    const fee = formData.get('fee');

    const formEnteredObj = {
      transactionType,
      date,
      ticker,
      price,
      numberOfShares,
      fee
    };

    this.dispatchEvent(new CustomEvent('detailed-submit', { bubbles: true, composed: true, detail: { formEnteredObj }}));
    this.ticker = '';
    const tickerSearchElement = this.shadowRoot.querySelector('#ticker');
    tickerSearchElement.activeExchange = '';
    form.reset();
  }

  handleTotalValueKeysUp() {
    const priceInput = this.shadowRoot.querySelector('#price');
    const numberOfSharesInput = this.shadowRoot.querySelector('#number_of_shares');
    const feeInput = this.shadowRoot.querySelector('#fee');

    const price = Number(priceInput.value) || (this.historicalOpenPrice || 0);
    const numberOfShares = Number(numberOfSharesInput.value) || 0;
    const fee = Number(feeInput.value) || 0;

    this.totalValue = (price * numberOfShares) + fee;
  }

  handleTypeChange(evt) {
    if (!this.canBeSold || !evt.target.value) {
      return;
    }

    this.selectedTransactionType = evt.target.value;
    this.updateSubmitDisabled();
  }

  keysTypedHistoricalPriceDebounce() {
    setTimeout(() => {
      this.handleHistoricPriceInput();
    }, 800);
  }

  resetValues() {
    this.date = new Date().toLocaleDateString('en-US');
    this.historicalOpenPrice = null;
    this.selectedTransactionType = 'buy';
    const tickerSearchElement = this.shadowRoot.querySelector('#ticker');
    tickerSearchElement.activeExchange = '';
    const tickerInputElement = tickerSearchElement.shadowRoot.querySelector('#tickerInput');
    tickerInputElement.value = '';
    this.shadowRoot.querySelector('#price').value = '';
    this.shadowRoot.querySelector('#number_of_shares').value = '';
    this.shadowRoot.querySelector('#fee').value = '';
    this.ticker = '';
    this.totalValue = 0;
  }

  updateSubmitDisabled() {
    const tickerSearchElement = this.shadowRoot.querySelector('#ticker');
    const tickerInput = tickerSearchElement.shadowRoot.querySelector('#tickerInput');
    const priceInput = this.shadowRoot.querySelector('#price');
    const numberOfSharesInput = this.shadowRoot.querySelector('#number_of_shares');
    const feeInput = this.shadowRoot.querySelector('#fee');

    const allInputsHaveText = tickerInput.value.trim() !== '' &&
      priceInput.value.trim() !== '' &&
      numberOfSharesInput.value.trim() !== '' &&
      feeInput.value.trim() !== '';

    this.submitDisabled = !allInputsHaveText || (this.sellError && this.selectedTransactionType === 'sell');

    if (priceInput.value === '') {
      this.showHistoricalPrice = false;
    }
  }

  static get styles() {
    return css`
      .form-container {
        display: inline-flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
      }

      form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
      }
      
      label {
        display: block;
        color: #2E2E3A;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: -0.14px;
      }
      
      input[type="text"],
      input[type="number"] {
        display: flex;
        width: 330px;
        padding: 10px 10px 10px 10px;
        align-items: center;
        gap: 8px;
        border: 1px solid #EFF1F7;
      }

      input[type="radio"] {
        margin: 0;
      }
      
      .form-field {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
      }

      .form-field-side {
        display: flex;
        align-items: flex-start;
        gap: 16px;
      }
      
      .form-field-half {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
      }

      .radio-container {
        display: flex;
        width: 170px;
        padding: 10px 12px;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #EFF1F7;
        background: #F2F3F7;
      }

      .modal-well {
        display: flex;
        justify-content: center;
        width: 312px;
        height: 20px;
        flex-shrink: 0;
        align-items: center;
        background: #F2F3F7;
        padding: 20px;
        margin: 0 0 20px 0;
      }

      .total-container {
        display: inline-flex;
        align-items: center;
        gap: 6px;
      }
    
      .total {
        color: #2E2E3A;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: -0.14px;
      }

      .total-value {
        color: #2E2E3A;
        font-family: 'Montserrat', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: -0.2px;
      }

      .radio-label {
        display: flex;
        align-items: center;
        cursor: pointer;
        gap: 8px;
      }

      .radio-label[active] {
        color: #3346E7;
      }

      .radio-label[disabled] {
        cursor: default;
      }

      .radio-label:hover {
        color: #3346E7;
        border-radius: 4px;
      }

      .radio-text {
        color: #535362;
        font-family: 'Montserrat', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 12px;
        letter-spacing: -0.12px;
      }

      .radio-text.active {
        color: #3346E7;
        font-family: 'Montserrat', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 12px;
        letter-spacing: -0.12px;
      }

      .toggle {
        --background-color: #3346E7;
      }

      .price-container {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: flex-end;
      }

      .price-text {
        color: #007bff;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: -0.14px;
        margin-left: auto;
      }

      .price-text:hover {
        color: #0056b3;
      }

      .error-message {
        display: flex;
        color: #F84439;
        font-size: 12px;
        align-items: center;
      }
    
      .error-icon {
        width: 14px;
        height: 14px;
        fill: #F84439;
        margin-right: 5px;
      }
    `;
  }

  render() {
    return html`
      <modal-element class="modal-element" .submitDisabled="${this.submitDisabled}" @close=${this.handleCloseClick} @submit=${this.handleSubmit}>
        <div slot="modal-slot" class="form-container">
          <form>
            <div class="form-field">
              <label for="ticker">Ticker</label>
              <ticker-search-element id="ticker" .searchText=${this.ticker}></ticker-search-element>
            </div>
  
            <div class="form-field-side">
              <div class="form-field-half">
                <label for="transactionType">Transaction Type</label>
                <div class="radio-container">
                  <label class="radio-label" @click=${this.handleTypeChange} ?active=${this.selectedTransactionType === 'buy'}>
                    <input type="radio" name="transactionType" value="buy" @change=${this.onTransactionTypeChange} ?checked=${this.selectedTransactionType === 'buy'} />
                    <span class="radio-text ${this.selectedTransactionType === 'buy' ? 'active' : ''}">Buy Trade</span>
                  </label>
                  <label class="radio-label" @click=${this.handleTypeChange} ?active=${this.selectedTransactionType === 'sell'} ?disabled=${!this.canBeSold}>
                    <input type="radio" name="transactionType" value="sell" @change=${this.onTransactionTypeChange} ?checked=${this.selectedTransactionType === 'sell'} ?disabled=${!this.canBeSold} />
                    <span class="radio-text ${this.selectedTransactionType === 'sell' ? 'active' : ''}">Sell Trade</span>
                  </label>
                </div>
              </div>
              <div class="form-field-half">
                <label for="date">Transaction Date</label>
                <datepicker-input-element placeholder="Transaction Date" .date=${this.date}></datepicker-input-element>
              </div>
            </div>

            <div class="form-field">
              <div class="price-container">
                <label for="price">Price (per share)</label>
                <a class="price-text" href="#" @click="${this.populatePriceInput}" ?hidden="${!this.ticker || this.showHistoricalPrice}">
                ${this.historicalOpenPrice !== undefined && this.historicalOpenPrice !== null ? '$' + this.historicalOpenPrice : ''}</a>
              </div>
              <input type="number" id="price" name="price" autocomplete="off" placeholder="1000.00" @input="${this.updateSubmitDisabled}" @keyup=${this.handleTotalValueKeysUp} />
            </div>

            <div class="form-field">
              <label for="number_of_shares">Number of shares</label>
              <input type="number" id="number_of_shares" name="number_of_shares" autocomplete="off" placeholder="100.00" @input="${this.handleNumberOfSharesInput}" @keyup=${this.handleTotalValueKeysUp} />

              ${this.sellError && this.selectedTransactionType === 'sell'
                ? html`
                  <div class="error-message">
                    <box-icon class="error-icon" type='solid' name='error-circle'></box-icon>
                    You can't sell more shares than you have
                  </div>`
                : ''}
            </div>
  
            <div class="form-field">
              <label for="fee">Brokerage Fee</label>
              <input type="number" id="fee" name="fee" autocomplete="off" placeholder="10.00" @input="${this.updateSubmitDisabled}" @keyup=${this.handleTotalValueKeysUp} />
            </div>
          </form>
          <div class="modal-well">
            <div class="total-container">
              <div class="total">Total = </div><div class="total-value">$${this.totalValue.toFixed(2)}</div>
            </div>
          </div>
        </div>
      </modal-element>
    `;
  }
}

customElements.define('buy-form-modal', BuyFormModal);