import { LitElement, html, css } from 'lit';
import './spinner-element';
import './stocks-filter-element';
import './toggle-switch-element';
import './toast-message-element';
import edit from './images/edit.svg';
import {
  addCommasToNumber,
  calculateNumberFields,
  dashDateToSlashDate,
  slashDateToDashDate
} from './util';
import { v4 as uuidv4 } from 'uuid';

const FINANCE_API_KEY = 'f27bbd28829cfd17713d89497a16ae75';

class CurrentPositionsDividendsElement extends LitElement {
  static get properties() {
    return {
      activeTab: Number,
      calculatedShares: Number,
      currentTickers: Array,
      dividendData: Object,
      dividendsState: Object,
      filteredSortedDividends: Array,
      loadingData: Boolean,
      reinvestmentsData: Object,
      sheetId: String,
      sortedDividends: Array,
      spreadsheetId: String,
      tableData: Array,
      tickerProfiles: Object,
    };
  }

  constructor() {
    super();

    this.activeTab = 0;
    this.calculatedShares = 0;
    this.currentTickers = [];
    this.dividendData = {};
    this.dividendsState = {};
    this.filteredSortedDividends = [];
    this.loadingData = true;
    this.reinvestmentsData = {};
    this.sortedDividends = [];
    this.spreadsheetId = localStorage.getItem('spreadsheet_id');
    this.tickerProfiles = {};
  }

  connectedCallback() {
    super.connectedCallback();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }

  async willUpdate(changedProperties) {
    if (changedProperties.has('tableData')) {
      if (this.currentTickers && this.currentTickers.length > 0) {
        this.earliestTickerDates = this.findEarliestTickerDate();
        this.getCurrentReinvestedSync(this.tableData);
        await this.fetchDividendDataForTickers();
        await this.getProfileData(this.currentTickers);
      }
    }
  }

  calculateDividendShares(row) {
    if (!this.tableData) {
      return;
    }

    let totalShares = 0;
    this.tableData.slice(1).forEach(tableRow => {
      const rowDate = tableRow[0];
      const ticker = tableRow[2];
      const shares = parseFloat(tableRow[4]);

      if (slashDateToDashDate(rowDate) <= row.date && ticker === row.ticker) {
        totalShares += shares;
      }
    });

    const { dividend, openPrice } = row;
    const dividendPayout = totalShares * dividend;
    const dividendShares = dividendPayout / openPrice;

    return dividendShares.toFixed(6);
  }

  calculatePayout(row) {
    if (!this.tableData) {
      return;
    }

    let totalShares = 0;
    this.tableData.slice(1).forEach(tableRow => {
      const rowDate = tableRow[0];
      const ticker = tableRow[2];
      const shares = parseFloat(tableRow[4]);

      if (slashDateToDashDate(rowDate) <= row.date && ticker === row.ticker) {
        totalShares += shares;
      }
    });

    const { dividend } = row;
    const dividendPayout = totalShares * dividend;

    return addCommasToNumber(dividendPayout);
  }

  async getHistoricDividend(ticker) {
    const response = await fetch(`https://financialmodelingprep.com/api/v3/historical-price-full/stock_dividend/${ticker}?&apikey=${FINANCE_API_KEY}`);
    const data = await response.json();
    return data.historical ? data.historical : [];
  }

  async getStockPriceHistory(ticker, from, to) {
    try {
      const response = await fetch(`https://financialmodelingprep.com/api/v3/historical-price-full/${ticker}?from=${from}&to=${to}&apikey=${FINANCE_API_KEY}`);
      const stockPriceData = await response.json();
      
      if (stockPriceData.historical && stockPriceData.historical.length > 0) {
        return stockPriceData.historical;
      } else {
        console.error(`No historical price data found for ${ticker}`);
        return [];
      }
    } catch (error) {
      console.error(`Error fetching historical price data for ${ticker}:`, error);
      return [];
    }
  }

  async getProfileData(currentTickers) {
    try {
      const profilePromises = currentTickers.map(async (ticker) => {
        const profileEndpoint = `https://financialmodelingprep.com/api/v3/profile/${ticker}?apikey=${FINANCE_API_KEY}`;
        const profileResponse = await fetch(profileEndpoint);
  
        if (profileResponse.ok) {
          const profileData = await profileResponse.json();

          if (Array.isArray(profileData) && profileData.length > 0) {
            const tickerId = profileData[0].symbol;

            this.tickerProfiles = {
              ...this.tickerProfiles,
              [tickerId]: profileData,
            };
          }
        }
      });

      await Promise.all(profilePromises);
    } catch (error) {
      console.error(`Error fetching profile data:`, error);
      throw error;
    }
  }

  async fetchDividendDataForTicker(ticker) {
    try {
      const earliestTickerDate = this.earliestTickerDates[ticker];
  
      if (earliestTickerDate) {
        const historicDividendData = await this.getHistoricDividend(ticker);
        const toDateString = new Date().toISOString().split('T')[0]; // Current date
        const stockPriceHistory = await this.getStockPriceHistory(ticker, slashDateToDashDate(earliestTickerDate), toDateString);
        const stockPriceMap = new Map(stockPriceHistory.map(entry => [entry.date, entry]));
        const dataWithTicker = [];
  
        for (const entry of historicDividendData) {
          const matchingStockEntry = stockPriceMap.get(entry.date);
  
          if (matchingStockEntry) {
            const openPrice = matchingStockEntry.open;
            const newDataEntry = { ...entry, ticker, id: uuidv4(), openPrice };
            dataWithTicker.push(newDataEntry);
          }
        }
  
        return { [ticker]: dataWithTicker };
      } else {
        console.error(`No Buy date found for ${ticker}`);
        return { [ticker]: [] };
      }
    } catch (error) {
      console.error(`Error fetching historic dividend data for ${ticker}:`, error);
      return { [ticker]: [] };
    }
  }
  
  async fetchDividendDataForTickers() {
    const dividendDataPromises = this.currentTickers.map(async (ticker) => {
      return await this.fetchDividendDataForTicker(ticker);
    });

    const dividendDataArray = await Promise.all(dividendDataPromises);
    this.dividendData = dividendDataArray.reduce((accumulator, current) => {
      return { ...accumulator, ...current };
    }, {});

    this.sortedDividends = this.sortAndFilterDividendsByDate();

    this.dispatchEvent(new CustomEvent('sorted-dividend-data', { bubbles: true, composed: true, detail: this.sortedDividends }));
  }

  findEarliestTickerDate() {
    const earliestTickerDates = {};

    this.tableData.slice(1).forEach((row) => {
      const date = row[0];
      const ticker = row[2];

      if (date) {
        const currentEarliestDate = earliestTickerDates[ticker];

        if (!currentEarliestDate || new Date(date) < new Date(currentEarliestDate)) {
          earliestTickerDates[ticker] = date;
        }
      }
    });

    return earliestTickerDates;
  }

  getDividendOpenPrice(row) {
    const date = row.date;
    const ticker = row.ticker;
    const key = `${date}|${ticker}`;
    if (this.reinvestmentsData[key] && this.reinvestmentsData[key].sharePrice) {
      return this.reinvestmentsData[key].sharePrice;
    }

    if (this.dividendData[ticker]) {
      const matchingEntry = this.dividendData[ticker].find(entry => entry.date === date);

      if (!matchingEntry) {
        return;
      }

      this.reinvestmentsData = {
        ...this.reinvestmentsData,
        [key]: {
          ...this.reinvestmentsData[key],
          ['sharePrice']: matchingEntry.openPrice,
        },
      };

      return matchingEntry ? matchingEntry.openPrice : 0;
    }
  
    return null;
  }

  getDividendShares(row) {
    const date = row.date;
    const ticker = row.ticker;
    const key = `${date}|${ticker}`;
    if (this.reinvestmentsData[key] && this.reinvestmentsData[key].numberOfShares) {
      return this.reinvestmentsData[key].numberOfShares;
    }
  }

  handleLinkClick(row) {
    const numberOfSharesLink = this.shadowRoot.querySelector(`#sharesLink-${row.id}`);
    const numberOfSharesInput = this.shadowRoot.querySelector(`#numberOfShares-${row.id}`);

    if (numberOfSharesLink && numberOfSharesInput) {
      numberOfSharesInput.value = numberOfSharesLink.innerText;

      const date = row.date;
      const ticker = row.ticker;
      const key = `${date}|${ticker}`;

      this.reinvestmentsData = {
        ...this.reinvestmentsData,
        [key]: {
          ...this.reinvestmentsData[key],
          ['numberOfShares']: numberOfSharesInput.value,
          ['reinvested']: true,
        },
      };
    }
  }

  selectTab(tabIndex) {
    this.activeTab = tabIndex;
  }

  getCurrentReinvestedSync(tableData) {
    tableData.slice(1).forEach(row => {
      const date = row[0];
      const formattedDate = slashDateToDashDate(date);
      const ticker = row[2];
      const type = row[1];
      const price = row[3];
      const shares = row[4];
  
      const key = `${formattedDate}|${ticker}`;
      if (type === 'Reinvestment') {
        this.reinvestmentsData = {
          ...this.reinvestmentsData,
          [key]: {
            ...this.reinvestmentsData[key],
            ['numberOfShares']: shares,
            ['sharePrice']: price,
            ['reinvested']: true,
          },
        };
      }
    });

    this.requestUpdate();
  }

  sortAndFilterDividendsByDate() {
    const allDividends = Object.values(this.dividendData).flat();
    const currentDate = Date.now();
    const filteredDividends = allDividends.filter((row) => new Date(row.date) <= currentDate);
    return filteredDividends.sort((a, b) => new Date(b.date) - new Date(a.date));
  }

  toggleDropdown(id) {
    this.dividendsState = this.dividendsState || {};
    this.dividendsState[id] = !this.dividendsState[id];

    Object.keys(this.dividendsState).forEach((rowId) => {
      if (rowId !== id) {
        this.dividendsState[rowId] = false;
      }
    });
    this.requestUpdate();
  }

  handleInputChange(row, property, value) {
    const date = row.date;
    const ticker = row.ticker;
    const key = `${date}|${ticker}`;
    this.reinvestmentsData = {
      ...this.reinvestmentsData,
      [key]: {
        ...this.reinvestmentsData[key],
        [property]: value,
      },
    };
  }

  handleReinvestmentSwitchToggled(evt, row) {
    const date = row.date;
    const ticker = row.ticker;
  
    const key = `${date}|${ticker}`;
    this.reinvestmentsData = {
      ...this.reinvestmentsData,
      [key]: {
        ...this.reinvestmentsData[key],
        ['reinvested']: evt.detail,
      },
    };
  }

  async handleSaveClick() {
    if (!this.spreadsheetId) {
      return;
    }
  
    const reinvestmentRowsToAdd = [];
    for (const key in this.reinvestmentsData) {
      if (Object.prototype.hasOwnProperty.call(this.reinvestmentsData, key)) {
        const [date, ticker] = key.split('|');
        const formattedDate = dashDateToSlashDate(date);
        const isReinvested = this.reinvestmentsData[key].reinvested;
        const hasShares = this.reinvestmentsData[key].numberOfShares;
  
        if (isReinvested && hasShares) {
          const { numberOfShares, sharePrice } = this.reinvestmentsData[key];
  
          const {
            currentPrice,
            totalPurchaseAmount,
            totalValueNow,
            totalGainLoss,
            totalGainLossPercent,
            assetClass,
            sector
          } = await calculateNumberFields(0, numberOfShares, sharePrice, this.tickerProfiles[ticker]);
  
          reinvestmentRowsToAdd.push([
            formattedDate, 'Reinvestment', ticker, sharePrice, numberOfShares, 0, parseFloat(currentPrice), totalPurchaseAmount, totalValueNow, totalGainLoss, totalGainLossPercent, assetClass, sector, uuidv4()
          ]);
        }
        // TODO add a toast message here for trades that don't have share inputs added
        // rather than just skipping them
      }
    }

    let newtableData = this.tableData;
    const reinvestmentIndicesToDelete = newtableData.reduce((indices, rowArray, currentIndex) => {
      // eslint-disable-next-line no-unused-vars
      const [rowDataDate, type, rowTicker] = rowArray;
      if (type === 'Reinvestment') {
        indices.push(currentIndex);
      }
      return indices;
    }, []);

    if (reinvestmentIndicesToDelete.length > 0) {
      newtableData = newtableData.filter((_, currentIndex) => !reinvestmentIndicesToDelete.includes(currentIndex));
    }

    if (reinvestmentRowsToAdd.length > 0) {
      newtableData = [...newtableData, ...reinvestmentRowsToAdd];
    }

    await gapi.client.sheets.spreadsheets.values.clear({
      spreadsheetId: this.spreadsheetId,
      range: 'Sheet1!A1:Z',
    });

    await gapi.client.sheets.spreadsheets.values.update({
      spreadsheetId: this.spreadsheetId,
      range: 'Sheet1',
      valueInputOption: 'RAW',
      resource: {
        values: newtableData,
      },
    });

    this.tableData = newtableData;

    this.dispatchEvent(new CustomEvent('saved-reinvested-dividends', { bubbles: true, composed: true, detail: newtableData }));

    const toast = document.createElement('toast-message-element');
    toast.message = 'Reinvestment Changes Saved';
    document.body.appendChild(toast);
  }
  
  calculateReinvestmentTotal(row) {
    const date = row.date;
    const ticker = row.ticker;
    const key = `${date}|${ticker}`;
    const { numberOfShares, sharePrice } = this.reinvestmentsData[key] || { numberOfShares: 0, sharePrice: 0 };
    if (numberOfShares && sharePrice) {
      const total = numberOfShares * sharePrice;
      return total.toFixed(2);
    }
    return '0.00';
  }

  static get styles() {
    return css`
      :host {
        color: #2E2E3A;
        font-family: 'Montserrat', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 12px;
        letter-spacing: -0.12px;
      }

      .check-icon {
        width: 18px;
        height: 18px;
      }

      .edit-icon {
        width: 16px;
        height: 16px;
        flex-shrink: 0;
        cursor: pointer;
      }
  
      .data-table {
        width: 1184px;
        flex-shrink: 0;
        margin: 0;
        border-collapse: collapse;
      }
  
      .title-row {
        border-bottom: 1px solid #EFF1F7;
        background: #F2F3F7;
      }
  
      .title-cell {
        padding: 14px 0 14px 20px;
      }

      .title-cell.small {
        width: 25px;
        padding: 14px 0 14px 8px;
      }
  
      .data-row {
        position: relative;
        border-bottom: 1px solid #EFF1F7;
        background: #FFF;
        box-sizing: border-box;
      }
  
      .data-cell {
        height: 20px;
        padding: 14px 20px;
      }

      .data-cell.small {
        width: 25px;
        padding: 14px 8px;
      }
  
      .last-column {
        position: relative;
      }
  
      .last-column img {
        height: 16px;
        width: 16px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin-left: 10px;
        cursor: pointer;
      }
  
      .last-column img:last-child {
        right: 20px;
      }
  
      .spinner {
        margin: 32px auto auto auto;
      }
  
      .dropdown-container {
        position: absolute;
        width: 1184px;
        overflow: hidden;
        max-height: 0;
        transition: max-height 0.2s ease-in-out;
      }

      .dropdown {
        display: flex;
        flex-direction: column;
        gap: 16px;
        position: relative;
        width: 100%;
        background-color: #f9f9f9;
        z-index: 1;
        padding: 20px;
        box-sizing: border-box;
      }
      
      .show-dropdown {
        max-height: 500px; /* Adjust the value to your preference */
      }
  
      .data-row.clickable:hover {
        background-color: #f5f5f5;
        cursor: pointer;
      }
  
      .data-row:hover .dropdown {
        display: block;
      }

      .dropdown h2 {
        margin: 0px;
      }

      input[type="number"] {
        display: flex;
        width: 200px;
        padding: 5px 5px 5px 5px;
        align-items: center;
        border: 1px solid #EFF1F7;
      }

      .input-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      
      .input-pair {
        display: inline-flex;
        flex-direction: column;
        gap: 8px;
      }

      .label-pair {
        display: inline-flex;
        flex-direction: row;
        gap: 66px;
      }

      .shares-link {
        text-decoration: underline;
        color: blue;
        cursor: pointer;
      }

      .operator-pair {
        display: inline-flex;
        flex-direction: row;
      }

      .operator {
        margin: 6px 10px;
        vertical-align: middle;
      }

      .total-amount {
        margin: 6px 0px;
      }

      button {
        color: #FFF;
        border: none;
        cursor: pointer;
      }

      .save-container {
        display: flex;
        justify-content: flex-end;
        margin-top: 16px;
      }
      
      .save {
        width: 126px;
        height: 36px;
        padding: 10px 20px;
        border-radius: 18px;
        border: none;
        background: #3346E7;
        box-shadow: 0px 6px 8px 0px rgba(51, 70, 231, 0.20);
      }      

      .save:hover {
        background-color: #003580;
      }

      .save:disabled {
        background-color: #3346E7;
        cursor: not-allowed;
        opacity: 0.5;
      }
    `;
  }
  
  render() {
    return html`
      <table class="data-table">
        ${this.filteredSortedDividends && this.filteredSortedDividends.length > 0 ? html`
          <tr class="title-row">
            <td class="title-cell">Dividend Date</td>
            <td class="title-cell">Ticker</td>
            <td class="title-cell">Dividend</td>
            <td class="title-cell">Payout</td>
            <td class="title-cell">Declaration Date</td>
            <td class="title-cell">Record Date</td>
            <td class="title-cell">Payment Date</td>
            <td class="title-cell small">Reinvested</td>
          </tr>
          ${this.filteredSortedDividends.map((row) => html`
            <tr class="data-row clickable" @click=${() => this.toggleDropdown(row.id)}>
              <td class="data-cell">${dashDateToSlashDate(row.date)}</td>
              <td class="data-cell">${row.ticker}</td>
              <td class="data-cell">${row.dividend}</td>
              <td class="data-cell">$${this.calculatePayout(row)}</td>
              <td class="data-cell">${dashDateToSlashDate(row.declarationDate)}</td>
              <td class="data-cell">${dashDateToSlashDate(row.recordDate)}</td>
              <td class="data-cell">${dashDateToSlashDate(row.paymentDate)}</td>
              <td class="data-cell small">
                ${this.reinvestmentsData[`${row.date}|${row.ticker}`] && this.reinvestmentsData[`${row.date}|${row.ticker}`].reinvested ? html`<box-icon class="check-icon" name='check'></box-icon>` : ''}
              </td>
              <td class="data-cell last-column">
                <img src="${edit}" class="edit-icon" alt="Edit" />
              </td>
            </tr>
            <div class="dropdown-container ${this.dividendsState[row.id] ? 'show-dropdown' : ''}">
              <div class="dropdown">
                <h2>Reinvestment Options</h2>
                <toggle-switch-element
                  .on=${this.reinvestmentsData[`${row.date}|${row.ticker}`] && this.reinvestmentsData[`${row.date}|${row.ticker}`].reinvested}
                  .title=${'Reinvested'}
                  @switch-toggled=${(e) => this.handleReinvestmentSwitchToggled(e, row)}>
                </toggle-switch-element>
                <div class="input-row">
                  <div class="input-data">
                    <div class="input-pair">
                      <div class="label-pair">
                        <label for="numberOfShares">Number of Shares</label>
                        <a id="sharesLink-${row.id}" class="shares-link" @click=${() => this.handleLinkClick(row)}>${this.calculateDividendShares(row)}</a>
                      </div>
                      <div class="operator-pair">
                        <input type="number" id="numberOfShares-${row.id}" placeholder="eg: 100" value=${this.getDividendShares(row)} .disabled=${this.reinvestmentsData[`${row.date}|${row.ticker}`] && !this.reinvestmentsData[`${row.date}|${row.ticker}`].reinvested} @input=${(e) => this.handleInputChange(row, 'numberOfShares', e.target.value)} />
                        <span class="operator">X</span>
                      </div>
                    </div>
                    <div class="input-pair">
                      <label for="sharePrice">Share Price</label>
                      <div class="operator-pair">
                        <input type="number" id="sharePrice" value=${this.getDividendOpenPrice(row)} .disabled=${this.reinvestmentsData[`${row.date}|${row.ticker}`] && !this.reinvestmentsData[`${row.date}|${row.ticker}`].reinvested} @input=${(e) => this.handleInputChange(row, 'sharePrice', e.target.value)} />
                        <span class="operator">=</span>
                      </div>
                    </div>
                    <div class="input-pair">
                      <label for="reinvestmentTotal">Reinvestment Total</label>
                      <div class="total-amount">$${this.calculateReinvestmentTotal(row)}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          `)}
        ` : html`<tr class="title-row"><td>No data available</td></tr>`}
      </table>
      <div class="save-container">
        <button class="save" @click="${() => this.handleSaveClick()}" .disabled="${this.saveDisabled}">Save</button>
      </div>
    `;
  }
}

customElements.define('current-positions-dividends-element', CurrentPositionsDividendsElement);