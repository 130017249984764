import { LitElement, html, css } from 'lit';
import './spinner-element';
import './dividend-calendar-element';
import './dividend-calendar-items-element';
import { dashDateToFullDate } from './util';

const GOOGLE_API_KEY = 'AIzaSyAcwLc7fO1RjJxq7a3aFAFUHafO04rdDPc';
const DISCOVERY_DOC = 'https://sheets.googleapis.com/$discovery/rest?version=v4';
const FINANCE_API_KEY = 'f27bbd28829cfd17713d89497a16ae75';

class DividendElement extends LitElement {
  static get properties() {
    return {
      colorsArray: Array,
      currentIndex: Number,
      currentYearDividendData: Object,
      dividends: Array,
      futureDividends: Object,
      loadingDividends: Boolean,
      selectedMonth: Number,
      selectedMonthName: String,
      sheetData: Object,
      showDropdown: Boolean,
      spreadsheetId: String,
    };
  }

  constructor() {
    super();
    const currentDate = new Date();
    const monthName = currentDate.toLocaleString('default', { month: 'long' });
    const monthIndex = currentDate.getMonth();

    this.colorsArray = ['#fa0053', '#00c5ff', '#ff9215'];
    this.currentIndex = 0;
    this.dividends = [];
    this.loadingPieData = true;
    this.loadingBarData = true;
    this.loadingCalendarData = true;
    this.loadingDividends = true;
    this.selectedMonth = monthIndex;
    this.selectedMonthName = monthName;
    this.spreadsheetId = localStorage.getItem('spreadsheet_id');
  }

  willUpdate(changedProperties) {
    if (
      changedProperties.has('currentYearDividendData') ||
      changedProperties.has('futureDividends')
    ) {
      this.updateDividends();
    }

    if (
      changedProperties.has('futureDividends')
    ) {
      this.createPieGraph('Individual');
    }
  }

  async firstUpdated() {
    google.charts.load('current', {'packages':['corechart']});
    this.getSheetData();
  }

  async createBarChart() {
    await this.getDividendDataForYear();
    await this.getFutureDividends();
    google.charts.setOnLoadCallback(this.drawBarChart.bind(this));
  }

  async createPieGraph(labelColumn) {
    // Prepare the data for the pie chart
    const chartData = [['Label', 'Dividend Value']];
    const labelToDividendValue = {}; // Map to accumulate dividend values based on label

    for (const [symbol, dividends] of Object.entries(this.futureDividends)) {
      const dividend = dividends[0]; // Select the first dividend object
      const strippedSheetData = this.sheetData.slice(1);
      const totalShares = strippedSheetData.reduce((sum, row) => {
        if (row[2] === dividend.symbol) {
          return sum + parseFloat(row[4]);
        }
        return sum;
      }, 0);
      const yearlyPayoutMultiplier = this.futureDividends[symbol].length === 4 ? 4 : 12;
      const dividendValue = (dividend.dividend * totalShares) * yearlyPayoutMultiplier;

      // Get the appropriate label based on labelColumn
      const label = labelColumn === 'Individual'
        ? symbol
        : labelColumn === 'Asset'
        ? strippedSheetData.find(row => row[2] === symbol)[11] // Assuming row[11] contains asset labels
        : strippedSheetData.find(row => row[2] === symbol)[12]; // Assuming row[12] contains sector labels

      // Accumulate dividend values for each label
      if (labelToDividendValue[label]) {
        labelToDividendValue[label] += dividendValue;
      } else {
        labelToDividendValue[label] = dividendValue;
      }
    }

    // Convert the labelToDividendValue map to chart data
    for (const [label, dividendValue] of Object.entries(labelToDividendValue)) {
      chartData.push([label, dividendValue]);
    }

    this.loadingPieData = false;
  
    // Create and draw the pie chart
    const data = google.visualization.arrayToDataTable(chartData);
    const options = {
      width: 432,
      height: 386,
      chartArea: {
        left: 0,
        top: 52,
        right: 0,
        bottom: 52,
      },
      pieHole: 0.5,
      colors: [
        '#fa0053',
        '#0062ff',
        '#00c5ff',
        '#ff9215',
        '#9601ff',
        '#14d0C5',
        '#24ca49',
        '#00c5ff',
        '#c0df00',
        '#f84439',
        '#3c49de',
        '#ff5733',
        '#8f00ff',
        '#00a1ab',
        '#ffca18',
        '#ff3c00' 
      ],
      legend: {
        position: 'none',
        textStyle: {
          fontSize: 14,
          color: '#2E2E3A'
        },
      },
      tooltip: {
        ignoreBounds: true,
      }
    };
    const chart = new google.visualization.PieChart(this.shadowRoot.querySelector('#annual_dividend_chart_div'));
    chart.draw(data, options);
  }

  async drawBarChart() {
    const data = new google.visualization.DataTable();
    data.addColumn('string', 'Month');
    data.addColumn('number', 'Dividends');
    data.addColumn({ role: 'style' });
    this.loadingBarData = false;
    const dataTable = await this.getBarChartData(data);

    if (dataTable.length === 0) {
      return;
    }
  
    const options = {
      bar: {groupWidth: "35%"},
      chartArea: {
        left: 50,
        top: 40,
        right: 20,
        bottom: 30,
      },
      legend: { position: 'none' },
      vAxis: {
        minValue: 0,
        textStyle: { color: '#9A9AAF', fontSize: 10 },
        gridlines: {
          color: '#F2F3F7',
        },
      },
      hAxis: {
        textStyle: { color: '#535362', fontSize: 10 },
        gridlines: {
          color: '#F2F3F7',
        },
      },
      isStacked: false,
      animation: {
        startup: true,
        duration: 1000,
        easing: 'out'
      },
    };
    

    const chart = new google.visualization.ColumnChart(this.shadowRoot.querySelector('#monthly_dividend_bar_chart_div'));
    chart.draw(dataTable, options);
  }

  async getBarChartData(dataTable) {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const monthNames = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
  
    const monthsToShow = 12; // Number of months to show in the chart
  
    const startIndex = currentMonth;
    const endIndex = (startIndex + monthsToShow) % 12;
  
    const displayedMonthNames = monthNames.slice(startIndex, startIndex + monthsToShow).concat(monthNames.slice(0, endIndex));
  
    const totalDividends = displayedMonthNames.map((monthName, index) => {
      const month = (startIndex + index + 1) % 12 || 12; // Circular indexing to start from the current month
      let year = currentYear;
      if (month <= currentMonth) {
        year += 1; // Increment year if the month is in the future or in the next year
      }
      const allDividends = [];

      // Merge currentYearDividendData dividends
      for (const dividends of Object.values(this.currentYearDividendData)) {
        allDividends.push(...dividends);
      }
      
      // Merge futureDividends dividends
      for (const dividends of Object.values(this.futureDividends)) {
        allDividends.push(...dividends);
      }

      const monthlyDividends = allDividends.reduce((total, dividend) => {
        const paymentDateYear = dividend.paymentDate.split('-')[0];
        const paymentDateMonth = dividend.paymentDate.split('-')[1];

        if (parseInt(paymentDateYear) === year && parseInt(paymentDateMonth) === month) {
          const strippedSheetData = this.sheetData.slice(1);
          const totalShares = strippedSheetData.reduce((sum, row) => {
            if (row[2] === dividend.symbol) {
              return sum + parseFloat(row[4]); // Assuming the number of shares is in the 4th index of the row
            }
            return sum;
          }, 0);
          const dividendValue = dividend.dividend * totalShares;
          return total + dividendValue;
        }
        return total;
      }, 0);
      return [`${monthName} ${year}`, monthlyDividends];
    });

    // add the label-value pairs to the dataTable with a fill color
    // sort the data
    // then add the random color assortment to the data row
    totalDividends.forEach(row => {
      dataTable.addRow([row[0], row[1], '#FFFFFF']);
    });

    for (var row = 0; row < dataTable.getNumberOfRows(); row++) {
      const randomColor = this.getRandomColor();
      dataTable.setValue(row, 2, randomColor);
    }
  
    return dataTable;
  }

  async getDividendDataForYear() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentYearDividendData = {};
    const tickers = this.sheetData.slice(1).map(row => row[2]);
  
    // Create a Map to track unique payment dates and their dividends
    const uniquePaymentDates = new Map();
  
    // Fetch dividend data for each quarter of the year
    // and last one of last year
    for (let quarter = -1; quarter < 4; quarter++) {
      const fromMonth = quarter * 3;
      const toMonth = fromMonth + 2;
      const fromDate = new Date(currentYear, fromMonth, 1);
      const toDate = new Date(currentYear, toMonth + 1, 0);
      const from = fromDate.toISOString().split('T')[0];
      const to = toDate.toISOString().split('T')[0];
      const dividendCalendar = await this.getDividendCalendar(from, to);
    
      for (const dividend of dividendCalendar) {
        const paymentYear = dividend.paymentDate ? dividend.paymentDate.substring(0, 4) : null;
        const paymentDate = dividend.paymentDate;
  
        // Filter out payment dates that do not fall within the current year
        if (paymentYear === null || paymentYear !== currentYear.toString()) {
          continue;
        }
  
        if (tickers.includes(dividend.symbol)) {
          // Check if payment date is already stored in uniquePaymentDates
          if (!uniquePaymentDates.has(paymentDate)) {
            uniquePaymentDates.set(paymentDate, dividend);
          }
        }
      }
    }
  
    // Convert Map values back to an array of dividends
    const uniqueDividends = Array.from(uniquePaymentDates.values());
  
    // Organize dividends by symbol
    for (const dividend of uniqueDividends) {
      if (!currentYearDividendData[dividend.symbol]) {
        currentYearDividendData[dividend.symbol] = [];
      }
      currentYearDividendData[dividend.symbol].push(dividend);
    }
  
    this.currentYearDividendData = currentYearDividendData;
    this.loadingCalendarData = false;
  }  

  async getFutureDividends() {
    let isQuarterly = false;
    this.futureDividends = {};
  
    for (const symbol in this.currentYearDividendData) {
      const dividends = this.currentYearDividendData[symbol];
      const lastDividend = dividends[dividends.length - 1];
      const currentYear = new Date().getFullYear();
      const futureDividends = [];
  
      if (dividends.length > 1) {
        const lastPaymentDate = dashDateToFullDate(dividends[dividends.length - 2].paymentDate);
        const currentPaymentDate = dashDateToFullDate(lastDividend.paymentDate);
        
        const lastYear = lastPaymentDate.getFullYear();
        const lastMonth = lastPaymentDate.getMonth();
        
        const currentYear = currentPaymentDate.getFullYear();
        const currentMonth = currentPaymentDate.getMonth();
        
        const monthsApart = (currentYear - lastYear) * 12 + currentMonth - lastMonth;
  
        if (monthsApart > 2 || monthsApart === 0) {
          isQuarterly = true; // Dividends are quarterly
        } else {
          isQuarterly = false; // Dividends are monthly if dates are different
        }
      } else if (dividends.length === 1) {
        isQuarterly = true; // Only one dividend object, assume quarterly
      }
  
      if (lastDividend) {
        const lastDividendDate = dashDateToFullDate(lastDividend.paymentDate);
        const futureMonth = lastDividendDate.getMonth();
        
        const futureDividendsCount = isQuarterly ? 4 : 12; // Number of future dividends to add
        const monthIncrement = isQuarterly ? 3 : 1; // Month increment based on quarterly or monthly dividends
  
        for (let i = 0; i < futureDividendsCount; i++) {
          const projectedYear = currentYear + Math.floor((futureMonth + i * monthIncrement) / 12);
          const projectedMonth = (futureMonth + i * monthIncrement) % 12;
          const projectedDate = new Date(projectedYear, projectedMonth, 1);
          const paymentDate = new Date(projectedYear, projectedMonth + monthIncrement, 1);
  
          const projectedDividend = {
            adjDividend: lastDividend.adjDividend,
            date: projectedDate.toISOString().split('T')[0],
            declarationDate: lastDividend.declarationDate,
            dividend: lastDividend.dividend,
            isQuarterly: isQuarterly || i === 0, // Set isQuarterly to true for the first projected dividend
            label: `${projectedDate.toLocaleString('default', { month: 'short' })} ${projectedYear}`,
            paymentDate: paymentDate.toISOString().split('T')[0],
            recordDate: lastDividend.recordDate,
            symbol: lastDividend.symbol
          };
  
          futureDividends.push(projectedDividend);
        }
      }
  
      this.futureDividends[symbol] = futureDividends;
    }
  }

  async getDividendCalendar(from, to) {
    return await fetch(`https://financialmodelingprep.com/api/v3/stock_dividend_calendar?from=${from}&to=${to}&apikey=${FINANCE_API_KEY}`)
      .then(response => response.json());
  }

  // TODO this is duplicate with dashboard-element - needs util function
  getRandomColor() {
    if (this.currentIndex === this.colorsArray.length) {
      this.currentIndex = 0; // Reset index when all colors have been used
    }
    const randomColor = this.colorsArray[this.currentIndex];
    this.currentIndex++;
    return randomColor;
  }

  async getSheetData() {
    if (!this.spreadsheetId) {
      return;
    }

    try {
      await gapi.load('client', () => {
        gapi.client.init({
          apiKey: GOOGLE_API_KEY,
          discoveryDocs: [DISCOVERY_DOC],
        }).then(() => {
          gapi.client.load('sheets', 'v4', async () => {
            try {
              const response = await gapi.client.sheets.spreadsheets.values.get({
                spreadsheetId: this.spreadsheetId,
                range: 'Sheet1!A:M',
              });
            
              this.sheetData = response.result.values;

              if (this.sheetData && this.sheetData.length > 1) {
                this.createBarChart();
              }

              if (!this.sheetData || this.sheetData.length <= 1) {
                this.loadingPieData = false;
                this.loadingBarData = false;
                this.loadingCalendarData = false;
                this.loadingDividends = false;
              }
            } catch (error) {
              console.error(error);
            }
          });
        });
      });
    } catch (error) {
      console.error(error);
    }
  }

  async getStockProfile(ticker) {
    return await fetch(`https://financialmodelingprep.com/api/v3/profile/${ticker}?apikey=${FINANCE_API_KEY}`)
    .then(response => response.json());
  }

  handleDropdownClick() {
    this.showDropdown = !this.showDropdown;
    const chevronIcon = this.shadowRoot.querySelector('.chevron-down-icon');
    chevronIcon.classList.toggle('rotate');
  }

  async handleChangeMonthClick(monthIndex) {
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    this.selectedMonthName = months[monthIndex];
    this.selectedMonth = monthIndex;
    const chevronIcon = this.shadowRoot.querySelector('.chevron-down-icon');
    chevronIcon.classList.toggle('rotate');
    this.showDropdown = false;
    
    await this.updateDividends();
  }

  async handleOptionChanged(evt) {
    let selectedOption = evt.detail;
    await this.createPieGraph(selectedOption);
  }

  async updateDividends() {
    if (!this.currentYearDividendData || !this.futureDividends) {
      return;
    }
  
    // Implementation of the function to filter and update the dividends
    const currentYear = new Date().getFullYear();
    const processedDates = new Set();
  
    // Filter dividends for the current month and year
    // Do some extra work to compare values without timezone (TODO - should we do UTC?)
    const filteredDividends = Object.values(this.currentYearDividendData).flatMap((dividends) =>
      dividends.filter((dividend) => {
        const dateParts = dividend.paymentDate.split('-'); // Assuming paymentDate is in 'YYYY-MM-DD' format
        const dividendYear = parseInt(dateParts[0], 10);
        const dividendMonth = parseInt(dateParts[1], 10);
        
        if (
          dividendMonth === this.selectedMonth + 1 &&
          dividendYear === currentYear &&
          !processedDates.has(dividend.paymentDate)
        ) {
          processedDates.add(dividend.paymentDate);
          return true;
        }
        
        return false;
      })
    );
  
    // Set the isQuarterly property based on the length of future dividends
    for (const dividend of filteredDividends) {
      const symbol = dividend.symbol;
  
      // Fetch stock profile for the symbol
      const stockProfile = await this.getStockProfile(symbol);
      const companyName = stockProfile[0].companyName || symbol;
      dividend.isQuarterly = this.futureDividends[symbol]?.length === 4;
      dividend.companyName = companyName;
    }

    // Sort filteredDividends by date in ascending order
    filteredDividends.sort((a, b) => new Date(a.paymentDate) - new Date(b.paymentDate));

    this.loadingDividends = false;
    this.dividends = [...filteredDividends];
  }

  static get styles() {
    return css`
      .dividend-container {
        display: flex;
        flex-wrap: wrap;
        gap: 28px;
        margin: 48px;
      }
      
      .dividends-row {
        display: flex;
        width: 100%;
        gap: 28px;
        justify-content: center;
      }

      .left-side {
        display: flex;
        gap: 28px;
        flex-direction: column;
      }
      
      .right-side {
        flex: 0 0 400px; /* Width of Card 2 */
        display: flex;
        flex-direction: column;
      }

      .dividend-card-container {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
      
      .dividend-card {
        display: flex;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        overflow: hidden;
        background-color: #fff;
        flex-direction: column;
        justify-content: space-between;
      }

      .card-1 {
        width: 432px;
        height: 439px;
      }

      .card-2 {
        width: 764px;
        height: 439px;
      }

      .card-3 {
        width: 764px;
        height: 389px;
      }

      .dividend-card-header-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 24px;
      }

      .dividend-card-header {
        color: #2E2E3A;
        font-family: 'Montserrat', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }

      .dividend-card-header-dropdown {
        display: inline-flex;
        cursor: pointer;
        padding: 8px 10px;
        align-items: center;
        gap: 8px;
        border-radius: 14px;
        border: 1px solid #E7E7E7;
        background: #FFF;
      }

      .dropdown-toggle-text {
        color: #2E2E3A;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 12px;
        letter-spacing: -0.14px;
      }

      .chevron-down-icon {
        display: flex;
        align-items: center;
        width: 12px;
        height: 12px;
        transition: transform 0.2s ease-in-out;
      }

      .chevron-down-icon.rotate {
        transform: rotate(180deg);
      }

      .dropdown-menu {
        position: absolute;
        width: 110px;
        top: 578px;
        background-color: #ffffff;
        border: 1px solid #cccccc;
        border-radius: 5px;
        padding: 8px 10px;
        z-index: 10;
        pointer-events: auto;
        transition: opacity 0.2s ease-in-out;
        right: 160px;
      }

      .dropdown-item {
        display: block;
        cursor: pointer;
      }

      .dropdown-item:hover {
        background-color: #f0f0f0;
      }

      .dropdown-container {
        display: flex;
        width: 416px;
        padding: 16px 0 0 16px;
        align-items: center;
      }

      .dropdown {
        display: flex;
        width: 124px;
        --dropdown-background-color: #FAFAFC;
        --dropdown-button-border: 1px solid #F2F3F7;
        --dropdown-button-padding: 10px;
      }
      
      .chart {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .chart.hidden {
        display: none;
      }

      .chart-spinner {
        margin: auto;
      }

      .no-data {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        color: #999999;
      }

      .no-data box-icon {
        width: 36px;
        height: 36px;
        fill: #999999;
      }
    `;
  }

  render() {
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];

    return html`
      <div class="dividend-container">
        <div class="dividends-row">
          <div class="dividend-card-container">
            <text class="dividend-card-header">Yearly Dividend Income</text>
            <div class="dividend-card card-1">
              <div class="dropdown-container">
                <dropdown-element class="dropdown" @option-selected=${this.handleOptionChanged} .items=${['Individual', 'Asset', 'Sector']}></dropdown-element>
              </div>
              <div id="annual_dividend_chart_div" class="chart ${this.loadingPieData || (!this.sheetData || this.sheetData.length <= 1) ? 'hidden' : ''}"></div>
              ${this.loadingPieData
                ? html`<spinner-element class="chart-spinner"></spinner-element>`
                : !this.sheetData || this.sheetData.length <= 1
                  ? html`
                    <div class="no-data">
                      <box-icon name='sad'></box-icon>
                      <p>You have not added any positions yet</p>
                    </div>
                  `
                  : ''}
            </div>
          </div>
          <div class="dividend-card-container">
            <text class="dividend-card-header">Yearly Dividend Forecast</text>
            <div class="dividend-card card-2">
              <div class="chart ${this.loadingBarData || (!this.sheetData || this.sheetData.length <= 1) ? 'hidden' : ''}" id="monthly_dividend_bar_chart_div"></div>
              ${this.loadingBarData
                ? html`<spinner-element class="chart-spinner"></spinner-element>`
                : !this.sheetData || this.sheetData.length <= 1
                  ? html`
                    <div class="no-data">
                      <box-icon name='sad'></box-icon>
                      <p>You have not added any positions yet</p>
                    </div>
                  `
                  : ''}
            </div>
          </div>
        </div>
        <div class="dividends-row">
          <div class="dividend-card-container">
            <text class="dividend-card-header">Dividend Calendar</text>
            <div class="dividend-card card-3">
              ${this.loadingCalendarData
                ? html`<spinner-element class="chart-spinner"></spinner-element>`
                : html`
                    <dividend-calendar-element
                      .dividends=${this.dividends}
                      .selectedMonth=${this.selectedMonth}
                      .selectedMonthName=${this.selectedMonthName}
                      .sheetData="${this.sheetData}">
                    </dividend-calendar-element>
                  `
              }
            </div>
          </div>
          <div class="dividend-card-container">
            <div class="dividend-card-header-container">
              <text class="dividend-card-header">Summary for ${this.selectedMonthName}</text>
              <div class="dividend-card-header-dropdown" @click="${this.handleDropdownClick}">
                <div class="dropdown-toggle-text">
                  ${this.selectedMonthName}
                </div>
                <div class="chevron-down-icon">
                  <box-icon name='chevron-down' name="chevron-down"></box-icon>
                </div>
              </div>
              ${this.showDropdown ? html`
                <div class="dropdown-menu">
                  ${months.map((month, index) => html`
                    <div class="dropdown-item" @click="${() => this.handleChangeMonthClick(index)}">
                      ${month}
                    </div>
                  `)}
                </div>
              ` : null}
            </div>
            <dividend-calendar-items-element
              .dividends=${this.dividends}
              .loadingDividends=${this.loadingDividends}
              .selectedMonth=${this.selectedMonth}
              .selectedMonthName=${this.selectedMonthName}
              .sheetData="${this.sheetData}">
            </dividend-calendar-items-element>
          </div>
        </div>
      </div>
    `;
  }
}

customElements.define('dividend-element', DividendElement);