import { LitElement, html, css } from 'lit';
import './spinner-element';
import './summary-cards-element';
import {
  createMasterListObject,
  createStockValuesObject,
  getCombinedSheetData
} from './util';

const GOOGLE_API_KEY = 'AIzaSyAcwLc7fO1RjJxq7a3aFAFUHafO04rdDPc';
const DISCOVERY_DOC = 'https://sheets.googleapis.com/$discovery/rest?version=v4';
const FINANCE_API_KEY = 'f27bbd28829cfd17713d89497a16ae75';

class DashboardElement extends LitElement {
  static get properties() {
    return {
      buySheetData: Object,
      colorsArray: Array ,
      currentIndex: Number,
      loadingBarData: Boolean,
      loadingLineData: Boolean,
      loadingPieData: Boolean,
      sellSheetData: Array,
      sheetData: Array,
      spreadsheetId: String,
    };
  }

  constructor() {
    super();
    this.buySheetData = [];
    this.loadingBarData = true;
    this.loadingLineData = true;
    this.loadingPieData = true;
    this.colorsArray = ['#fa0053', '#0062ff', '#00c5ff', '#ff9215', '#9601ff', '#14d0C5', '#24ca49', '#00c5ff', '#c0df00', '#f84439', '#3c49de'];
    this.currentIndex = 0;
    this.sellSheetData = [];
    this.sheetData = [];
    this.spreadsheetId = localStorage.getItem('spreadsheet_id');
  }

  async firstUpdated() {
    google.charts.load('current', {'packages':['corechart']});
  
    await this.getBuySheetData();
    await this.getSellSheetData();
  }

  createLineChart() {
    google.charts.setOnLoadCallback(this.drawLineChart.bind(this));
  }

  createBarChart() {
    google.charts.setOnLoadCallback(this.drawBarChart.bind(this));
  }

  async drawBarChart() {
    if (!this.spreadsheetId) {
      return;
    }

    await gapi.load('client', () => {
      gapi.client.init({
        apiKey: GOOGLE_API_KEY,
        discoveryDocs: [DISCOVERY_DOC],
      }).then(() => {
        gapi.client.load('sheets', 'v4', () => {
          gapi.client.sheets.spreadsheets.values.get({
            spreadsheetId: this.spreadsheetId,
            range: `Sheet1!C2:I`,
          }).then((response) => {
            if (!response.result.values) {
              this.loadingBarData = false;
              return;
            }
            this.loadingBarData = false;
            var dataTable = new google.visualization.DataTable();
            dataTable.addColumn('string', 'Label');
            dataTable.addColumn('number', 'Value');
            dataTable.addColumn({ role: 'style' });
            dataTable.addColumn({ role: 'annotation' });
            var labelMap = {}; // object to store values for each label
            for (var i = 0; i < response.result.values.length; i++) {
              var row = response.result.values[i];
              var label = row[0];
              var value = parseFloat(row[6]);
              if (label in labelMap) {
                labelMap[label] += value; // sum up values for repeated labels
              } else {
                labelMap[label] = value;
              }
            }

            let total = 0;
            for (let label in labelMap) {
              total += labelMap[label];
            }

            // add the label-value pairs to the dataTable with a fill color
            // sort the data
            // then add the random color assortment to the data row
            for (label in labelMap) {
              const percentage = ((labelMap[label] / total) * 100).toFixed(1).toString();
              dataTable.addRow([label, labelMap[label], '#FFFFFF', percentage]);
            }

            dataTable.sort([{ column: 1 }]);

            for (row = 0; row < dataTable.getNumberOfRows(); row++) {
              const randomColor = this.getRandomColor();
              dataTable.setValue(row, 2, randomColor);
            }

            setTimeout(() => {    
              const options = {
                bar: {groupWidth: "35%"},
                chartArea: {
                  left: 52,
                  top: 40,
                  right: 20,
                  bottom: 32,
                },
                legend: { position: 'none' },
                vAxis: {
                  minValue: 0,
                  textStyle: { color: '#9A9AAF', fontSize: 10 },
                  gridlines: {
                    color: '#F2F3F7',
                  },
                },
                hAxis: {
                  textStyle: { color: '#535362', fontSize: 10 },
                  gridlines: {
                    color: '#F2F3F7',
                  },
                },
                isStacked: false,
                animation: {
                  startup: true,
                  duration: 1000,
                  easing: 'out'
                },
              };
              var chart = new google.visualization.ColumnChart(this.shadowRoot.querySelector('#individual_chart_div'));

              // const changeBorderRadius = () => {
              //   const chartColumns = chart.getContainer().getElementsByTagName('rect');
              //   Array.prototype.forEach.call(chartColumns, (column) => {
              //     const fill = column.getAttribute('fill');
              //     const stroke = column.getAttribute('stroke');
              //     if (this.colorsArray.includes(fill) || fill === 'none' || stroke === '#ffffff') {
              //       console.log('here');
              //       column.setAttribute('rx', 10);
              //       column.setAttribute('ry', 10);
              //     }
              //   });
              // };

              // google.visualization.events.addListener(chart, 'ready', changeBorderRadius);
              // google.visualization.events.addListener(chart, 'select', changeBorderRadius);
              // google.visualization.events.addListener(chart, 'onmouseover', changeBorderRadius);
              // google.visualization.events.addListener(chart, 'onmouseout', changeBorderRadius);

              chart.draw(dataTable, options);
            }, 1);
          }, function(response) {
            console.log('Error: ' + response.result.error.message);
          });
        });
      });
    });
  }

  async drawLineChart() {
    const data = new google.visualization.DataTable();
    data.addColumn('date', 'Date');
    data.addColumn('number', 'Total Value');
  
    // Call the getData function to populate the data table
    const dataTable = await this.getData(data);

    if (dataTable.length === 0) {
      return;
    }
  
    const options = {
      curveType: 'function',
      chartArea: {
        left: 80,
        top: 30,
        right: 30,
        bottom: 50,
      },
      legend: 'none',
      colors: ['#FF8800', '#FFFFFF'],
      areaOpacity: 0.15,
      animation: {
        duration: 1000,
        easing: 'out',
        startup: true,
      },
      hAxis: {
        textStyle: {
          color: '#9A9AAF',
        },
        format: 'MMM dd, yyyy',
        gridlines: {
          color: '#EFF1F7',
        },
        minorGridlines: {
          color: 'transparent',
        },
        maxTextLines: 1,
      },
      vAxis: {
        textStyle: {
          color: '#9A9AAF',
        },
        gridlines: {
          color: '#EFF1F7',
        },
      },
    };

    const chart = new google.visualization.AreaChart(this.shadowRoot.querySelector('#net_equity_chart_div'));
    chart.draw(dataTable, options);
  }
  
  async createPieGraph(divId, range, labelColumn, valueColumn) {
    if (!this.spreadsheetId) {
      return;
    }

    await gapi.load('client', () => {
      gapi.client.init({
        apiKey: GOOGLE_API_KEY,
        discoveryDocs: [DISCOVERY_DOC],
      }).then(() => {
        // TODO: We don't need to call this endpoint every time. Just once, then use a property
        gapi.client.load('sheets', 'v4', () => {
          gapi.client.sheets.spreadsheets.values.get({
            spreadsheetId: this.spreadsheetId,
            range: `Sheet1!${range}`,
          }).then((response) => {
            if (!response.result.values) {
              this.loadingPieData = false;
              return;
            }
            this.loadingPieData = false;
            var dataTable = new google.visualization.DataTable();
            dataTable.addColumn('string', 'Label');
            dataTable.addColumn('number', 'Value');
            var labelMap = {}; // object to store values for each label
            for (var i = 0; i < response.result.values.length; i++) {
              var row = response.result.values[i];
              var label = row[labelColumn];
              var value = parseFloat(row[valueColumn]);
              if (label in labelMap) {
                labelMap[label] += value; // sum up values for repeated labels
              } else {
                labelMap[label] = value;
              }
            }
            // add the label-value pairs to the dataTable
            for (label in labelMap) {
              dataTable.addRow([label, labelMap[label]]);
            }
            setTimeout(() => {    
              const options = {
                chartArea: {
                  left: 0,
                  top: 50,
                  right: 0,
                  bottom: 50,
                  width: '100%',
                  height: '100%',
                },
                pieHole: 0.5,
                colors: this.colorsArray,
                legend: {
                  position: 'none',
                  textStyle: {
                    fontSize: 14,
                    color: '#2E2E3A'
                  },
                },
                tooltip: {
                  ignoreBounds: true,
                }
              };
              var chart = new google.visualization.PieChart(this.shadowRoot.querySelector(divId));
              chart.draw(dataTable, options);
            }, 1);
          }, function(response) {
            console.log('Error: ' + response.result.error.message);
          });
        });
      });
    });
  }

  getRandomColor() {
    if (this.currentIndex === this.colorsArray.length) {
      this.currentIndex = 0; // Reset index when all colors have been used
    }
    const randomColor = this.colorsArray[this.currentIndex];
    this.currentIndex++;
    return randomColor;
  }

  async getBuySheetData() {
    if (!this.spreadsheetId) {
      return;
    }
  
    try {
      await gapi.load('client', async () => {
        try {
          await gapi.client.init({
            apiKey: GOOGLE_API_KEY,
            discoveryDocs: [DISCOVERY_DOC],
          });
        } catch (error) {
          console.error(error);
        }
  
        await gapi.client.load('sheets', 'v4', async () => {
          try {
            const response = await gapi.client.sheets.spreadsheets.values.get({
              spreadsheetId: this.spreadsheetId,
              range: 'Sheet1!A:Z',
            });
  
            this.buySheetData = response.result.values;
            this.sheetData = this.buySheetData ? this.buySheetData.concat(this.sellSheetData) : [];
          } catch (error) {
            console.error(error);
          }
        });
      });
    } catch (error) {
      console.error(error);
    }
  }

  async getSellSheetData() {
    if (!this.spreadsheetId) {
      return;
    }
  
    try {
      await gapi.load('client', async () => {
        try {
          await gapi.client.init({
            apiKey: GOOGLE_API_KEY,
            discoveryDocs: [DISCOVERY_DOC],
          });
        } catch (error) {
          console.error(error);
        }
  
        await gapi.client.load('sheets', 'v4', async () => {
          try {
            const response = await gapi.client.sheets.spreadsheets.values.get({
              spreadsheetId: this.spreadsheetId,
              range: 'Sheet2!A:Z',
            });
  
            this.sellSheetData = response.result.values;
            this.sheetData = this.sellSheetData ? this.sellSheetData.concat(this.buySheetData) : [];
            this.createPieGraph('#class_chart_div', 'I2:L', 3, 0);
            this.loadingLineData = false;
            this.createLineChart();
            this.createBarChart();
          } catch (error) {
            console.error(error);
          }
        });
      });
    } catch (error) {
      console.error(error);
    }
  }

  // Get the historical stock prices for the given ticker and date
  async getStockHistoryRange(ticker, startDate, endDate) {
    const from = startDate.toISOString().split('T')[0];
    const to = endDate.toISOString().split('T')[0];
    return await fetch(`https://financialmodelingprep.com/api/v3/historical-price-full/${ticker}?from=${from}&to=${to}&apikey=${FINANCE_API_KEY}`)
      .then(response => response.json());
  }

  async getData() {
    if (!this.buySheetData || !this.sellSheetData) {
      return [];
    }

    const strippedSheetData = await getCombinedSheetData(this.buySheetData, this.sellSheetData);

    // Find earliest and latest date overall
    const allDates = strippedSheetData.map(row => new Date(row[0]));
    const startDate = new Date(Math.min(...allDates));
    const endDate = new Date();
    const tickers = [...new Set(strippedSheetData.map(row => row[2]))];
    const stockData = await Promise.all(tickers.map(ticker => this.getStockHistoryRange(ticker, startDate, endDate)));

    const positionMasterList = await createMasterListObject(false, this.buySheetData, this.sellSheetData);

    let stockValues = createStockValuesObject(stockData, endDate, startDate);

    // Update the new object with current stock values
    for (const symbol in positionMasterList) {
      for (const UUID in positionMasterList[symbol]) {
        const transaction = positionMasterList[symbol][UUID];
        const transactionDate = new Date(transaction.date);

        for (const stockDate in stockValues) {
          const stockDateObj = new Date(stockDate);

          if (stockDateObj >= transactionDate) {
            const stock = stockValues[stockDate][symbol];
            let updatedTotalShares = stock.shares;

            if (transaction.type === 'Buy' || transaction.type === 'Reinvestment' || transaction.type === 'Split') {
              // Add shares for buy/dividend transaction
              updatedTotalShares += transaction.shares;
            } else if (transaction.type === 'Sell') {
              // Sell shares for sell transaction, ensure updatedTotalShares does not go below 0
              updatedTotalShares = updatedTotalShares - transaction.shares;
            }

            stockValues[stockDate][symbol].shares = updatedTotalShares;
            const total = updatedTotalShares * stock.openPrice;
            stockValues[stockDate][symbol].total = total;
          }
        }
      }
    }

    const data = [];

    // Iterate over stockValues and add each date and total value to the data array
    for (const date in stockValues) {
      const totalValue = Object.values(stockValues[date]).reduce((acc, stock) => acc + stock.total, 0);
      data.push([new Date(date), totalValue]);
    }

    const dataTable = new google.visualization.DataTable();
    dataTable.addColumn('date', 'Date');
    dataTable.addColumn('number', 'Total Value');
    dataTable.addRows(data);

    return dataTable;
  }

  handleOptionChanged(evt) {
    let selectedOption = evt.detail;

    if (selectedOption === 'Asset') {
      this.createPieGraph('#class_chart_div', 'I2:L', 3, 0);
    } else if (selectedOption === 'Sector') {
      this.createPieGraph('#class_chart_div', 'I2:M', 4, 0);
    }
  }

  static get styles() {
    return css`
      .dashboard-container {
        display: flex;
        flex-wrap: wrap;
        margin: 48px;
      }

      .dashboard-row {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
      }

      .dashboard-row:nth-last-child(1) {
        margin-top: 40px;
      }

      .summary-cards-element {
        margin: 0 14px 10px 0;
      }

      .dashboard-card-container {
        margin: 0 0 10px 14px;
      }

      .dashboard-card-3-container {
        margin: 0 14px 10px 0;
      }

      .dashboard-card-4-container {
        margin: 0 0 10px 14px;
      }

      .dashboard-card {
        display: flex;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        overflow: hidden;
        background-color: #fff;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 16px;
      }
      
      .dashboard-card-header {
        color: #2E2E3A;
        font-family: 'Montserrat', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }
      
      .chart {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .chart.hidden {
        display: none;
      }

      .dropdown-container {
        display: flex;
        width: 394px;
        padding: 16px 0 0 16px;
        align-items: center;
      }

      .dropdown {
        display: flex;
        width: 124px;
        --dropdown-background-color: #FAFAFC;
        --dropdown-button-border: 1px solid #F2F3F7;
        --dropdown-button-padding: 10px;
      }

      .card-spinner {
        margin: auto;
      }

      .no-data {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        color: #999999;
      }

      .no-data box-icon {
        width: 36px;
        height: 36px;
        fill: #999999;
      }
      
      .dashboard-card.card-1 {
        width: 854px;
        height: 452px;
      }
      
      .dashboard-card.card-3 {
        width: 746px;
        height: 389px;
      }
      
      .dashboard-card.card-4 {
        width: 410px;
        height: 389px;
      }
    `;
  }

  render() {
    return html`
      <div class="dashboard-container">
        <div class="dashboard-row">
          <summary-cards-element class="summary-cards-element"></summary-cards-element>
          <div class="dashboard-card-container">
            <text class="dashboard-card-header">Net Equity</text>
            <div class="dashboard-card card-1">
              <div class="chart ${this.loadingLineData || (!this.sheetData || this.sheetData.length <= 2) ? 'hidden' : ''}" id="net_equity_chart_div"></div>
              ${this.loadingLineData
                ? html`<spinner-element class="card-spinner"></spinner-element>`
                : !this.sheetData || this.sheetData.length <= 2
                  ? html`
                    <div class="no-data">
                      <box-icon name='sad'></box-icon>
                      <p>You have not added any positions yet</p>
                    </div>
                  `
                  : ''}
            </div>
          </div>
        </div>
        <div class="dashboard-row">
          <div class="dashboard-card-3-container">
            <text class="dashboard-card-header">Individual</text>
            <div class="dashboard-card card-3">
              <div class="chart ${this.loadingBarData || (!this.sheetData || this.sheetData.length <= 2) ? 'hidden' : ''}" id="individual_chart_div"></div>
              ${this.loadingBarData
                ? html`<spinner-element class="card-spinner"></spinner-element>`
                : !this.sheetData || this.sheetData.length <= 2
                  ? html`
                    <div class="no-data">
                      <box-icon name='sad'></box-icon>
                      <p>You have not added any positions yet</p>
                    </div>
                  `
                  : ''}
            </div>
          </div>
          <div class="dashboard-card-4-container">
            <text class="dashboard-card-header">Classification</text>
            <div class="dashboard-card card-4">
              <div class="dropdown-container">
                <dropdown-element class="dropdown" @option-selected=${this.handleOptionChanged} .items=${['Asset', 'Sector']}></dropdown-element>
              </div>
              <div class="chart ${this.loadingPieData || (!this.sheetData || this.sheetData.length <= 2) ? 'hidden' : ''}" id="class_chart_div"></div>
              ${this.loadingPieData
                ? html`<spinner-element class="card-spinner"></spinner-element>`
                : !this.sheetData || this.sheetData.length <= 2
                  ? html`
                    <div class="no-data">
                      <box-icon name='sad'></box-icon>
                      <p>You have not added any positions yet</p>
                    </div>
                  `
                  : ''}
            </div>
          </div>
        </div>
      </div>
    `;
  }
}

customElements.define('dashboard-element', DashboardElement);