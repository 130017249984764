import { LitElement, html, css } from 'lit';
import { addCommasToNumber } from './util';

class TopPerformingCardElement extends LitElement {
  static get properties() {
    return {
      loadingTopData: { type: Boolean },
      spreadsheetId: { type: String },
      sheetData: { type: Object },
      topPerformers: { type: Array },
    };
  }

  constructor() {
    super();
    this.topPerformers = [];
    this.spreadsheetId = localStorage.getItem('spreadsheet_id');
  }

  async connectedCallback() {
    super.connectedCallback();
  }

  willUpdate(changedProperties) {
    if (
      changedProperties.has('sheetData')
    ) {
      this.getTopPerformers(this.sheetData);
    }
  }

  getTopPerformers(sheetData) {
    const strippedSheetData = sheetData.slice(1); // Assuming sheetData is an array of rows
    const buyRows = strippedSheetData.filter(row => row[1] === 'Buy');
  
    // Sort the buyRows based on Total Gain/Loss percent (Column K)
    buyRows.sort((a, b) => {
      const gainLossPercentA = parseFloat(a[10]); // Assuming Total Gain/Loss percent is in column K
      const gainLossPercentB = parseFloat(b[10]);
      return gainLossPercentB - gainLossPercentA; // Sorting in descending order
    });
  
    // Select the top 5 performers
    const topPerformers = buyRows.slice(0, 5).map(row => {
      return {
        Date: row[0], // Assuming Date is in column A
        Ticker: row[2], // Assuming Ticker is in column C
        totalGainLossPercent: parseFloat(row[10]).toFixed(2), // Assuming Total Gain/Loss percent is in column K
        totalGainLoss: addCommasToNumber(parseFloat(row[9]).toFixed(2)) // Assuming Total Gain/Loss is in column J
      };
    });
  
    this.loadingTopData = false;
    this.topPerformers = topPerformers;
  }

  static get styles() {
    return css`
      .list-container {
        display: inline-flex;
        flex-direction: column;
        gap: 1px;
      }

      .loading-card {
        display: flex;
        justify-content: center;
        width: 262px;
        min-height: 40px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        background-color: #fff;
        padding: 16px 20px;
      }

      .top-performing-card {
        width: 262px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        overflow: hidden;
        background-color: #fff;
        flex-direction: row;
        padding: 16px 20px;
      }

      .card-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      .header-container {
        display: inline-flex;
        width: 170px;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
      }

      .content-container {
        display: inline-flex;
        justify-content: space-between;
        align-items: flex-start;
      }

      .content-block {
        display: inline-flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
      }
      
      .content-container:first-child {
        text-align: left;
      }

      .content-header {
        color: #9A9AAF;
        font-family: 'Montserrat', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: 16px;
        letter-spacing: -0.16px;
      }

      .content-value-container {
        display: inline-flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 8px;
      }

      .content-value {
        color: #2E2E3A;
        font-family: 'Montserrat', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: -0.24px;
      }

      .content-value-small {
        color: #F84439;
        font-family: 'Montserrat', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: -0.14px;
      }

      .positive {
        color: #24CA49;
      }
      
      .negative {
        color: #F84439;
      }

      .no-data {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        color: #999999;
      }

      .no-data box-icon {
        width: 24px;
        height: 24px;
        fill: #999999;
      }
    `;
  }
  
  render() {
    return html`
      <div class="list-container">
        ${this.loadingTopData
          ? html`
            <div class="loading-card">
              <spinner-element></spinner-element>
            </div>
          `
          : this.topPerformers && this.topPerformers.length > 0
          ? html`
            ${this.topPerformers.map(performer => {
              const totalGainLossPercent = parseFloat(performer.totalGainLossPercent);

              return html`
                <div class="top-performing-card">
                  <div class="card-container">
                    <div class="header-container">
                      <text class="content-header">${performer.Ticker}</text>
                      <text class="content-value">${performer.Date}</text>
                    </div>
                    <div class="content-container">
                      <div class="content-block">
                        <div class="content-value-container">
                          <div class="content-value">$${performer.totalGainLoss}</div>
                          <div class="content-value-small ${totalGainLossPercent >= 0 ? 'positive' : 'negative'}">${performer.totalGainLossPercent}%</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              `;
            })}
          `
          : html`
            <div class="loading-card">
              <div class="no-data">
                <box-icon name='sad'></box-icon>
                <p>You have not added any positions yet</p>
              </div>
            </div>
          `
        }
      </div>
    `;
  }
}

customElements.define('top-performing-card-element', TopPerformingCardElement);
