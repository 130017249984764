import { LitElement, html, css } from 'lit';
import { Router } from '@vaadin/router';
import './landing_page/landing-page-element.js';
import './dashboard-element.js';
import './performance-element.js';
import './dividend-element.js';
import './risk-element.js';
import './stocks-bought-element.js';
import './stocks-sold-element.js';
import './news-wrapper-element.js';
import './header-bar-element.js';
import './modals/whats-new-modal.js';
import './modals/changelog-modal.js';

import logo from './images/logo.png';

const GOOGLE_CLIENT_ID = '939778736823-lkigeeutv9miqjo0r2q90okbudp47qce.apps.googleusercontent.com';
const SCOPES = 'https://www.googleapis.com/auth/spreadsheets https://www.googleapis.com/auth/drive.metadata.readonly';
const GOOGLE_API_KEY = 'AIzaSyAcwLc7fO1RjJxq7a3aFAFUHafO04rdDPc';
const DISCOVERY_DOC = 'https://sheets.googleapis.com/$discovery/rest?version=v4';

class AppElement extends LitElement {
  static get properties() {
    return {
      collapsedSubNav: { type: Boolean },
      currentPath: { type: String },
      currentVersion: { type: String },
      isSignedIn: { type: Boolean },
      loggedInDetails: { type: Object },
      navItems: Array,
      pageTitle: String,
      releaseDate: String,
      router: Object,
    };
  }

  constructor() {
    super();
    this._boundSignedIn = this.handleSignedIn.bind(this);
    this.collapsedSubNav = false;
    this.currentPath = '/landing';
    this.pageTitle = "Dashboard";
    this.isSignedIn = false;
    this.navItems = [];
  }

  connectedCallback() {
    super.connectedCallback();
    document.addEventListener('signed-in', this._boundSignedIn);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    document.removeEventListener('signed-in', this._boundSignedIn);
  }

  willUpdate(changedProperties) {
    super.willUpdate(changedProperties);

    if (changedProperties.has('currentPath')) {
      const lastTimeAuthorized = localStorage.getItem('last_time_authorized');
      const now = new Date().getTime() / 1000;
      const timeSinceAuthorized = now - lastTimeAuthorized;

      if (timeSinceAuthorized >= 3598 && this.currentPath !== '/landing') {
        this.authorizeGoogleAccess();
      }
    }
  }

  firstUpdated() {
    const router = new Router(this.shadowRoot.querySelector(".view"));
    router.setRoutes([
      {
        path: "/app/dashboard",
        component: "dashboard-element",
        action: () => {
          this.highlightNavItem("/app/dashboard");
          this.pageTitle = "Dashboard";
          this.currentPath = "/app/dashboard";
        },
      },
      {
        path: '/',
        redirect: '/landing',
        action: () => {
          this.currentPath = "/landing";
        },
      },
      // {
      //   path: '(.*)',
      //   redirect: (context, commands) => {
      //     if (context.pathname === '/landing') {
      //       return commands.component('landing-page-element');
      //     } else {
      //       return commands.redirect('/landing');
      //     }
      //   },
      //   action: () => {
      //     this.currentPath = "/landing";
      //   },
      // },
      {
        path: '/landing',
        component: 'landing-page-element',
        action: () => {
          this.currentPath = "/landing";
        },
      },
      {
        path: "/app/analytics",
        children: [
          {
            path: "performance",
            component: "performance-element",
            action: () => {
              // Update page title and current path as needed
              this.highlightNavItem("/app/analytics/performance");
              this.pageTitle = "Performance Analysis";
              this.currentPath = "/app/analytics/performance";
            }
          },
          {
            path: "dividends",
            component: "dividend-element",
            action: () => {
              // Update page title and current path as needed
              this.highlightNavItem("/app/analytics/dividends");
              this.pageTitle = "Dividend Analysis";
              this.currentPath = "/app/analytics/dividends";
            }
          },
          {
            path: "risk",
            component: "risk-element",
            action: () => {
              // Update page title and current path as needed
              this.highlightNavItem("/app/analytics/risk");
              this.pageTitle = "Risk Analysis";
              this.currentPath = "/app/analytics/risk";
            }
          }
        ]
      },
      {
        path: "/app/positions",
        component: "stocks-bought-element",
        action: () => {
          this.highlightNavItem("/app/positions");
          this.pageTitle = "Current Positions";
          this.currentPath = "/app/positions";
        },
      },
      {
        path: "/app/sold",
        component: "stocks-sold-element",
        action: () => {
          this.highlightNavItem("/app/sold");
          this.pageTitle = "Sold Positions";
          this.currentPath = "/app/sold";
        },
      },
      {
        path: "/app/news",
        component: "news-wrapper-element",
        action: () => {
          this.highlightNavItem("/app/news");
          this.pageTitle = "News";
          this.currentPath = "/app/news";
        },
      },
      {
        path: "/app/settings",
        component: "settings-element",
        action: () => {
          this.highlightNavItem("/app/settings");
          this.pageTitle = "Settings";
          this.currentPath = "/app/settings";
        },
      },
    ]);

    let lastTimeAuthorized = localStorage.getItem('last_time_authorized');
    const now = new Date().getTime() / 1000;
    const timeSinceAuthorized = now - lastTimeAuthorized;
    // If it has been more than 3598 seconds since last authorization
    if (timeSinceAuthorized >= 3598 && this.currentPath !== '/landing') {
      this.authorizeGoogleAccess();
    }

    let token = localStorage.getItem('access_token');

    if (token) {
      gapi.load('client', () => {
        gapi.client.init({
          apiKey: GOOGLE_API_KEY,
          discoveryDocs: [DISCOVERY_DOC],
        }).then(() => {
          gapi.client.setToken({
            access_token: token
          });
          this.isSignedIn = true;
        });
      });
    } else {
      // If the user revokes and tries to go to anything else
      Router.go('/landing');
    }

    // This is updated manually
    this.currentVersion = '0.1.0.beta.1';
    this.releaseDate = 'October 26, 2023';

    const storedVersion = localStorage.getItem('appVersion');
    
    // Show the user what's new if a new version has been issued
    if (storedVersion !== this.currentVersion) {
      const whatsNewModal = this.shadowRoot.querySelector('whats-new-modal');
      setTimeout(() => {
        const modalElement = whatsNewModal.shadowRoot.querySelector('modal-element');
        modalElement.setAttribute('show', '');
      }, 1);

      localStorage.setItem('appVersion', this.currentVersion);
    }
  }

  updated(changedProps) {
    if (changedProps.has('currentPath') && this.currentPath !== '/landing') {
      const navSidebar = this.shadowRoot.querySelector('.nav-sidebar');
      if (navSidebar) {
        this.navItems = navSidebar.querySelectorAll('li a');
        this.highlightNavItem(this.currentPath);
      }

      const navExpand = this.shadowRoot.querySelector('.nav-link.expandable');
      const subNav = this.shadowRoot.querySelector('.sub-nav');

      if (!this.collapsedSubNav) {
        subNav.classList.add('visible');
        navExpand.classList.add('expanded');
      }

      // Only add the event listener if it hasn't been added already
      if (!navExpand.clickHandlerAdded) {
        navExpand.addEventListener('click', () => {
          subNav.classList.toggle('visible');
          navExpand.classList.toggle('expanded');
          this.collapsedSubNav = true;
        });
        navExpand.clickHandlerAdded = true;
      }
    }
  }

  authorizeGoogleAccess() {
    const email = this.loggedInDetails ? this.loggedInDetails.email : '';
    const tokenClient = google.accounts.oauth2.initTokenClient({
      client_id: GOOGLE_CLIENT_ID,
      scope: SCOPES,
      prompt: '',
      hint: email,
      callback: async (resp) => {
        if (resp.error !== undefined) {
          throw (resp);
        }
        const lastTimeAuthorized = new Date().getTime() / 1000;
        localStorage.setItem("access_token", resp.access_token);
        localStorage.setItem("last_time_authorized", lastTimeAuthorized);

        const spreadsheetExists = await this.checkSpreadsheetExistsByTitle('My New Spreadsheet');
        if (!spreadsheetExists) {
          await this.createSpreadsheet();
          Router.go('/app/positions');
        } else if (this.currentPath !== '/landing') {
          location.reload();
        } else {
          Router.go('/app/dashboard');
        }
      }
    });
    tokenClient.requestAccessToken({ prompt: '' });
  }

  async checkSpreadsheetExistsByTitle(title) {
    return new Promise((resolve, reject) => {
      gapi.load('client', () => {
        gapi.client.init({
          apiKey: GOOGLE_API_KEY,
          scope: SCOPES,
          discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'],
        }).then(() => {
          gapi.client.load('drive', 'v3', () => {
            gapi.client.drive.files.list({
              q: `mimeType='application/vnd.google-apps.spreadsheet' and trashed=false and name='${title}'`,
              fields: 'files(id, name)',
            }).then((response) => {
              if (response.result.files.length > 0) {
                const spreadsheetId = response.result.files[0].id;
                localStorage.setItem("spreadsheet_id", spreadsheetId);
                resolve(true);
              } else {
                resolve(false);
              }
            }, (error) => {
              console.error('Error finding spreadsheet:', error);
              reject(error);
            });
          });
        });
      });
    });
  }

  async createSpreadsheet() {
    await gapi.load('client');
    await gapi.client.init({
      apiKey: GOOGLE_API_KEY,
      discoveryDocs: [DISCOVERY_DOC],
    });
    await gapi.client.load('sheets', 'v4');

    try {
      const response = await gapi.client.sheets.spreadsheets.create({
        properties: {
          title: 'My New Spreadsheet',
        },
        sheets: [
          { properties: { title: 'Sheet1' } },
          { properties: { title: 'Sheet2' } },
          { properties: { title: 'Sheet3' } },
        ],
      });

      const spreadsheetId = response.result.spreadsheetId;
      localStorage.setItem('spreadsheet_id', spreadsheetId);
    } catch (error) {
      console.error('Error creating spreadsheet:', error);
    }
  }

  handleSignedIn(evt) {
    this.loggedInDetails = evt.detail.loggedInDetails;
    this.isSignedIn = evt.detail.isSignedIn;

    this.authorizeGoogleAccess();
  }

  highlightNavItem(path) {
    this.navItems.forEach((item) => {
      if (item.getAttribute("href") === path) {
        item.classList.add("active");
      } else {
        item.classList.remove("active");
      }
    });
  }

  openChangelogModal() {
    const changelogModal = this.shadowRoot.querySelector('changelog-modal');
    const modalElement = changelogModal.shadowRoot.querySelector('modal-element');
    modalElement.setAttribute('show', '');
  }

  /**
    *  Sign out the user upon button click.
    */
  signoutGoogle() {
    const token = gapi.client.getToken();
    if (token !== null) {
      google.accounts.oauth2.revoke(token.access_token);
      localStorage.removeItem('access_token');
      gapi.client.setToken('');
      this.isSignedIn = false;
    }
    Router.go('/landing');
  }

  static get styles() {
    return css`
      .app-container {
        display: flex;
        flex-direction: row;
        min-height: 100vh;
      }
      
      .nav-sidebar {
        display: inline-flex;
        position: fixed;
        flex-direction: column;
        align-items: center;
        width: 16.3%;
        height: 100%;
        padding-bottom: 0px;
        background-color: #FFF;
        gap: 32px;
        flex-shrink: 0;
      }

      .header-bar {
        position: fixed;
        flex-shrink: 0;
        height: 8.4%;
        left: 16.3%;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: #FFFFFF;
        box-sizing: border-box;
      }
      
      .logo {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 8.45%;
        border-bottom: 1px solid #E7E7E7;
        border-right: 1px solid #E7E7E7;
      }
      
      .logo img {
        width: 280px;
      }
      
      .nav-sidebar ul {
        list-style-type: none;
        width: 82%;
        padding: 0px 24px;
        flex-direction: column;
        align-items: flex-start;
        gap: 32px;
      }
      
      .main {
        padding: 70px 20px 20px 20px;
        height: 100%;
      }

      .view {
        position: absolute;
        background-color: #F2F3F7;
        left: 16.3%;
        top: 8.4%;
        right: 0;
        min-height: 100vh;
        border-left: 1px solid #E7E7E7;
        border-top: 1px solid #E7E7E7;
      }

      .nav-item {
        display: inline-block;
        width: 100%;
      }
      
      .nav-link {
        display: flex;
        color: #535362;
        fill: #535362;
        padding: 12px 20px;
        align-items: center;
        cursor: pointer;
        text-decoration: none;
      }
      
      .nav-icon {
        display: inline-block;
        margin-right: 10px;
        width: 20px;
        height: 20px;
      }

      .nav-text {
        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: -0.16px;
      }
      
      .nav-link:hover {
        background-color: #f6f7f9;
        padding-right: 20px;
      }
      
      .nav-link.active {
        color: #FFFFFF;
        fill: #FFFFFF;
        background-color: #3346E7;
        box-shadow: 0px 20px 20px 0px rgba(51, 70, 231, 0.15);
      }

      .nav-link.expanded .nav-expand {
        width: 16px;
        height: 16px;
        fill: #535362;
        transform: rotate(180deg);
      }

      .nav-expand {
        position: absolute;
        right: 10%;
        width: 16px;
        height: 16px;
        margin-right: 8px;
        fill: #535362;
        vertical-align: middle;
        horizontal-align: middle;
        transition: transform 0.2s ease-in-out;
      }

      .sub-nav {
        display: none;
        min-width: 195px;
        padding: 10px;
      }
      
      .sub-nav-link {
        display: flex;
        color: #535362;
        fill: #535362;
        padding: 12px 26px;
        align-items: flex-start;
        cursor: pointer;
        text-decoration: none;
      }

      .sub-nav-link:hover {
        background-color: #f6f7f9;
        padding-right: 20px;
      }

      .sub-nav-link.active {
        color: #FFFFFF;
        fill: #FFFFFF;
        background-color: #3346E7;
        box-shadow: 0px 20px 20px 0px rgba(51, 70, 231, 0.15);
      }
      
      .sub-nav-icon {
        display: inline-block;
        margin-right: 10px;
        width: 24px;
        height: 24px;
      }
      
      .sub-nav-text {
        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: -0.16px;
      }
      
      .sub-nav.visible {
        display: block;
      }
    `;
  }

  render() {
    return html`
      <changelog-modal></changelog-modal>
      <whats-new-modal .currentVersion="${this.currentVersion}" .releaseDate=${this.releaseDate}></whats-new-modal>
      <div class="app-container">
        ${this.currentPath !== '/landing' ? html`
          <div class="nav-sidebar">
            <div class="logo">
              <img src="${logo}" alt="Logo" />
            </div>
            <ul>
              <li class="nav-item">
                <a class="nav-link" href="/app/dashboard">
                  <box-icon class="nav-icon" name='dashboard' type='solid'></box-icon>
                  <span class="nav-text">Dashboard</span>
                </a>
              </li>
              <li class="nav-item">
                <div class="nav-link expandable">
                  <box-icon class="nav-icon" name='analyse' type='solid'></box-icon>
                  <span class="nav-text">Analytics</span>
                  <box-icon class="nav-expand" name='chevron-down' type='solid'></box-icon>
                </div>
                <ul class="sub-nav">
                  <li class="sub-nav-item">
                    <a class="sub-nav-link" href="/app/analytics/performance">
                      <span class="sub-nav-text">Performance</span>
                    </a>
                  </li>
                  <li class="sub-nav-item">
                    <a class="sub-nav-link" href="/app/analytics/dividends">
                      <span class="sub-nav-text">Dividends</span>
                    </a>
                  </li>
                </ul>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/app/positions">
                  <box-icon class="nav-icon" name='chart' type='solid'></box-icon>
                  <span class="nav-text">Current Positions</span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/app/sold">
                  <box-icon class="nav-icon" name='check-circle' type='solid'></box-icon>
                  <span class="nav-text">Sold Positions</span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/app/news">
                  <box-icon class="nav-icon" name='news' type='solid'></box-icon>
                  <span class="nav-text">News</span>
                </a>
              </li>
              <li class="nav-item" @click="${() => this.openChangelogModal()}">
                <a class="nav-link">
                  <box-icon class="nav-icon" name='notepad' type='solid'></box-icon>
                  <span class="nav-text">Changelog</span>
                </a>
              </li>
            </ul>
          </div>
        `: null}
        <div class="main">
          ${this.currentPath !== '/landing' ? html`
            <header-bar-element class="header-bar" .loggedInDetails="${this.loggedInDetails}" .pageTitle="${this.pageTitle}" @signout="${this.signoutGoogle}"></header-bar-element>
          `: null}
          <div class="view"></div>
        </div>
      </div>
    `;
  }
}

customElements.define('app-element', AppElement);