import { v4 as uuidv4 } from 'uuid';

export function addCommasToNumber(value) {
  // Check if the input is a string containing an integer or a decimal with up to 2 decimal places
  if (!/^-?\d+(\.\d{1,2})?$/.test(value) && typeof value !== 'number') {
    return value;
  }

  const stringValue = parseFloat(value).toFixed(2).toString();
  const [integerPart, decimalPart] = stringValue.split('.');
  const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  const formattedNumber = decimalPart ? `${formattedInteger}.${decimalPart}` : formattedInteger;

  return formattedNumber;
}

export async function addNewRows(sheetId, spreadsheetId, totalRowCount) {
  try {
    const rowsToAdd = 1000;

    const request = {
      spreadsheetId: spreadsheetId,
      resource: {
        requests: [
          {
            insertDimension: {
              range: {
                sheetId: sheetId,
                dimension: 'ROWS',
                startIndex: totalRowCount - 1,
                endIndex: totalRowCount + rowsToAdd,
              },
            },
          },
        ],
      },
    };

    await gapi.client.sheets.spreadsheets.batchUpdate(request);
  } catch (error) {
    console.error('Error:', error);
  }
}

export async function calculateNumberFields(fee, numberOfShares, price, profile) {
  let assetClass;
  let sector;
  const isCrypto = profile[0].exchange === 'CRYPTO';
  if (isCrypto) {
    assetClass = 'Crypto';
  } else if (profile[0].isEtf || profile[0].isFund) {
    assetClass = 'ETF / Mutual Fund';
  } else {
    assetClass = profile[0].sector === 'Real Estate' ? 'REIT' : 'Individual';
  }

  const currentPrice = profile[0].price;
  sector = isCrypto ? 'Crypto' : profile[0].sector;
  const totalPurchaseAmount = (parseFloat(price) * parseFloat(numberOfShares)) + parseFloat(fee);
  const totalValueNow = parseFloat(currentPrice) * parseFloat(numberOfShares);
  const totalGainLoss = totalValueNow - totalPurchaseAmount;
  const totalGainLossPercent = (totalGainLoss / totalPurchaseAmount) * 100;

  if (sector === '') {
    sector = 'Other';
  }

  return {
    assetClass,
    currentPrice,
    sector,
    totalPurchaseAmount,
    totalValueNow,
    totalGainLoss,
    totalGainLossPercent
  };
}

// Format YYYY-MM-DD and return a full date
export function dashDateToFullDate(dateString) {
  const [year, month, day] = dateString.split('-').map(Number);
  return new Date(year, month - 1, day);
}

// Format YYYY-MM-DD and return MM/DD/YYYY
export function dashDateToSlashDate(dateString) {
  const date = new Date(dateString);
  const utcMonth = String(date.getUTCMonth() + 1).padStart(2, '0');
  const utcDay = String(date.getUTCDate()).padStart(2, '0');
  const utcYear = date.getUTCFullYear();
  return `${utcMonth}/${utcDay}/${utcYear}`;
}

// Format MM/DD/YYYY and return YYYY-MM-DD
export function slashDateToDashDate(dateString) {
  const [month, day, year] = dateString.split('/');
  const isoDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
  return isoDate;
}

export async function getCombinedSheetData(buySheetData, sellSheetData) {
  const strippedBuySheetData = buySheetData.length !== 0 ? buySheetData.slice(1) : [];

  // Extract specific columns for buy sheet data
  const strippedBuyData = strippedBuySheetData.map(row => [
    row[0], // Date
    row[1], // Type
    row[2], // Ticker
    parseFloat(row[4]), // Shares
    parseFloat(row[7]), // TotalAmount
    row[13] // UUID
  ]);

  // Extract specific columns for sell sheet data
  const strippedSellData = sellSheetData.slice(1).flatMap(row => [
    [
      row[0], // Purchase Date for old buys
      row[14], // Type for old buys/dividends/splits
      row[2], // Ticker
      parseFloat(row[6]), // Shares for old buys
      parseFloat(row[4]), // TotalAmount for old buys
      uuidv4() // New UUID for old buys
    ],
    [
      row[1], // Sell Date for sells
      "Sell", // Type for sells
      row[2], // Ticker
      parseFloat(row[6]), // Shares for sells
      parseFloat(row[7]), // TotalAmount for sells
      row[13], // UUID
      row[14] // Old type
    ]
  ]);

  // Combine and sort by date
  return [...strippedBuyData, ...strippedSellData].sort((a, b) => new Date(a[0]) - new Date(b[0]));
}

export async function getFirstEmptyRowIndex(sheetName, spreadsheetId) {
  try {
    const response = await gapi.client.sheets.spreadsheets.values.get({
      spreadsheetId: spreadsheetId,
      range: sheetName
    });
  
    const values = response.result.values;
  
    if (values && values.length > 0) {
      // Find the index of the last non-empty row
      const lastRowIndex = values.findIndex(row => !row.join(''));
  
      // If all rows are non-empty, lastRowIndex will be -1
      // In this case, you can set sellRowIndex to values.length + 1
      return lastRowIndex === -1 ? values.length + 1 : lastRowIndex + 1;
    }
  } catch (error) {
    console.error(error);
  }
}

export async function createMasterListObject(allowSplits, buySheetData, sellSheetData) {
  const strippedSheetData = await getCombinedSheetData(buySheetData, sellSheetData);
  const positionMasterList = {};

  for (let i = strippedSheetData.length - 1; i >= 0; i--) {
    const row = strippedSheetData[i];
    const date = row[0];
    const ticker = row[2];
    const type = row[1];
    const shares = parseFloat(row[3]);
    const totalAmount = parseFloat(row[4]);
    const UUID = row[5];
    const oldType = row[6] ? row[6] : 'Sell';

    if ((type === "Split" || oldType === 'Split') && !allowSplits) {
      continue;
    }

    if (ticker in positionMasterList) {
      if (UUID in positionMasterList[ticker]) {
        // If the UUID key already exists, add shares and totalAmount together
        positionMasterList[ticker][UUID].shares += shares;
        positionMasterList[ticker][UUID].totalAmount += totalAmount;
      } else {
        positionMasterList[ticker][UUID] = {
          date,
          shares,
          totalAmount,
          type,
        };
      }
    } else {
      positionMasterList[ticker] = {
        [UUID]: {
          date,
          shares,
          totalAmount,
          type,
        },
      };
    }

    if (type === "Sell") {
      // Accumulate shares sold on "Sell" type dates
      const sellShares = 0;
      const sellPurchaseDate = row[0]; // Purchase Date for old buys
      const sellTicker = row[2]; // Ticker
      const sellTotalAmount = parseFloat(row[4]); // TotalAmount for sells
    
      // Create "Buy" entry for old buys
      const buyEntryForOldBuy = [
        sellPurchaseDate, // Purchase Date for old buys
        row[1], // Preserve the original type for old buys
        sellTicker, // Ticker
        sellShares, // Shares for old buys
        sellTotalAmount, // TotalAmount for old buys
        uuidv4(), // New UUID for old buys
      ];

      // Flag to check if a "Buy" entry is found for the corresponding "Sell" date
      let buyEntryFound = false;

      // Update buy type entries with accumulated shares sold before the corresponding "Sell" date
      for (let j = i - 1; j >= 0; j--) {
        const buyRow = strippedSheetData[j];
        const buyDate = buyRow[0];
        const buyTicker = buyRow[2];
        const buyUUID = buyRow[5];

        if (buyRow[1] === "Buy" && buyTicker === ticker) {
          buyEntryFound = true;

          if (!(buyUUID in positionMasterList[ticker])) {
            positionMasterList[ticker][buyUUID] = {
              date: buyDate,
              shares: 0,
              totalAmount: 0,
              type: 'Buy',
            };
          }

          // Update buy type entry with accumulated shares sold
          positionMasterList[ticker][buyUUID].shares += sellShares;
          break; // Exit the loop once a "Buy" entry is found
        }
      }

      // If no "Buy" entry is found, add one for old buys
      if (!buyEntryFound) {
        strippedSheetData.push(buyEntryForOldBuy);
      }
    }
  }

  return positionMasterList;
}

export function sortPositionMasterList(positionMasterList) {
  // Flatten the positionMasterList into an array of entries
  const entries = Object.entries(positionMasterList);

  // Combine all nested objects into a single array of objects with UUIDs included
  const combinedObjects = entries.reduce((acc, [ticker, tickerData]) => {
    const tickerObjects = Object.entries(tickerData).map(([uuid, data]) => ({
      uuid,
      ticker,
      ...data,
    }));
    return [...acc, ...tickerObjects];
  }, []);

  // Sort the combined objects based on the date property
  combinedObjects.sort((a, b) => {
    const dateA = a.date.split('/').reverse().join('-'); // Convert to YYYY-MM-DD
    const dateB = b.date.split('/').reverse().join('-');
    return new Date(dateA) - new Date(dateB);
  });

  return combinedObjects;
}

export function createStockValuesObject(stockData, endDate, startDate) {
  let stockValues = {};

  if (!stockData.length) {
    stockData = Object.values(stockData);
  }

  stockData.forEach(({ symbol, historical, investmentType, sector }) => {
    let lastOpenPrice = null;

    for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {
      const isoDate = date.toISOString().split('T')[0];
      if (!stockValues[isoDate]) {
        stockValues[isoDate] = {};
      }

      // If open price is available for this date, update lastOpenPrice
      const openPriceEntry = historical.find(entry => entry.date === isoDate);
      if (openPriceEntry) {
        lastOpenPrice = openPriceEntry.open;
      } else if (lastOpenPrice === null) {
        // Find the nearest historical date with a valid open price (before or after)
        for (let i = 1; i <= historical.length; i++) {
          const previousDate = new Date(date);
          previousDate.setDate(previousDate.getDate() - i);
          const previousIsoDate = previousDate.toISOString().split('T')[0];
          const previousOpenPriceEntry = historical.find(entry => entry.date === previousIsoDate);
          if (previousOpenPriceEntry) {
            lastOpenPrice = previousOpenPriceEntry.open;
            break;
          }

          const nextDate = new Date(date);
          nextDate.setDate(nextDate.getDate() + i);
          const nextIsoDate = nextDate.toISOString().split('T')[0];
          const nextOpenPriceEntry = historical.find(entry => entry.date === nextIsoDate);
          if (nextOpenPriceEntry) {
            lastOpenPrice = nextOpenPriceEntry.open;
            break;
          }
        }
      }

      stockValues[isoDate][symbol] = { shares: 0, investmentType, sector, openPrice: lastOpenPrice, total: 0 };
    }
  });

  return stockValues;
}

export async function deleteRowsFromSpreadsheet(sheetId, tableData, spreadsheetId) {
  const deleteRequest = {
    deleteDimension: {
      range: {
        sheetId: sheetId,
        dimension: 'ROWS',
        startIndex: 0,
        endIndex: tableData.length + 1,
      },
    },
  };

  await gapi.client.sheets.spreadsheets.batchUpdate({
    spreadsheetId: spreadsheetId,
    resource: {
      requests: [deleteRequest],
    },
  });
}