import { LitElement, html, css } from 'lit';
import './spinner-element';
import './news-article-element';

class SpecificNewsElement extends LitElement {
  static get properties() {
    return {
      articlesPerPage: Number,
      canKeepScrolling: Boolean,
      isScrolling: Boolean,
      loadingMore: Boolean,
      news: Array,
      spreadsheetId: String,
      title: String,
      selectedPageNumber: Number,
    };
  }

  constructor() {
    super();
    this.articlesPerPage = 15;
    this.canKeepScrolling = true;
    this.isScrolling = false;
    this.selectedPageNumber = 1;
    this._boundHandleScroll = this.handleScroll.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();

    document.addEventListener('scroll', this._boundHandleScroll);
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    document.removeEventListener('scroll', this._boundHandleScroll);
  }

  handleScroll() {
    if (!this.isScrolling && window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 100 && this.canKeepScrolling) {
      this.isScrolling = true;
      this.selectedPageNumber = this.selectedPageNumber + 1;
      this.dispatchPageChangedEvent(this.selectedPageNumber);
      setTimeout(() => {
        this.isScrolling = false;
      }, 100);
    }
  }

  dispatchPageChangedEvent(pageNumber) {
    this.dispatchEvent(new CustomEvent('page-changed', {
      detail: { page: pageNumber },
      composed: true,
      bubbles: true,
    }));
  }

  static get styles() {
    return css`
      .page-container {
        display: inline-flex;
        flex-direction: column;
        width: 1260px;
        gap: 32px;
      }

      .header-container {
        display: flex;
        align-items: center;
        gap: 16px;
      }

      .header-text {
        color: #2E2E3A;
        font-family: 'Montserrat', sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: -0.24px;
      }

      .article-row {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 48px;
      }

      .pagination {
        display: flex;
        justify-content: center;
        margin-top: 16px;
        font-size: 16px;
      }

      .page-link {
        cursor: pointer;
        margin: 0 8px;
        color: blue;
        text-decoration: underline;
      }

      .loading-spinner {
        text-align: center;
        margin: 16px;
      }
    `;
  }

  render() {
    return html`
      <div class="page-container">
        <div class="header-container">
          <text class="header-text">${this.title}</text>
        </div>
        ${this.news.length === 0
          ? html`<p>There are no news articles to show</p>`
          : html`
            <div class="article-row">
              ${this.news.map(article => html`
                <news-article-element class="article-element" .article=${article}></news-article-element>
              `)}
            </div>
          `}
        ${this.loadingMore ? html`
          <div class="loading-spinner" id="loadingSpinner">
            <spinner-element></spinner-element>
          </div>
        ` : ''}
      </div>
    `;
  }
}

customElements.define('specific-news-element', SpecificNewsElement);
