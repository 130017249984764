import { LitElement, html, css } from 'lit';
import './toggle-switch-element';
import Fuse from 'fuse.js';

const FINANCE_API_KEY = 'f27bbd28829cfd17713d89497a16ae75';

class NewsFilterElement extends LitElement {
  static get properties() {
    return {
      activeTab: Boolean,
      allCryptoNews: Array,
      allStockNews: Array,
      disableSymbolDropdown: Boolean,
      filteredCryptoNews: Array,
      filteredStockNews: Array,
      isCurrentPositions: Boolean,
      isSymbolCardVisible: Boolean,
      resetDropdownSelection: Boolean,
      searchText: String,
      selectedSymbol: String,
      sheetData: Array,
      sites: Array,
      symbols: Array,
    };
  }

  constructor() {
    super();
    this.allCryptoNews = [];
    this.allStockNews = [];
    this.canKeepScrolling = true;
    this.currentPositions = false;
    this.filteredCryptoNews = [];
    this.filteredStockNews = [];
    this.filteredNews = [];
    this.isSymbolCardVisible = false;
    this.searchText = '';
    this.sheetData = [];
    this.symbols = [];
  }

  connectedCallback() {
    super.connectedCallback();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }

  willUpdate(changedProperties) {
    if (changedProperties.has('filteredStockNews') ||
        changedProperties.has('filteredCryptoNews') ||
        changedProperties.has('activeTab')) {
      if (this.activeTab === 1) {
        this.symbols = ['None', ...new Set(this.allStockNews.map(article => article.symbol))];
      } else if (this.activeTab === 2) {
        this.symbols = ['None', ...new Set(this.allCryptoNews.map(article => article.symbol))]
          .filter(symbol => symbol !== '');
      }
    }

    if (changedProperties.has('activeTab')) {
      this.searchText = '';
      this.selectedSymbol = null;
      this.isCurrentPositions = false;
      this.disableSymbolDropdown = false;
      this.performFiltering();
    }
  }

  async getSpecificCryptoNews(cryptos, page) {
    // Endpoint takes the symbol with no hyphen
    const cryptosFormatted = cryptos.replace("-", "");
    return await fetch(`https://financialmodelingprep.com/api/v4/crypto_news?symbol=${cryptosFormatted}&page=${page}&apikey=${FINANCE_API_KEY}`)
      .then(response => response.json());
  }

  async getSpecificStockNews(tickers, page) {
    return await fetch(`https://financialmodelingprep.com/api/v3/stock_news?tickers=${tickers}&page=${page}&apikey=${FINANCE_API_KEY}`)
      .then(response => response.json());
  }

  handleSearchInput(event) {
    this.searchText = event.target.value;
    this.performFiltering();
  }

  async handleSwitchToggled({detail}) {
    this.resetDropdownSelection = detail;
    this.selectedSymbol = null;
    this.disableSymbolDropdown = detail;
    this.isCurrentPositions = detail;
    this.performFiltering();
  }

  handleSymbolSelected(evt) {
    if (evt.detail === 'None') {
      this.selectedSymbol = null;
    } else {
      this.selectedSymbol = evt.detail;
    }

    this.performFiltering();
  }

  async performFiltering() {
    if (!this.sheetData || this.sheetData <= 1) {
      return;
    }

    const allNewsChoice = this.activeTab === 1 ? this.allStockNews : this.allCryptoNews;
    const newsChoiceLimit = this.activeTab === 1 ? 60 : 20;
    if (!this.selectedSymbol && !this.isCurrentPositions && this.searchText === '') {
      // If all filters are empty
      this.filteredNews = allNewsChoice.slice(0, newsChoiceLimit);
      this.canKeepScrolling = true;
    } else {
      let filteredNews = allNewsChoice;

      if (this.selectedSymbol) {
        filteredNews = this.activeTab === 1 ? await this.getSpecificStockNews(this.selectedSymbol, 1) : await this.getSpecificCryptoNews(this.selectedSymbol, 1);
      }

      if (this.isCurrentPositions && this.sheetData) {
        const uniqueTickers = [...new Set(this.sheetData.map(row => row[2]))];
        const tickersFormatted = uniqueTickers.filter(ticker => ticker).join(',');

        filteredNews = this.activeTab === 1 ? await this.getSpecificStockNews(tickersFormatted, 1) : await this.getSpecificCryptoNews(tickersFormatted, 1);
        const seenTitles = {};
  
        // Filter the array to remove duplicates based on the 'title' key
        filteredNews = filteredNews.filter(article => {
          if (!seenTitles[article.title]) {
            seenTitles[article.title] = true;
            return true;
          }
          return false;
        });
      }

      if (this.searchText) {
        // Apply the search to 'title', 'text', and 'site'
        const options = {
          keys: [
            { name: 'title', weight: 3 },
            { name: 'text', weight: 2 },
            'site',
          ],
        };
        const fuse = new Fuse(filteredNews, options);
        filteredNews = fuse.search(this.searchText).map(result => result.item);
      }

      this.canKeepScrolling = false;
      this.filteredNews = [...filteredNews];
    }

    this.dispatchEvent(new CustomEvent('filtered-articles', {
      bubbles: true,
      composed: true,
      detail: {
        canKeepScrolling: this.canKeepScrolling,
        filteredNews: this.filteredNews
      }
    }));
  }

  static get styles() {
    return css`
      .filter-container {
        display: flex;
        align-items: center;
        gap: 20px;
        width: 1260px;
        height: 36px;
        flex-shrink: 0;
        padding: 20px 32px;
        border-bottom: 1px solid #F2F3F7;
        background: #FFF;
      }

      .filter-search {
        display: inline-flex;
        width: 247px;
        height: 12px;
        padding: 10px 10px 10px 10px;
        align-items: center;
        border: 1px solid #EFF1F7;
        background: #FAFAFC;
      }

      .filter-button {
        display: inline-flex;
        height: 32px;
        padding: 10px;
        align-items: center;
        gap: 8px;
        border: 1px solid #EFF1F7;
        background: #FAFAFC;
        fill: #2E2E3A;
        cursor: pointer;
        color: #2E2E3A;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 12px;
        letter-spacing: -0.14px;
      }

      .filter-icon {
        width: 12px;
        height: 12px;
        flex-shrink: 0;
      }

      .filter-card {
        display: none;
      }
      
      .filter-card.visible {
        display: inline-flex;
        position: absolute;
        top: 165px;
        left: 396px;
        width: 500px;
        padding: 16px 12px 12px 12px;
        justify-content: center;
        gap: 12px;
        border-radius: 0px 0px 8px 8px;
        background: #FFF;
        box-shadow: 0px 25px 50px 0px rgba(0, 0, 0, 0.15);
        z-index: 999;
      }
      
      .filter-card .grid-container {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        gap: 12px;
        max-height: 400px;
      }
      
      .filter-card label {
        display: flex;
        align-items: center;
        cursor: pointer;
      }
      
      .filter-card button {
        display: flex;
        width: 60%;
        margin-left: auto;
        padding: 8px 12px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        background: #3346E7;
        border: none;
        color: #FFF;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 12px;
        letter-spacing: -0.14px;
        cursor: pointer;
        z-index: 999;
      }

      .dropdown {
        --dropdown-background-color: #FAFAFC;
        --dropdown-button-border: 1px solid #F2F3F7;
        --dropdown-button-radius: 0px;
        --dropdown-button-padding: 10px;
        --icon-width: 16px;
        --icon-height: 16px;
        --dropdown-list-width: 200px;
        --dropdown-list-border-top: 1px solid #ccc;
      }
    `;
  }

  render() {
    return html`
      <div class="filter-container" style=${this.activeTab === 1 || this.activeTab === 2 ? '' : 'display:none'}>
        <input class="filter-search" placeholder="Search by title, text, and site" @input=${this.handleSearchInput} .value=${this.searchText}></input>
        <dropdown-element
          class="dropdown"
          .activeTab=${this.activeTab}
          .disabled=${this.disableSymbolDropdown}
          @option-selected=${this.handleSymbolSelected}
          .resetDropdownSelection=${this.resetDropdownSelection}
          .selectedText=${"Filter By Symbol: "}
          .items=${this.symbols}>
        </dropdown-element>
        <toggle-switch-element
          .on=${this.isCurrentPositions}
          .title=${'Current Positions'}
          @switch-toggled=${this.handleSwitchToggled}>
        </toggle-switch-element>
      </div>
    `;
  }
}

customElements.define('news-filter-element', NewsFilterElement);