import { LitElement, html, css } from 'lit';

class DatepickerInputElement extends LitElement {
  static get properties() {
    return {
      date: { type: String },
      showCalendar: { type: Boolean },
      currentMonth: { type: Number },
      currentYear: { type: Number },
    };
  }

  static get styles() {
    return css`
      :host {
        display: inline-block;
        position: relative;
        --input-width: 118px;
      }
      
      input[type="text"] {
        display: flex;
        width: var(--input-width);
        padding: 10px;
        align-items: center;
        gap: 12px;
        border: 1px solid #EFF1F7;
        background: #FFF;
      }
      
      .calendar {
        position: absolute;
        z-index: 1;
        right: 0px;
        width: auto;
        background-color: white;
        border-radius: 4px;
        border: 1px solid #ccc;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
        padding: 6px;
        display: none;
      }
      
      .calendar.visible {
        display: block;
      }
      
      button {
        background-color: transparent;
        border: none;
        cursor: pointer;
        font-size: 12px;
      }
      
      .days {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        gap: 3px;
      }
      
      .day {
        text-align: center;
        padding: 3px;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        line-height: 20px;
        cursor: pointer;
        font-size: 12px;
      }
      
      .day.inactive {
        color: #ccc;
        cursor: default;
      }
      
      .day.today {
        background-color: #f7f7f7;
        font-weight: bold;
      }
      
      .day.selected {
        font-weight: bold;
        font-size: 14px;
      }
      
      .controls {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
      }
      
      .middle-section {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }

      .month,
      .year {
        margin: auto;
      }
      
      /* Dropdown styles */
      .dropdown {
        position: relative;
        display: inline-block;
      }
      
      .selected-value {
        cursor: pointer;
      }

      .dropdown-content {
        display: none;
        position: absolute;
        background-color: #f9f9f9;
        max-height: 250px;
        overflow-y: auto;
        min-width: 100px;
        right: 0px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        padding: 12px 16px;
        z-index: 1;
      }
      
      .dropdown-content .dropdown-item {
        cursor: pointer;
        padding: 5px 0;
      }
      
      .dropdown-content .dropdown-item:hover {
        background-color: #f1f1f1;
      }
    `;
  }

  constructor() {
    super();
    const currentDate = new Date();
    this.showCalendar = false;
    this.currentMonth = currentDate.getMonth() + 1;
    this.currentYear = currentDate.getFullYear();
  }

  connectedCallback() {
    super.connectedCallback();
    document.addEventListener('click', this._handleDocumentClick);
  }
  
  disconnectedCallback() {
    super.disconnectedCallback();
    document.removeEventListener('click', this._handleDocumentClick);
  }

  _handleDocumentClick(event) { // eslint-disable-line
    const path = event.composedPath();
    const clickedElement = path[0]; // last element in the path is the actual clicked element

    if (!clickedElement.closest('.calendar') && !clickedElement.closest('#date-input')) {
      // clicked outside the calendar and the date input, hide the calendar
      this.showCalendar = false;
    }
  }

  isWeekendDay(prevMonthDaysToShow, dayOfMonth) {
    const weekendDays = [0, 6];
    let dayOfWeek = ((dayOfMonth + prevMonthDaysToShow)) % 7;

    return weekendDays.includes(dayOfWeek);
  }
  

  _handleInputFocus() {
    this.showCalendar = true;
    this._resetDates();
  }

  _handleDateChange(event) {
    this.date = event.target.dataset.date;
    this.showCalendar = false;

    this.dispatchEvent(new CustomEvent('date-changed', { bubbles: true, composed: true, detail: {date: this.date} }));
  }

  _handleMonthChange(delta) {
    const newDate = new Date(this.currentYear, this.currentMonth + delta, 1);
    this.currentMonth = newDate.getMonth() + 1;
    this.currentYear = newDate.getFullYear();
  }

  _handleYearChange(newYear) {
    this.currentYear = newYear;
  }

  _getMonthName(monthIndex) {
    if (monthIndex === 0) {
      monthIndex = 12;
    }

    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    return months[monthIndex - 1];
  }

  _toggleDropdown(type) {
    const dropdownContent = this.shadowRoot.querySelector(`.dropdown-content[data-type="${type}"]`);
    dropdownContent.style.display = dropdownContent.style.display === 'none' || !dropdownContent.style.display ? 'block' : 'none';
  }

  _resetDates() {
    const currentDate = new Date();
    this.currentMonth = currentDate.getMonth() + 1;
    this.currentYear = currentDate.getFullYear();
  }

  _renderCalendar() {
    // Determine the first day of the month
    const firstDayOfMonth = new Date(this.currentYear, this.currentMonth - 1, 1);
    const firstDayOfWeek = firstDayOfMonth.getDay();

    // Determine the last day
    const daysInMonth = new Date(this.currentYear, this.currentMonth, 0).getDate();

    const days = [];
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = currentYear - 30; i <= currentYear; i++) {
      years.push(i);
    }
    years.reverse();

    // Include previous month's days
    const prevMonthDaysToShow = firstDayOfWeek - 1;
    const prevMonthDate = new Date(this.currentYear, this.currentMonth, 0);
    const prevMonthDays = prevMonthDate.getDate();
    for (let i = prevMonthDays - prevMonthDaysToShow; i <= prevMonthDays; i++) {
      days.push({ day: i, inactive: true });
    }

    // Include current month's days
    for (let i = 1; i <= daysInMonth; i++) {
      const isWeekend = this.isWeekendDay(prevMonthDaysToShow, i);
      days.push({ day: i, inactive: isWeekend });
    }

    // Include next month's days
    const numberOfDays = 7 - (days.length % 7);
    const nextMonthDaysToShow = numberOfDays !== 7 ? numberOfDays : 0;
    for (let i = 1; i <= nextMonthDaysToShow; i++) {
      days.push({ day: i, inactive: true });
    }

    const daysOfWeek = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

    return html`
      <div class="calendar ${this.showCalendar ? 'visible' : ''}">
        <div class="controls">
          <div class="left-button">
            <button @click="${() => this._handleMonthChange(-2)}">&lt;</button>
          </div>
          <div class="middle-section">
            <div class="month">
              <div class="dropdown">
                <div class="selected-value" @click="${() => this._toggleDropdown('month')}">${months[this.currentMonth - 1]} <span class="arrow">&#9662;</span></div>
                <div class="dropdown-content" data-type="month" @mousedown="${(e) => e.stopPropagation()}">
                  ${months.map((month, index) => html`<div class="dropdown-item" @click="${() => { this._handleMonthChange(index - this.currentMonth); this._toggleDropdown('month'); }}">${month}</div>`)}
                </div>
              </div>
            </div>
            <div class="year">
              <div class="dropdown">
                <div class="selected-value" @click="${() => this._toggleDropdown('year')}">${this.currentYear} <span class="arrow">&#9662;</span></div>
                <div class="dropdown-content" data-type="year" @mousedown="${(e) => e.stopPropagation()}">
                  ${years.map((year) => html`<div class="dropdown-item" @click="${() => { this._handleYearChange(year); this._toggleDropdown('year'); }}">${year}</div>`)}
                </div>
              </div>
            </div>
          </div>
          <div class="right-button">
            <button @click="${() => this._handleMonthChange(0)}">&gt;</button>
          </div>
        </div>    
        <div class="days">
          ${daysOfWeek.map((dayOfWeek) => html`<div class="day-of-week">${dayOfWeek}</div>`)}
          ${days.map(
            (day) => html`
              <div class="day ${day.inactive ? 'inactive' : ''} ${this.date === `${this.currentMonth}/${day.day}/${this.currentYear}` ? 'selected' : ''}"
                  @click="${day.inactive ? null : () => this._handleDateChange({ target: { dataset: { date: `${this.currentMonth}/${day.day}/${this.currentYear}` } } })}">
                ${day.day}
              </div>
            `
          )}
        </div>
      </div>
    `;
  }

  render() {
    return html`
      <div>
        <input
          type="text"
          id="date-input"
          placeholder="Select a date"
          .value="${this.date}"
          @focus="${this._handleInputFocus}"
        />
        ${this._renderCalendar()}
      </div>
    `;
  }
}

customElements.define('datepicker-input-element', DatepickerInputElement);