import { LitElement, html, css } from 'lit';
import './modals/buy-form-modal';
import './modals/delete-row-modal';
import './modals/edit-row-modal';
import './spinner-element';
import filter from './images/filter.svg';
import Fuse from 'fuse.js';

class StocksFilterElement extends LitElement {
  static get properties() {
    return {
      activeTab: Number,
      isFilterCardVisible: Boolean,
      filteredSortedDividends: Array,
      filteredTableData: Array,
      hideFilterButton: Boolean,
      searchText: String,
      selectedFilterType: String,
      sortedDividends: Array,
      tableData: Array,
      tickers: Array,
      types: Array,
    };
  }

  constructor() {
    super();
    this.sortedDividends = [];
    this.tableData = [];
    this.types = [];
    this.tickers = [];
    this.filteredSortedDividends = [];
    this.filteredTableData = [];
    this.hideFilterButton = false;
    this.searchText = '';
  }

  connectedCallback() {
    super.connectedCallback();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }

  willUpdate(changedProperties) {
    if (changedProperties.has('activeTab')) {
      if (this.activeTab === 3) {
        this.hideFilterButton = true;
      } else {
        this.hideFilterButton = false;
      }
    }

    if (changedProperties.has('sortedDividends')) {
      if (this.sortedDividends) {
        this.performDividendFiltering();
      }
    }

    if (changedProperties.has('tableData')) {
      if (this.tableData) {
        this.types = ['All types', ...new Set(this.tableData.slice(1).map(row => row[1]))];
        this.performTableDataFiltering();
      }
    }
  }

  handleApplyFilters() {
    this.performTableDataFiltering();
    this.isFilterCardVisible = false;
  }

  handleFilterButtonClick() {
    this.isFilterCardVisible = !this.isFilterCardVisible;
  }

  handleOptionChanged(evt) {
    if (evt.detail === 'All types') {
      this.selectedFilterType = null;
    } else {
      this.selectedFilterType = evt.detail;
    }
  }

  handleSearchInput(event) {
    this.searchText = event.target.value;
    this.performTableDataFiltering();
    this.performDividendFiltering();
  }

  performDividendFiltering() {
    if (!this.searchText) {
      // If search text is empty, show all dividends
      this.filteredSortedDividends = this.sortedDividends;
    } else {
      let filteredDividends = this.sortedDividends;

      if (this.searchText) {
        const options = {
          keys: ['ticker'],
        };
        const fuse = new Fuse(filteredDividends, options);
        filteredDividends = fuse.search(this.searchText).map(result => result.item);
      }

      this.filteredSortedDividends = filteredDividends;
    }

    this.dispatchEvent(new CustomEvent('filtered-dividend-data', { bubbles: true, composed: true, detail: this.filteredSortedDividends }));
  }

  performTableDataFiltering() {
    if (!this.searchText && !this.selectedFilterType) {
      // If both search text and type filter are empty, show all rows
      this.filteredTableData = this.tableData;
    } else {
      let filteredRows = this.tableData.slice(1); // Exclude the first row (title row)

      if (this.selectedFilterType) {
        // Apply the Type filter if it's selected
        filteredRows = filteredRows.filter(row => row[1] === this.selectedFilterType);
      }

      if (this.searchText) {
        // Apply the Ticker search if search text is present
        const options = {
          keys: ['2'], // Use index 2 to access the Ticker string (row[2])
        };
        const fuse = new Fuse(filteredRows, options);
        filteredRows = fuse.search(this.searchText).map(result => result.item);
      }

      this.filteredTableData = [this.tableData[0], ...filteredRows]; // Add back the first row
    }

    this.dispatchEvent(new CustomEvent('filtered-table-data', { bubbles: true, composed: true, detail: this.filteredTableData }));
  }

  static get styles() {
    return css`
      .filter-container {
        display: flex;
        width: 1144px;
        height: 46px;
        flex-shrink: 0;
        align-items: center;
        background: #FFF;
        padding: 16px 20px;
        gap: 8px;
      }

      .filter-search {
        display: inline-flex;
        width: 247px;
        height: 12px;
        padding: 10px 10px 10px 10px;
        align-items: center;
        border: 1px solid #EFF1F7;
        background: #FAFAFC;
        cursor: pointer;
      }

      .filter-button {
        display: inline-flex;
        width: 72px;
        height: 32px;
        padding: 10px;
        align-items: center;
        gap: 8px;
        border: 1px solid #EFF1F7;
        background: #FAFAFC;
        cursor: pointer;
        color: #2E2E3A;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 12px;
        letter-spacing: -0.14px;
      }

      .filter-icon {
        width: 12px;
        height: 12px;
        flex-shrink: 0;
      }

      .filter-card {
        display: none;
      }

      .filter-card.visible {
        display: inline-flex;
        position: absolute;
        top: 165px;
        left: 33.7%;
        width: 160px;
        padding: 16px 12px 12px 12px;
        flex-direction: column;
        justify-content: center;
        gap: 12px;
        border-radius: 0px 0px 8px 8px;
        background: #FFF;
        box-shadow: 0px 25px 50px 0px rgba(0, 0, 0, 0.15);
      }

      .filter-card button {
        display: flex;
        width: 60%;
        margin-left: auto;
        padding: 8px 12px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        background: #3346E7;
        border: none;
        color: #FFF;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 12px;
        letter-spacing: -0.14px;
        cursor: pointer;
      }

      .dropdown {
        --dropdown-background-color: #FAFAFC;
        --dropdown-button-border: 1px solid #F2F3F7;
        --dropdown-button-padding: 10px;
      }
    `;
  }

  render() {
    return html`
      <div class="filter-container">
        <input class="filter-search" placeholder="Search by Ticker" @input=${this.handleSearchInput} .value=${this.searchText}></input>
        ${!this.hideFilterButton ? html`
          <button class="filter-button" @click=${this.handleFilterButtonClick}>
            Filter
            <img src="${filter}" class="filter-icon" alt="Filter" />
          </button>` : ''}
      </div>

      <!-- Filter Card -->
      <div class="filter-card ${this.isFilterCardVisible ? 'visible' : ''}">
        <dropdown-element class="dropdown" @option-selected=${this.handleOptionChanged} .items=${this.types}></dropdown-element>
        <button @click=${this.handleApplyFilters}>Apply Filter</button>
      </div>
    `;
  }
}

customElements.define('stocks-filter-element', StocksFilterElement);