import { LitElement, html, css } from 'lit';
import './modal-element';

const GOOGLE_API_KEY = 'AIzaSyAcwLc7fO1RjJxq7a3aFAFUHafO04rdDPc';
const DISCOVERY_DOC = 'https://sheets.googleapis.com/$discovery/rest?version=v4';

class DeleteRowModal extends LitElement {
  static get properties() {
    return {
      currentRow: Object,
      isSellRow: Boolean,
      isBoughtRow: Boolean,
      rowNumber: Number,
      sheetId: String,
      spreadsheetId: String,
    };
  }

  constructor() {
    super();

    this.isSellRow = false;
    this.isBoughtRow = false;

    gapi.load('client', () => {
      gapi.client.init({
        apiKey: GOOGLE_API_KEY,
        discoveryDocs: [DISCOVERY_DOC],
      }).then(() => {
        gapi.client.load('sheets', 'v4', () => {
          this.getSheetId();
        });
      });
    });
  }

  connectedCallback() {
    super.connectedCallback();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }

  async deleteRow(rowIndex) {
    await gapi.client.sheets.spreadsheets.batchUpdate({
      spreadsheetId: this.spreadsheetId,
      resource: {
        requests: [
          {
            deleteDimension: {
              range: {
                sheetId: this.sheetId,
                dimension: 'ROWS',
                startIndex: rowIndex,
                endIndex: rowIndex + 1,
              },
            },
          },
        ],
      },
    }).then(() => {
      console.log(`Row ${rowIndex} deleted successfully.`);
    }).catch((error) => {
      console.error('Error deleting row:', error);
    });
  }

  async getSheetId() {
    try {
      const sheetName = this.isSellRow ? 'Sheet2' : 'Sheet1';
      const sheet = await gapi.client.sheets.spreadsheets.get({
        spreadsheetId: this.spreadsheetId,
        ranges: [sheetName],
        includeGridData: false,
      });
      this.sheetId = sheet.result.sheets[0].properties.sheetId;
    } catch (error) {
      console.error(error);
    }
  }

  handleCloseClick() {
    const modalElement = this.shadowRoot.querySelector('modal-element');
    modalElement.removeAttribute('show');
  }

  async handleSubmit() {
    const rowIndex = this.rowNumber;
    const ticker = this.currentRow.ticker;

    const updatedRow = [
      'filler date',
      'filler type',
      ticker
    ];
    
    if (this.isSellRow || this.isBoughtRow) {
      await this.deleteRow(rowIndex);
      const eventName = this.isSellRow ? 'load-sell-positions' : 'load-current-positions';
      this.dispatchEvent(new CustomEvent(eventName, { bubbles: true, composed: true }));
    } else {
      this.dispatchEvent(new CustomEvent('reload-dividends', {bubbles: true,composed: true, detail: { deleteDividends: true, sheetId: this.sheetId, updatedRow }}));
    }
  }

  static get styles() {
    return css`
      .delete-text {
        margin-bottom: 20px;
        color: #2E2E3A;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: -0.14px;
      }

      .modal-element {
        align-items: center;
      }
    `;
  }
  
  render() {
    return html`
      <modal-element class="modal-element" @close=${this.handleCloseClick} @submit=${this.handleSubmit}>
        <div slot="modal-slot">
          <div class="delete-text">
            <p>Are you sure you want to delete ${this.isSellRow || this.isBoughtRow ? 'this row' : 'all rows'} for ${this.currentRow.ticker}?</p>
          </div>
        </div>
      </modal-element>
    `;
  }
}

customElements.define('delete-row-modal', DeleteRowModal);