import { LitElement, html, css } from 'lit';

class ModalElement extends LitElement {
  static get properties() {
    return {
      isOneButton: Boolean,
      submitDisabled: Boolean,
      show: Boolean,
    };
  }

  constructor() {
    super();
  }

  handleCloseClick() {
    this.dispatchEvent(new CustomEvent('close'));
  }

  handleSubmitClick() {
    this.dispatchEvent(new CustomEvent('submit'));
    this.dispatchEvent(new CustomEvent('close'));
  }

  static get styles() {
    return css`
      :host {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 999;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.3s ease;
        --text-alignment: center;
      }

      :host([show]) {
        opacity: 1;
        pointer-events: auto;
      }

      button {
        color: #FFF;
        font-family: Outfit;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 133.333% */
        letter-spacing: -0.12px;
        border: none;
        cursor: pointer;
      }

      .modal {
        position: absolute;
        min-width: 350px;
        max-width: 352px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 24px;
        border-radius: 5px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
        overflow-y: auto;
        display: flex;
        justify-content: center;
        align-items: var(--text-alignment);
        flex-direction: column;
      }

      .button-container {
        display: flex;
        width: 350px;
        justify-content: space-between;
        align-items: flex-start;
      }

      .get-started-container {
        display: flex;
        width: 350px;
        justify-content: center;
      }

      .nevermind {
        display: flex;
        padding: 8px 12px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        background: #F84439;
      }

      .nevermind:hover {
        background-color: #660000;
      }

      .submit {
        display: flex;
        padding: 8px 22px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        background: #3346E7;
      }

      .submit:hover {
        background-color: #003580;
      }

      .submit:disabled {
        background-color: #3346E7;
        cursor: not-allowed;
        opacity: 0.5;
      }
    `;
  }

  render() {
    return html`
      <div class="modal">
        <slot name="modal-slot"></slot>
        ${this.isOneButton
          ? html`
            <div class="get-started-container">
              <button class="submit" @click="${this.handleCloseClick}">Get Started</button>
            </div>`
          : html`
            <div class="button-container">
              <button class="nevermind" @click="${this.handleCloseClick}">Nevermind</button>
              <button class="submit" @click="${this.handleSubmitClick}" .disabled="${this.submitDisabled}">Submit</button>
            </div>`
        }
      </div>
    `;
  }
}

customElements.define('modal-element', ModalElement);
