import { LitElement, html, css } from 'lit';
import { addCommasToNumber } from './util';

const GOOGLE_API_KEY = 'AIzaSyAcwLc7fO1RjJxq7a3aFAFUHafO04rdDPc';
const DISCOVERY_DOC = 'https://sheets.googleapis.com/$discovery/rest?version=v4';

import './modals/delete-row-modal';
import './spinner-element';
import './stocks-filter-element';
import trash from './images/trash.svg';

class StocksSoldElement extends LitElement {
  static get properties() {
    return {
      activeTab: Number,
      currentRow: Object,
      filteredTableData: Array,
      firstYear: String,
      loadingData: Boolean,
      rowNumber: Number,
      secondYear: String,
      spreadsheetId: String,
      tableData: {
        type: Array,
        value: () => []
      },
      thirdYear: String,
    };
  }

  constructor() {
    super();

    this.loadingData = true;
    this.tableData = [];
    this.activeTab = 0;
    this.currentRow = {};
    this.firstYear = new Date().getFullYear();
    this.secondYear = new Date().getFullYear() - 1;
    this.thirdYear = new Date().getFullYear() - 2;
    this.spreadsheetId = localStorage.getItem('spreadsheet_id');

    this._boundLoadSellPositions = this.loadSell.bind(this);
    this._boundFilteredTableDataChanged = this.handleFilteredTableDataChanged.bind(this);

    gapi.load('client', () => {
      gapi.client.init({
        apiKey: GOOGLE_API_KEY,
        discoveryDocs: [DISCOVERY_DOC],
      }).then(() => {
        gapi.client.load('sheets', 'v4', () => {
          this.loadSellSpreadsheetData();
        });
      });
    });
  }

  connectedCallback() {
    super.connectedCallback();
    document.addEventListener('load-sell-positions', this._boundLoadSellPositions);
    document.addEventListener('filtered-table-data', this._boundFilteredTableDataChanged);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    document.removeEventListener('load-sell-positions', this._boundLoadSellPositions);
    document.removeEventListener('filtered-table-data', this._boundFilteredTableDataChanged);
  }

  willUpdate(changedProperties) {
    if (changedProperties.has('tableData')) {
      if (this.tableData) {
        this.filteredTableData = this.tableData;
      }
    }
  }

  calculateTotals(tableData) {
    tableData = tableData.slice(1);
    const totalPurchaseAmount = tableData.reduce((acc, row) => acc + parseFloat(row[4]), 0);
    const totalGainLoss = tableData.reduce((acc, row) => acc + parseFloat(row[9]), 0);
    const totalSellingAmount = tableData.reduce((acc, row) => acc + parseFloat(row[7]), 0);
    const totalGainLossPercent = (totalGainLoss / totalSellingAmount) * 100;

    return {
      totalPurchaseAmount,
      totalSellingAmount,
      totalGainLoss,
      totalGainLossPercent
    };
  }

  createTableForYear(year) {
    const filteredTableData = this.filteredTableData ? this.filteredTableData.filter((row, rowIndex) => {
      if (rowIndex === 0) {
        return true; // Include the header row
      }
      const dateParts = row[1].split('/');
      const rowYear = parseInt(dateParts[2]);
      return rowYear === year;
    }) : null;
  
    return html`
      ${this.loadingData
        ? html`<spinner-element class="spinner"></spinner-element>`
        : this.filteredTableData && this.filteredTableData.length > 1
          ? html`
            <table class="data-table">
              ${filteredTableData.map((row, rowIndex) => {
                // Skip the first row from formatting
                if (rowIndex === 0) {
                  return html`<tr class="title-row">${row.map(cell => html`<td class="title-cell">${cell}</td>`)}</tr>`;
                }
              
                return html`
                  <tr class="data-row">
                    ${row.map((cell, index) => {
                      if (index === row.length - 1) {
                        const lastCell = parseFloat(cell);
                        return html`
                        <td class="data-cell last-column">
                          <div>${lastCell.toFixed(2)}%</div>
                          <img src="${trash}" class="trash-icon" alt="Trash" @click=${() => this.handleDeleteRow(rowIndex)} />
                        </td>
                        `;
                      } else if (index >= 3 && index <= 10) {
                        const floatCell = parseFloat(cell);
                        if (index === 10) {
                          cell = addCommasToNumber(floatCell) + "%";
                        } else {
                          if (floatCell < 0) {
                            cell = "-$" + addCommasToNumber(Math.abs(floatCell));
                          } else {
                            // Skip over the number of shares
                            cell = (index === 6) ? addCommasToNumber(cell) : "$" + addCommasToNumber(floatCell);
                          }
                        }
                      }
                      return html`<td class="data-cell">${cell}</td>`;
                    })}
                  </tr>
                `;
              })}
              ${this.populateTotalRow(filteredTableData, 4)}
            </table>
          `
          : html`<p class="no-sold-stocks">There are no stocks sold for this year</p>`
      }
    `;
  }

  handleDeleteRow(rowIndex) {
    const strippedSheetData = this.tableData.slice(1);
    const rowData = strippedSheetData[rowIndex - 1];
    const [
      purchaseDate,
      sellDate,
      ticker
    ] = rowData.slice(0, 6);
  
    this.currentRow = {
      'purchaseDate': purchaseDate,
      'sellDate': sellDate,
      'ticker': ticker,
    };

    this.rowNumber = rowIndex;
    const deleteRowModal = this.shadowRoot.querySelector('delete-row-modal');
    const modalElement = deleteRowModal.shadowRoot.querySelector('modal-element');
    modalElement.setAttribute('show', '');
  }

  handleFilteredTableDataChanged(evt) {
    this.filteredTableData = evt.detail;
  }

  async loadSell() {
    this.loadingData = true;
    await this.loadSellSpreadsheetData();
  }

  async loadSellSpreadsheetData() {
    try {
      await this.sortValuesByDate();
      await gapi.client.sheets.spreadsheets.values.get({
        spreadsheetId: this.spreadsheetId,
        range: 'Sheet2!A1:K',
      }).then((response) => {
        this.tableData = response.result.values;
        this.loadingData = false;
      }, (response) => {
        console.log('Error: ' + response.result.error.message);
      });
    } catch (error) {
      console.error(error);
    }
  }

  populateTotalRow(tableData, columnAmount) {
    // Calculate the total weighted gain/loss values
    const totalValues = this.calculateTotals(tableData);
  
    // Map the total values to HTML elements for the total row
    return html`
      <tr class="total-row">
        <td class="total-cell" colspan="${columnAmount}"><strong>Total</strong></td>
        <td class="total-cell"><strong>$${addCommasToNumber(totalValues.totalPurchaseAmount)}</strong></td>
        <td class="total-cell" colspan="2"></td>
        <td class="total-cell"><strong>$${addCommasToNumber(totalValues.totalSellingAmount)}</strong></td>
        <td class="total-cell" colspan="1"></td>
        <td class="total-cell"><strong>${totalValues.totalGainLoss < 0 ? '-$' : '$'}${addCommasToNumber(Math.abs(totalValues.totalGainLoss))}</strong></td>
        <td class="total-cell"><strong>${addCommasToNumber(totalValues.totalGainLossPercent)}%</strong></td>
      </tr>
    `;
  }

  selectTab(tabIndex) {
    this.activeTab = tabIndex;
  }

  async sortValuesByDate() {
    await gapi.client.init({
      apiKey: GOOGLE_API_KEY,
      discoveryDocs: [DISCOVERY_DOC],
    });
  
    var range = 'Sheet2!A2:M';
    var response = await gapi.client.sheets.spreadsheets.values.get({
      spreadsheetId: this.spreadsheetId,
      range: range,
    });
    var values = response.result.values;
    if (values) {
      values.sort(function(a, b) {
        var dateA = new Date(a[1]);
        var dateB = new Date(b[1]);
        return dateA - dateB;
      });

      var updateValues = {
        range: range,
        values: values,
      };
      await gapi.client.sheets.spreadsheets.values.update({
        spreadsheetId: this.spreadsheetId,
        range: range,
        valueInputOption: 'RAW',
        resource: updateValues,
      });
    }
  }

  static get styles() {
    return css`
      .stock-sold-container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin: 48px;
      }

      .header-container {
        display: flex;
        width: 1184px;
        height: 46px;
        flex-shrink: 0;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
      }

      .tabs-container {
        width: 332px;
        height: 32px;
        padding: 6px;
        flex-shrink: 0;
        background: #FFF;
        backdrop-filter: blur(10px);
      }

      .tab {
        display: inline-flex;
        color: #2E2E3A;
        fill: #2E2E3A;
        text-decoration: none;
        padding: 8px 10px;
        justify-content: center;
        align-items: center;
        gap: 8px;
      }

      .tab.active {
        color: #FFFFFF;
        fill: #FFFFFF;
        background: #3346E7;
      }

      .tab-icon {
        vertical-align: middle;
        width: 16px;
        height: 16px;
      }

      .tab-text {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: -0.14px;
      }

      .trash-icon {
        width: 16px;
        height: 16px;
        flex-shrink: 0;
        cursor: pointer;
      }

      .card-body {
        display: inline-flex;
        width: 1184px;
        flex-shrink: 0;
      }

      .card {
        display: inline-flex;
        width: 1184px;
        flex-shrink: 0;
      }

      .data-table {
        width: 1184px;
        flex-shrink: 0;
        margin: 0;
        border-collapse: collapse;
      }

      .title-row {
        border-bottom: 1px solid #EFF1F7;
        background: #F2F3F7;
      }

      .title-cell {
        color: #2E2E3A;
        font-family: 'Montserrat', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 12px;
        letter-spacing: -0.12px;
        padding: 14px 0 14px 20px;
      }

      .data-row {
        border-bottom: 1px solid #EFF1F7;
        background: #FFF;
      }

      .data-cell {
        color: #2E2E3A;
        font-family: 'Montserrat', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 12px;
        letter-spacing: -0.12px;
        padding: 14px 20px;
      }

      .total-row {
        border-bottom: 1px solid #EFF1F7;
        background: #FFF;
      }

      .total-cell {
        color: #2E2E3A;
        font-family: 'Montserrat', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 12px;
        letter-spacing: -0.12px;
        padding: 20px 20px;
      }

      .no-sold-stocks {
        text-align: center;
        font-size: 24px;
      }

      .tag-wrapper {
        width: calc(100% - 10px);
      }

      .last-column {
        position: relative;
        width: 150px;
      }
      
      .last-column div {
        display: inline-block;
        width: calc(100% - 48px);
      }
      
      .last-column img {
        height: 16px;
        width: 16px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin-left: 10px;
        cursor: pointer;
      }
      
      .last-column img:last-child {
        right: 20px;
      }

      .spinner {
        margin: 32px auto auto auto;
      }
    `;
  }

  render() {
    return html`
      <delete-row-modal .currentRow=${this.currentRow} .isSellRow=${true} .rowNumber=${this.rowNumber} .spreadsheetId=${this.spreadsheetId}></delete-row-modal>
      <div class="stock-sold-container">
        <div class="header-container">
          <div class="tabs-container">
            <a href="#" name="tab1" class="tab ${this.activeTab === 0 ? 'active' : ''}" @click="${() => this.selectTab(0)}">
              <box-icon class="tab-icon" name='dollar-circle'></box-icon>
              <span class="tab-text">All Sold</span>
            </a>
            <a href="#" name="tab2" class="tab ${this.activeTab === 1 ? 'active' : ''}" @click="${() => this.selectTab(1)}">
              <box-icon class="tab-icon" name='calendar'></box-icon>
              <span class="tab-text">${this.firstYear}</span>
            </a>
            <a href="#" name="tab3" class="tab ${this.activeTab === 2 ? 'active' : ''}" @click="${() => this.selectTab(2)}">
              <box-icon class="tab-icon" name='calendar'></box-icon>
              <span class="tab-text">${this.secondYear}</span>
            </a>
            <a href="#" name="tab4" class="tab ${this.activeTab === 3 ? 'active' : ''}" @click="${() => this.selectTab(3)}">
              <box-icon class="tab-icon" name='calendar'></box-icon>
              <span class="tab-text">${this.thirdYear}</span>
            </a>
          </div>
        </div>
        <stocks-filter-element .hideFilterButton=${true} .tableData=${this.tableData}></stocks-filter-element>
        <div class="card">
          <div class="card-body" style=${this.activeTab === 0 ? '' : 'display:none'}>
            ${this.loadingData
              ? html`<spinner-element class="spinner"></spinner-element>`
              : this.filteredTableData && this.filteredTableData.length > 1
                ? html`
                  <table class="data-table">
                    ${this.filteredTableData && this.filteredTableData.map((row, rowIndex) => {
                      // Skip the first row from formatting
                      if (rowIndex === 0) {
                        return html`<tr class="title-row">${row.map(cell => html`<td class="title-cell">${cell}</td>`)}</tr>`;
                      }
                    
                      return html`
                        <tr class="data-row">
                          ${row.map((cell, index) => {
                            if (index === row.length - 1) {
                              const lastCell = parseFloat(cell);
                              return html`
                              <td class="data-cell last-column">
                                <div>${lastCell.toFixed(2)}%</div>
                                <img src="${trash}" class="trash-icon" alt="Trash" @click=${() => this.handleDeleteRow(rowIndex)} />
                              </td>
                              `;
                            } else if (index >= 3 && index <= 10) {
                              const floatCell = parseFloat(cell);
                              if (index === 10) {
                                cell = addCommasToNumber(floatCell) + "%";
                              } else {
                                if (floatCell < 0) {
                                  cell = "-$" + addCommasToNumber(Math.abs(floatCell));
                                } else {
                                  // Skip over the number of shares
                                  cell = (index === 6) ? addCommasToNumber(cell) : "$" + addCommasToNumber(floatCell);
                                }
                              }
                            }
                            return html`<td class="data-cell">${cell}</td>`;
                          })}
                        </tr>
                      `;
                    })}
                    ${this.filteredTableData && this.populateTotalRow(this.filteredTableData, 4)}
                  </table>
                `
                : html`<p class="no-sold-stocks">There are no stocks sold yet</p>`
            }
          </div>
          <div class="card-body" style=${this.activeTab === 1 ? '' : 'display:none'}>
            ${this.createTableForYear(this.firstYear)}
          </div>
          <div class="card-body" style=${this.activeTab === 2 ? '' : 'display:none'}>
            ${this.createTableForYear(this.secondYear)}
          </div>
          <div class="card-body" style=${this.activeTab === 3 ? '' : 'display:none'}>
            ${this.createTableForYear(this.thirdYear)}
          </div>
        </div>
      </div>
    `;
  }
}

customElements.define('stocks-sold-element', StocksSoldElement);