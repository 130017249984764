import { LitElement, html, css } from 'lit';
import './spinner-element';
import './news-article-element';

class NewsElement extends LitElement {
  static get properties() {
    return {
      articlesPerPage: Number,
      cryptoNews: Array,
      currentPageCrypto: Number,
      currentPageStock: Number,
      stockNews: Array,
      spreadsheetId: String,
    };
  }

  constructor() {
    super();
    this.articlesPerPage = 3;
    this.currentPageStock = 0;
    this.currentPageCrypto = 0;
  }

  connectedCallback() {
    super.connectedCallback();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }

  handleStockPreviousScroll() {
    this.currentPageStock--;
    if (this.currentPageStock < 0) {
      this.currentPageStock = 0; // Prevent scrolling before the first page
    }
    
    const stockArticleContainer = this.shadowRoot.getElementById('stockArticleContainer');
    const articleWidth = stockArticleContainer.querySelector('.article-item').clientWidth;
    const gap = 20;
    const totalWidth = (articleWidth + gap) * this.articlesPerPage;
    const scrollPosition = totalWidth * this.currentPageStock;
    stockArticleContainer.scrollLeft = scrollPosition;
  }

  handleStockNextScroll() {
    const stockArticleContainer = this.shadowRoot.getElementById('stockArticleContainer');
    const articleWidth = stockArticleContainer.querySelector('.article-item').clientWidth;
    const gap = 20;
    const totalWidth = (articleWidth + gap) * this.articlesPerPage;
  
    const maxPage = Math.floor(this.stockNews.length / this.articlesPerPage);
    if (this.currentPageStock < maxPage) {
      this.currentPageStock++;
      const scrollPosition = totalWidth * this.currentPageStock;
      stockArticleContainer.scrollLeft = scrollPosition;
    }
  }

  handleCryptoPreviousScroll() {
    this.currentPageCrypto--;
    if (this.currentPageCrypto < 0) {
      this.currentPageCrypto = 0; // Prevent scrolling before the first page
    }
    
    const cryptoArticleContainer = this.shadowRoot.getElementById('cryptoArticleContainer');
    const articleWidth = cryptoArticleContainer.querySelector('.article-item').clientWidth;
    const gap = 20;
    const totalWidth = (articleWidth + gap) * this.articlesPerPage;
    const scrollPosition = totalWidth * this.currentPageCrypto;
    cryptoArticleContainer.scrollLeft = scrollPosition;
  }

  handleCryptoNextScroll() {
    const cryptoArticleContainer = this.shadowRoot.getElementById('cryptoArticleContainer');
    const articleWidth = cryptoArticleContainer.querySelector('.article-item').clientWidth;
    const gap = 20;
    const totalWidth = (articleWidth + gap) * this.articlesPerPage;
  
    const maxPage = Math.floor(this.cryptoNews.length / this.articlesPerPage);
    if (this.currentPageCrypto < maxPage) {
      this.currentPageCrypto++;
      const scrollPosition = totalWidth * this.currentPageCrypto;
      cryptoArticleContainer.scrollLeft = scrollPosition;
    }
  }

  handleViewAllClick(evt) {
    const parentElement = evt.target.parentElement;
    const textElement = parentElement.querySelector('.header-text');
    const textValue = textElement.textContent;

    this.dispatchEvent(new CustomEvent('view-all', {
      detail: { text: textValue },
      composed: true,
      bubbles: true,
    }));
  }

  static get styles() {
    return css`
      .page-container {
        display: inline-flex;
        flex-direction: column;
        gap: 32px;
      }

      .row-container {
        display: inline-flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
      }

      .header-container {
        display: flex;
        align-items: center;
        gap: 16px;
      }

      .header-text {
        color: #2E2E3A;
        font-family: 'Montserrat', sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: -0.24px;
      }

      .view-all-button {
        display: flex;
        padding: 4px 12px;
        align-items: center;
        gap: 10px;
        border: none;
        border-radius: 40px;
        background: #3346E7;
        cursor: pointer;
        color: #FFF;
        font-family: 'Montserrat', sans-serif;
        font-size: 10px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px; /* 160% */
        letter-spacing: 0.3px;
      }

      .scroll-container {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        gap: 24px;
      }

      .article-container {
        display: inline-flex;
        flex-direction: row;
        gap: 20px;
        width: 1120px;
        overflow: hidden;
        scroll-behavior: smooth;
      }

      .article-loading {
        display: flex;
        width: 360px;
        height: 428px;
        padding-top: 20px;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        cursor: pointer;
        background: #F2F3F7;
        position: relative;
      }

      .article-loading::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 428px;
        background: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.1), transparent);
        animation: scrollingLine 2s linear infinite;
      }
      
      @keyframes scrollingLine {
        0% {
          transform: translateX(-100%); /* Start off-screen to the left */
        }
        100% {
          transform: translateX(100%); /* End off-screen to the right */
        }
      }

      .scroll-button {
        display: inline-flex;
        padding: 10px;
        align-items: center;
        gap: 16px;
        border-radius: 20px;
        border: 1px solid #3C49DE;
        background: #FFF;
        cursor: pointer;
      }
    `;
  }

  render() {
    return html`
      <div class="page-container">
        <div class="row-container">
          <div class="header-container">
            <text class="header-text">Stock News</text>
            <button class="view-all-button" @click="${this.handleViewAllClick}">View All</button>
          </div>
          <div class="scroll-container">
            <button class="scroll-button" @click="${this.handleStockPreviousScroll}">
              <box-icon class="chevron-icon" name='chevron-left'></box-icon>
            </button>
            <div class="article-container" id="stockArticleContainer">
              ${this.stockNews.length === 0
                ? Array(3).fill().map(() => html`
                    <div class="article-loading"></div>
                  `)
                : this.stockNews.map(article => html`
                    <news-article-element class="article-item" .article=${article}></news-article-element>
                  `)}
            </div>
            <button class="scroll-button" @click="${this.handleStockNextScroll}">
              <box-icon class="chevron-icon" name='chevron-right'></box-icon>
            </button>
          </div>
        </div>
        <div class="row-container">
          <div class="header-container">
            <text class="header-text">Crypto News</text>
            <button class="view-all-button" @click="${this.handleViewAllClick}">View All</button>
          </div>
          <div class="scroll-container">
            <button class="scroll-button" @click="${this.handleCryptoPreviousScroll}">
              <box-icon class="chevron-icon" name='chevron-left'></box-icon>
            </button>
            <div class="article-container" id="cryptoArticleContainer">
              ${this.cryptoNews.length === 0
                ? Array(3).fill().map(() => html`
                    <div class="article-loading"></div>
                  `)
                : this.cryptoNews.map(article => html`
                    <news-article-element class="article-item" .article=${article}></news-article-element>
                  `)}
            </div>
            <button class="scroll-button" @click="${this.handleCryptoNextScroll}">
              <box-icon class="chevron-icon" name='chevron-right'></box-icon>
            </button>
          </div>
        </div>
      </div>
    `;
  }
}

customElements.define('news-element', NewsElement);