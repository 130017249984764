import { LitElement, html, css } from 'lit';

class ToastMessageElement extends LitElement {
  static get properties() {
    return {
      duration: Number,
      message: String,
    };
  }

  constructor() {
    super();

    this.duration = 3000;
    this.message = '';
  }

  connectedCallback() {
    super.connectedCallback();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }

  static get styles() {
    return css`
      :host {
        position: fixed;
        bottom: 40px;
        left: 40px;
        padding: 12px 36px;
        background-color: #333;
        color: #fff;
        border-radius: 5px;
        opacity: 0;
        transition: opacity 0.5s ease-in-out;
        z-index: 1000;
      }

      :host(.show) {
        opacity: 1;
      }
    `;
  }

  updated(changedProperties) {
    if (changedProperties.has('message')) {
      this.show();
    }
  }

  show() {
    this.classList.add('show');

    setTimeout(() => {
      this.hide();
    }, this.duration);
  }

  hide() {
    this.classList.remove('show');
  }

  render() {
    return html`${this.message}`;
  }
}

customElements.define('toast-message-element', ToastMessageElement);