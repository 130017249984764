import { LitElement, html, css } from 'lit';
import jwt_decode from "jwt-decode";
import '../app-element.js';

class LandingPageElement extends LitElement {
  static get properties() {
    return {
      loggedInDetails: { type: Object },
      isSignedIn: { type: Boolean },
    };
  }

  static get styles() {
    return css`
      .container {
        display: flex;
        justify-content: flex-end;
        padding: 20px;
      }
    `;
  }

  firstUpdated() {
    super.firstUpdated();
    setTimeout(() => {
      this.initializeGoogleSignIn();
    }, 0);
  }

  initializeGoogleSignIn() {
    const buttonDiv = this.shadowRoot.getElementById("buttonDiv");
    if (!buttonDiv) return;

    google.accounts.id.initialize({
      client_id: "939778736823-lkigeeutv9miqjo0r2q90okbudp47qce.apps.googleusercontent.com",
      callback: this.handleCredentialResponse.bind(this)
    });

    google.accounts.id.renderButton(
      buttonDiv,
      { type: "standard", theme: "filled_blue", size: "large" }
    );
  }

  handleCredentialResponse(response) {
    // Here we can do whatever process with the response we want
    // Note that response.credential is a JWT ID token
    this.loggedInDetails = jwt_decode(response.credential);
    this.isSignedIn = true;
    this.dispatchEvent(new CustomEvent('signed-in', { bubbles: true, composed: true, detail: { loggedInDetails: this.loggedInDetails, isSignedIn: this.isSignedIn }}));
  }

  render() {
    return html`
      <div class="container">
        <div id="buttonDiv"></div>
      </div>
    `;
  }
}

customElements.define('landing-page-element', LandingPageElement);