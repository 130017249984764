import { LitElement, html, css } from 'lit';

const FINANCE_API_KEY = 'f27bbd28829cfd17713d89497a16ae75';

class TickerSearchElement extends LitElement {
  static get properties() {
    return {
      activeExchange: String,
      isCardVisible: Boolean,
      searchText: String,
      searchResults: Array,
    };
  }

  constructor() {
    super();

    this.activeExchange = '';
    this.isCardVisible = false;
    this.searchText = '';
    this.searchResults = [];
  }

  connectedCallback() {
    super.connectedCallback();
    document.addEventListener('click', this.handleDocumentClick);
  }
  
  disconnectedCallback() {
    super.disconnectedCallback();
    document.removeEventListener('click', this.handleDocumentClick);
  }
  
  handleDocumentClick(evt) {
    const path = evt.composedPath();
    const clickedElement = path[0]; // last element in the path is the actual clicked element

    if (!clickedElement.closest('.card') && !clickedElement.closest('#tickerInput')) {
      this.isCardVisible = false;
    }
  }

  handleTickerFocus() {
    if (this.searchText !== '') {
      this.isCardVisible = true;
    }
  }

  handleTickerKeysUp(event) {
    this.searchText = event.target.value;

    clearTimeout(this.debounceTimer);
    this.debounceTimer = setTimeout(() => {
      if (this.searchText) {
        this.fetchSearchResults();
      } else {
        this.isCardVisible = false;
        this.dispatchEvent(new CustomEvent('ticker-cleared', {bubbles: true, composed: true, detail: {field: 'ticker'} }));
      }
    }, 400);
  }

  async fetchSearchResults() {
    try {
      const response = await fetch(`https://financialmodelingprep.com/api/v3/search-ticker?query=${this.searchText}&limit=5&exchange=${this.activeExchange}&apikey=${FINANCE_API_KEY}`);
      const data = await response.json();
      this.searchResults = data;
      this.isCardVisible = true;
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  handleExchangeButtonClick(exchange) {
    this.activeExchange = exchange;
    this.fetchSearchResults();
  }

  handleItemClick(symbol) {
    const inputElement = this.shadowRoot.querySelector('#tickerInput');
    inputElement.value = symbol;
    this.searchText = symbol;
    this.isCardVisible = false;

    this.dispatchEvent(new CustomEvent('historical-price-changed', { bubbles: true, composed: true, detail: {symbol: symbol} }));
  }

  updateSubmitDisabled() {
    this.dispatchEvent(new CustomEvent('submit-changed', { bubbles: true, composed: true }));
  }

  static get styles() {
    return css`
      input[type="text"] {
        display: flex;
        width: 330px;
        padding: 10px 10px 10px 10px;
        align-items: center;
        gap: 8px;
        border: 1px solid #EFF1F7;
      }

      .card {
        display: inline-block;
        position: absolute;
        width: 330px;
        z-index: 99;
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 10px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      }

      .exchange-buttons {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 8px;
        margin-bottom: 5px;
      }
      
      .exchange-buttons button {
        height: 30px;
        padding: 10px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        color: #2E2E3A;
        font-family: 'Montserrat', sans-serif;
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: 10px;
        letter-spacing: -0.14px;
      }

      .exchange-buttons button[active] {
        background-color: #007bff;
        color: #fff;
      }

      .search-results ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
      }

      .list-item {
        display: flex;
        flex-direction: column;
        color: #2E2E3A;
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        line-height: 10px;
        letter-spacing: -0.14px;
        gap: 5px;
        cursor: pointer;
      }
      
      .symbol {
        font-size: 14px;
        font-weight: 600;
        margin-top: 15px;
      }

      .name {
        font-size: 12px;
        font-weight: 500;
      }
    `;
  }

  render() {
    return html`
      <input
        type="text"
        id="tickerInput"
        name="ticker"
        autocomplete="off"
        placeholder="AAPL"
        @input="${this.updateSubmitDisabled}"
        @keyup=${this.handleTickerKeysUp}
        @focus=${this.handleTickerFocus}
      />
      ${this.isCardVisible
        ? html`
            <div class="card">
              <div class="exchange-buttons">
                <button
                  @click=${() => this.handleExchangeButtonClick('')}
                  ?active=${this.activeExchange === ''}
                >
                  ALL
                </button>
                <button
                  @click=${() => this.handleExchangeButtonClick('ETF')}
                  ?active=${this.activeExchange === 'ETF'}
                >
                  ETF
                </button>
                <button
                  @click=${() => this.handleExchangeButtonClick('MUTUAL_FUND')}
                  ?active=${this.activeExchange === 'MUTUAL_FUND'}
                >
                  MUTUAL FUND
                </button>
                <button
                  @click=${() => this.handleExchangeButtonClick('CRYPTO')}
                  ?active=${this.activeExchange === 'CRYPTO'}
                >
                  CRYPTO
                </button>
                <button
                  @click=${() => this.handleExchangeButtonClick('NASDAQ')}
                  ?active=${this.activeExchange === 'NASDAQ'}
                >
                  NASDAQ
                </button>
                <button
                  @click=${() => this.handleExchangeButtonClick('NYSE')}
                  ?active=${this.activeExchange === 'NYSE'}
                >
                  NYSE
                </button>
              </div>
              <div class="search-results">
                <ul>
                  ${this.searchResults.map((result) => html`
                    <li class="list-item" @click="${() => this.handleItemClick(result.symbol, result.exchange)}">
                      <span class="symbol">${result.symbol}</span>
                      <span class="name">${result.name}</span>
                    </li>
                  `)}
                </ul>
              </div>
            </div>
          `
        : ''}
    `;
  }
}

customElements.define('ticker-search-element', TickerSearchElement);
