import { LitElement, html, css } from 'lit';
import './spinner-element';
import './dropdown-element';

const GOOGLE_API_KEY = 'AIzaSyAcwLc7fO1RjJxq7a3aFAFUHafO04rdDPc';
const DISCOVERY_DOC = 'https://sheets.googleapis.com/$discovery/rest?version=v4';
const FINANCE_API_KEY = 'f27bbd28829cfd17713d89497a16ae75';

class RiskElement extends LitElement {
  static get properties() {
    return {
      consensusDataCache: Object,
      profiles: Object,
      sheetData: Object,
      spreadsheetId: String,
      tickers: Array,
    };
  }

  constructor() {
    super();
    this.loadingTreeMap = true;
    this.loadingAnalystRatings = true;
    this.loadingPriceTargets = true;
    this.consensusDataCache = {};
    this.tickers = [];
    this.spreadsheetId = localStorage.getItem('spreadsheet_id');
  }

  async firstUpdated() {
    google.charts.load('current', { packages: ['treemap', 'corechart'] });
    this.getSheetData();
  }

  async addStockEarningsToProfiles() {
    const symbols = new Set();
    const strippedSheetData = this.sheetData.slice(1);
    
    for (const row of strippedSheetData) {
      const symbol = row[2];
      symbols.add(symbol);
    }
    
    for (const symbol of symbols) {
      if (!(symbol in this.profiles)) {
        continue;
      }
    
      const earningsSurprisesUrl = `https://financialmodelingprep.com/api/v3/earnings-surprises/${symbol}?apikey=${FINANCE_API_KEY}`;
    
      const earningsSurprises = await fetch(earningsSurprisesUrl)
        .then(response => response.json());
    
      if (earningsSurprises && earningsSurprises.length > 0) {
        const lastFourQuarters = earningsSurprises.slice(0, 4);
        const sumLastFourQuarters = lastFourQuarters.reduce((sum, earnings) => sum + earnings.actualEarningResult, 0);
    
        this.profiles[symbol].actualEarningResult = sumLastFourQuarters;
      }
    }
  }

  async getStockProfiles() {
    if (!this.profiles) {
      this.profiles = {};
    }
  
    const strippedSheetData = this.sheetData.slice(1);
  
    for (const row of strippedSheetData) {
      const ticker = row[2];

      if (!(ticker in this.profiles)) {
        continue;
      }
  
      const profile = await fetch(`https://financialmodelingprep.com/api/v3/profile/${ticker}?apikey=${FINANCE_API_KEY}`)
        .then(response => response.json());
  
      if (profile && profile.length > 0) {
        this.profiles[ticker] = profile[0];
      }
    }
  }

  async createBarChart() {
    google.charts.setOnLoadCallback(this.drawBarChart.bind(this));
  }
  
  async drawBarChart() {
    const consensusData = await this.getAnalystConsensusData();
    const chartData = this.processChartData(consensusData);
  
    if (chartData.length === 0) {
      return;
    }
  
    const data = google.visualization.arrayToDataTable(chartData);
    const options = {
      height: '100%',
      width: '100%',
      chartArea: {
        height: '100%',
        width: '100%',
        right: 40,
        left: 60,
        top: 40,
        bottom: 120,
      },
      isStacked: true,
      legend: { position: 'bottom' },
      hAxis: {
        slantedText: true,
        slantedTextAngle: 45,
      },
    };
  
    const chart = new google.visualization.ColumnChart(this.shadowRoot.querySelector('#risk_analyst_ratings_div'));
    chart.draw(data, options);
  }

  async getAnalystConsensus(ticker) {
    if (ticker in this.consensusDataCache) {
      return this.consensusDataCache[ticker];
    }
  
    const response = await fetch(`https://financialmodelingprep.com/api/v4/upgrades-downgrades-consensus?symbol=${ticker}&apikey=${FINANCE_API_KEY}`);
    const data = await response.json();
  
    this.consensusDataCache[ticker] = data; // Cache the fetched data for future use
  
    return data;
  }
  
  async getAnalystConsensusData() {
    const strippedSheetData = this.sheetData.slice(1);
    const consensusData = [];
  
    for (const row of strippedSheetData) {
      const ticker = row[2];

      if (!(ticker in this.consensusDataCache)) {
        const consensus = await this.getAnalystConsensus(ticker);
        consensusData.push({ ticker, consensus });
      }
    }
  
    return consensusData;
  }
  
  processChartData(consensusData) {
    const chartData = [['Stock', 'Strong Sell', 'Sell', 'Hold', 'Buy', 'Strong Buy']];
  
    for (const data of consensusData) {
      const { ticker, consensus } = data;
  
      if (consensus.length !== 0) {
        const { strongSell, sell, hold, buy, strongBuy } = consensus[0];
        chartData.push([ticker, strongSell, sell, hold, buy, strongBuy]);
      }
    }
  
    return chartData;
  }

  async createTreeMap() {
    await this.getStockProfiles();
    await this.addStockEarningsToProfiles();
    google.charts.setOnLoadCallback(this.drawTreeMap.bind(this));
  }
  
  async drawTreeMap() {
    const data = new google.visualization.DataTable();
    data.addColumn('string', 'Stock');
    data.addColumn('string', 'Parent');
    data.addColumn('number', 'Total Value');
    data.addColumn('number', 'P/E Ratio');
    const dataTable = await this.getTreeMapData(data);
  
    if (dataTable.length === 0) {
      return;
    }
  
    const options = {
      height: '100%',
      width: '100%',
      chartArea: {
        height: '100%',
        width: '100%',
        right: 40,
        left: 60,
        top: 40,
        bottom: 120,
      },
      headerHeight: 0,
      fontSize: 20,
      minColor: '#84D579', // Softer shade of green with less white
      midColor: '#F7EA8C', // Softer shade of yellow with less white
      maxColor: '#F27070', // Softer shade of red with less white
      tooltip: {
        textStyle: {
          color: '#ffffff',
        },
      },
      scalePosition: 'bottom',
      generateTooltip: showFullTooltip,
      showScale: true,
    };
  
    function showFullTooltip(row) {
      const stock = dataTable.getValue(row, 0);
      const totalValue = dataTable.getValue(row, 2);
      const peRatio = dataTable.getValue(row, 3);
  
      return '<div style="background:#f9f9f9; padding:10px; border-style:solid">' +
        '<span><b>Stock: </b>' + stock + '</span><br>' +
        '<span><b>Total Value: </b>$' + totalValue + '</span><br>' +
        '<span><b>P/E Ratio: </b>' + peRatio + '</span></div>';
    }
  
    const chart = new google.visualization.TreeMap(this.shadowRoot.querySelector('#risk_tree_map_div'));
    chart.draw(data, options);
  }
  
  async getTreeMapData(dataTable) {
    const strippedSheetData = this.sheetData.slice(1);
    const stockMap = new Map();

    // Iterate over the rows of sheetData
    strippedSheetData.forEach((row) => {
      const ticker = row[2];
      const totalValue = parseFloat(row[8]);
      const peRatio = (this.profiles[ticker].price / this.profiles[ticker].actualEarningResult) || null;

      if (peRatio === null) {
        return;
      }

      if (stockMap.has(ticker)) {
        // If stock already exists in the map, update the total value
        const existingTotalValue = stockMap.get(ticker).totalValue;
        stockMap.set(ticker, {
          totalValue: existingTotalValue + totalValue,
          peRatio: peRatio
        });
      } else {
        // If stock does not exist in the map, add it
        stockMap.set(ticker, {
          totalValue: totalValue,
          peRatio: peRatio
        });
      }
    });
  
    // Create the root node for the TreeMap
    dataTable.addRow(['Stocks', null, 0, 0]);

    // Iterate over the stockMap to populate the DataTable
    stockMap.forEach((stockData, ticker) => {
      dataTable.addRow([ticker, 'Stocks', stockData.totalValue, stockData.peRatio]);
    });
  
    return dataTable;
  }

  async getSheetData() {
    if (!this.spreadsheetId) {
      return;
    }

    try {
      await gapi.load('client', () => {
        gapi.client.init({
          apiKey: GOOGLE_API_KEY,
          discoveryDocs: [DISCOVERY_DOC],
        }).then(() => {
          gapi.client.load('sheets', 'v4', () => {
            gapi.client.sheets.spreadsheets.values.get({
              spreadsheetId: this.spreadsheetId,
              range: 'Sheet1!A:I',
            }).then((response) => {
              this.sheetData = response.result.values;
              const strippedSheetData = this.sheetData.slice(1);
              strippedSheetData.forEach(row => {
                const ticker = row[2];
                if (!this.tickers.includes(ticker)) {
                  this.tickers.push(ticker);
                }
              });
              this.loadingTreeMap = false;
              this.loadingAnalystRatings = false;
              this.loadingPriceTargets = false;
              this.createTreeMap();
              this.createBarChart();
            });
          });
        });
      });
    } catch (error) {
      throw new Error(`Error: ${error.result.error.message}`);
    }
  }

  static get styles() {
    return css`
      .risk-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        padding: 20px 0px 20px 0px;
        font-family: Arial, sans-serif;
      }

      .risk-card {
        width: 70%;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        background-color: #fff;
        margin: 10px;
      }
      
      .risk-card-header {
        font-size: 24px;
        font-weight: bold;
        padding: 10px 10px 0;
        margin: 0;
        color: #999;
      }

      .no-data {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        color: #999999;
      }

      .no-data box-icon {
        width: 36px;
        height: 36px;
        fill: #999999;
      }

      .dropdown-container {
        display: flex;
        justify-content: space-between;
        border-radius: 10px 10px 0 0;
        align-items: center;
        padding: 10px;
        background-color: #ddd;
        border-bottom: 2px solid #cbcbcb;
      }

      .dropdown {
        width: 30%;
      }

      .risk-card.card-1 {
        min-height: 80vh;
      }
      
      .risk-card.card-2 {
        min-height: 60vh;
      }

      .risk-card.card-3 {
        min-height: 50vh;
      }

      #risk_price_target_div {
        height: 45vh;
      }

      #risk_tree_map_div {
        height: 75vh;
      }

      #risk_analyst_ratings_div {
        height: 55vh;
      }
    `;
  }

  render() {
    return html`
      <div class="risk-container">
        <div class="risk-card card-1">
          <h2 class="risk-card-header">Risk Tree Map</h2>
          ${this.loadingTreeMap
            ? html`<spinner-element></spinner-element>`
            : this.sheetData
              ? html`
                <div id="risk_tree_map_div"></div>
              `
              : html`
                <div class="no-data">
                  <box-icon name='sad'></box-icon>
                  <p>You have not added any positions yet</p>
                </div>
              `
          }
        </div>
        <div class="risk-card card-2">
          <h2 class="risk-card-header">Analyst Ratings</h2>
          ${this.loadingAnalystRatings
            ? html`<spinner-element></spinner-element>`
            : this.sheetData
              ? html`
                <div id="risk_analyst_ratings_div"></div>
              `
              : html`
                <div class="no-data">
                  <box-icon name='sad'></box-icon>
                  <p>You have not added any positions yet</p>
                </div>
              `
          }
        </div>
        <div class="risk-card card-3">
          ${this.tickers.length > 0 ? html`
            <div class="dropdown-container">
              <dropdown-element class="dropdown" @option-selected=${this.handleOptionChanged} .items=${this.tickers}></dropdown-element>
            </div>
          ` : null}
          ${this.loadingPriceTargets
            ? html`<spinner-element></spinner-element>`
            : this.sheetData
              ? html`
                <div class="chart" id="risk_price_target_div"></div>
              `
              : html`
                <div class="no-data">
                  <box-icon name='sad'></box-icon>
                  <p>You have not added any positions yet</p>
                </div>
              `
          }
        </div>
      </div>
    `;
  }
}

customElements.define('risk-element', RiskElement);