import { LitElement, html, css } from 'lit';
import './modal-element';
import '../datepicker-input-element';

const FINANCE_API_KEY = 'f27bbd28829cfd17713d89497a16ae75';
const GOOGLE_API_KEY = 'AIzaSyAcwLc7fO1RjJxq7a3aFAFUHafO04rdDPc';
const DISCOVERY_DOC = 'https://sheets.googleapis.com/$discovery/rest?version=v4';

class EditRowModal extends LitElement {
  static get properties() {
    return {
      currentRow: Object,
      date: String,
      fee: Number,
      numberOfShares: Number,
      price: Number,
      rowNumber: Number,
      sheetId: String,
      spreadsheetId: String,
      ticker: String,
      totalValue: Number,
    };
  }

  constructor() {
    super();
    this._boundDateChange = this.handleDateChanged.bind(this);
    this.date = new Date().toLocaleDateString('en-US');
    this.totalValue = 0;

    gapi.load('client', () => {
      gapi.client.init({
        apiKey: GOOGLE_API_KEY,
        discoveryDocs: [DISCOVERY_DOC],
      }).then(() => {
        gapi.client.load('sheets', 'v4', () => {
          this.getSheetId();
        });
      });
    });
  }

  connectedCallback() {
    super.connectedCallback();
    document.addEventListener('date-changed', this._boundDateChange);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    document.removeEventListener('date-changed', this._boundDateChange);
  }

  willUpdate(changedProperties) {
    if (
      changedProperties.has('currentRow')
    ) {
      this.date = this.currentRow.date;
      this.fee = this.currentRow.fee;
      this.numberOfShares = this.currentRow.numberOfShares;
      this.price = this.currentRow.price;
      this.ticker = this.currentRow.ticker;
      this.totalValue = (Number(this.currentRow.price) * Number(this.currentRow.numberOfShares)) + Number(this.currentRow.fee);
    }
  }

  async getSheetId() {
    try {
      const sheetName = 'Sheet1';
      const sheet = await gapi.client.sheets.spreadsheets.get({
        spreadsheetId: this.spreadsheetId,
        ranges: [sheetName],
        includeGridData: false,
      });
      this.sheetId = sheet.result.sheets[0].properties.sheetId;
    } catch (error) {
      console.error(error);
    }
  }

  async getStockHistoryRange(ticker, date) {
    const dateStr = new Date(date);
    const year = dateStr.getFullYear();
    const month = (dateStr.getMonth() + 1).toString().padStart(2, '0');
    const day = dateStr.getDate().toString().padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    return await fetch(`https://financialmodelingprep.com/api/v3/historical-price-full/${ticker}?from=${formattedDate}&to=${formattedDate}&apikey=${FINANCE_API_KEY}`)
      .then(response => response.json());
  }

  handleCloseClick() {
    const modalElement = this.shadowRoot.querySelector('modal-element');
    modalElement.removeAttribute('show');
  }

  async handleDateChanged(evt) {
    this.date = evt.detail.date;
    this.keysTypedHistoricalPriceDebounce();
  }

  async handleHistoricPriceInput() {
    if (!this.ticker || !this.date) {
      return;
    }

    const stockHistory = await this.getStockHistoryRange(this.ticker, this.date);
    this.historicalOpenPrice = Object.values(stockHistory).length !== 0 ? stockHistory.historical[0].open : null;
  }

  async handleSubmit() {
    const form = this.shadowRoot.querySelector('form');
    const formData = new FormData(form);

    const dateStr = new Date(this.date);
    const month = (dateStr.getMonth() + 1).toString().padStart(2, '0');
    const day = dateStr.getDate().toString().padStart(2, '0');
    const year = dateStr.getFullYear().toString();
    const formattedDate = `${month}/${day}/${year}`;
    const date = formattedDate;

    const type = this.currentRow.type;
    const ticker = this.currentRow.ticker;
    const price = formData.get('price');
    const numberOfShares = formData.get('number_of_shares');
    const fee = formData.get('fee');
    const currentPrice = this.currentRow.currentPrice;
    const totalPurchaseAmount = (parseFloat(price) * parseFloat(numberOfShares)) + parseFloat(fee);
    const totalValueNow = parseFloat(currentPrice) * parseFloat(numberOfShares);
    const totalGainLoss = totalValueNow - totalPurchaseAmount;
    const totalGainLossPercent = (totalGainLoss / totalPurchaseAmount) * 100;
    const assetClass = this.currentRow.assetClass;
    const sector = this.currentRow.sector;

    const updatedRow = [
      date,
      type,
      ticker,
      price,
      numberOfShares,
      fee,
      currentPrice,
      totalPurchaseAmount,
      totalValueNow,
      totalGainLoss,
      totalGainLossPercent,
      assetClass,
      sector
    ];

    await gapi.client.sheets.spreadsheets.values.update({
      spreadsheetId: this.spreadsheetId,
      range: `Sheet1!A${this.rowNumber + 1}:M${this.rowNumber + 1}`,
      valueInputOption: 'RAW',
      resource: {
        values: [updatedRow],
      },
    }).then(() => {
      console.log(`Row ${this.rowNumber} updated successfully.`);
    }).catch((error) => {
      console.error('Error updating row:', error);
    });

    this.dispatchEvent(new CustomEvent('load-current-positions', { bubbles: true, composed: true }));
  }

  handleTickerKeysUp(evt) {
    this.ticker = evt.target.value;
    if (this.ticker) {
      this.keysTypedHistoricalPriceDebounce();
    } else {
      this.historicalOpenStockPrice = null;
    }
  }

  handleTotalValueKeysUp() {
    const priceInput = this.shadowRoot.querySelector('#price');
    const numberOfSharesInput = this.shadowRoot.querySelector('#number_of_shares');
    const feeInput = this.shadowRoot.querySelector('#fee');
    
    const price = Number(priceInput.value) || (this.historicalOpenStockPrice || 0);
    const numberOfShares = Number(numberOfSharesInput.value) || 0;
    const fee = Number(feeInput.value) || 0;
    
    this.totalValue = (price * numberOfShares) + fee;
  }

  keysTypedHistoricalPriceDebounce() {
    setTimeout(() => {
      this.handleHistoricPriceInput();
    }, 800);
  }

  static get styles() {
    return css`
      .form-container {
        display: inline-flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
      }

      form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
      }
      
      label {
        display: block;
        color: #2E2E3A;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: -0.14px;
      }
      
      input[type="text"],
      input[type="number"] {
        display: flex;
        width: 330px;
        padding: 10px 10px 10px 10px;
        align-items: center;
        gap: 8px;
        border: 1px solid #EFF1F7;
      }

      .datepicker-input {
        --input-width: 330px;
      }
      
      .form-field {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
      }

      .checkbox-field {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
      }

      .modal-well {
        display: flex;
        justify-content: center;
        width: 312px;
        height: 20px;
        flex-shrink: 0;
        align-items: center;
        background: #F2F3F7;
        padding: 20px;
        margin: 0 0 20px 0;
      }

      .total-container {
        display: inline-flex;
        align-items: center;
        gap: 6px;
      }
    
      .total {
        color: #2E2E3A;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: -0.14px;
      }

      .total-value {
        color: #2E2E3A;
        font-family: 'Montserrat', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: -0.2px;
      }

      .checkbox-container {
        display: flex;
        align-items: center;
      }

      .checkbox-input {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        width: 20px;
        height: 20px;
        border: 2px solid #999;
        border-radius: 3px;
        margin-left: 8px;
        outline: none;
        cursor: pointer;
      }
    `;
  }
  
  render() {
    return html`
      <modal-element class="modal-element" @close=${this.handleCloseClick} @submit=${this.handleSubmit}>
        <div slot="modal-slot" class="form-container">
          <form>
            <div class="form-field">
              <label for="ticker" class="input-box-label">Ticker</label>
              <input type="text" id="ticker" name="ticker" placeholder="AAPL" .value="${this.ticker}" @keyup=${this.handleTickerKeysUp} disabled />
            </div>

            <div class="form-field">
              <label for="date" class="input-box-label">Transaction Date</label>
              <datepicker-input-element class="datepicker-input" placeholder="Transaction Date" .date=${this.date}></datepicker-input-element>
            </div>

            <div class="form-field">
              <label for="price" class="input-box-label">Price (per share)</label>
              <input type="number" id="price" name="price" placeholder="${this.historicalOpenStockPrice || '1000.00'}" .value="${this.price}" @keyup=${this.handleTotalValueKeysUp} />
            </div>

            <div class="form-field">
              <label for="number_of_shares" class="input-box-label">Number of shares</label>
              <input type="number" id="number_of_shares" name="number_of_shares" placeholder="100.00" .value="${this.numberOfShares}" @keyup=${this.handleTotalValueKeysUp} />
            </div>
  
            <div class="form-field">
              <label for="fee" class="input-box-label">Brokerage Fee</label>
              <input type="number" id="fee" name="fee" placeholder="10.00" .value="${this.fee}" @keyup=${this.handleTotalValueKeysUp} />
            </div>
          </form>
          <div class="modal-well">
            <div class="total-container">
              <div class="total">Total = </div><div class="total-value">$${this.totalValue.toFixed(2)}</div>
            </div>
          </div>
        </div>
      </modal-element>
    `;
  }
}

customElements.define('edit-row-modal', EditRowModal);