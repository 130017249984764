import { LitElement, html, css } from 'lit';

class BenchmarkStatsCardElement extends LitElement {
  static get properties() {
    return {
      benchmarks: { type: Array },
      isBenchmarking: { type: Boolean },
      loadingBenchmarks: { type: Boolean },
      noBenchmarks: { type: Boolean },
      spreadsheetId: { type: String },
    };
  }

  constructor() {
    super();
    this.benchmarks = [];
    this.spreadsheetId = localStorage.getItem('spreadsheet_id');
  }

  async connectedCallback() {
    super.connectedCallback();
  }

  willUpdate(changedProperties) {
    if (
      changedProperties.has('loadingBenchmarks')
    ) {
      if (this.loadingBenchmarks) {
        setTimeout(() => {   
          this.loadingBenchmarks = false;
        }, 500);
      }
    }
  }

  handleNewBenchmarkClick() {
    this.dispatchEvent(new CustomEvent('open-benchmark-modal', { bubbles: true, composed: true }));
  }

  static get styles() {
    return css`
      .list-container {
        display: inline-flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 16px;
      }

      .benchmark-stats-card {
        display: flex;
        width: 806px;
        min-height: 56px; /* Use min-height instead of height */
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        overflow: hidden;
        background-color: #fff;
        flex-direction: row;
        justify-content: space-between;
        padding: 24px;
      }

      .empty-card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 806px;
        min-height: 224px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        background-color: #fff;
        padding: 24px;
      }

      .loading-card {
        display: flex;
        justify-content: center;
        width: 806px;
        min-height: 56px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        background-color: #fff;
        padding: 24px;
      }

      .empty-message-icon-container {
        display: inline-flex;
        justify-content: center;
      }

      .empty-message-icon {
        width: 75px;
        height: 75px;
      }

      .empty-message {
        display: inline-flex;
        justify-content: center;
        color: #2E2E3A;
        font-family: 'Montserrat', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: -0.24px;
      }

      .empty-message-link {
        display: inline-flex;
        justify-content: center;
        cursor: pointer;
        text-decoration: underline;
        color: #2E2E3A;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: -0.24px;
      }

      .card-container {
        display: inline-flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 42px;
      }

      .header-container {
        display: inline-flex;
        width: 240px;
        flex-direction: column;
        align-items: flex-start;
      }

      .content-container {
        display: inline-flex;
        width: 524px;
        align-items: flex-start;
        gap: 24px;
      }

      .content-block {
        display: inline-flex;
        width: 150px;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
      }
      
      .content-container:first-child {
        text-align: left;
      }

      .content-header {
        color: #9A9AAF;
        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 12px;
        letter-spacing: -0.16px;
      }

      .content-value-container {
        display: flex;
        gap: 6px;
      }

      .content-value {
        color: #2E2E3A;
        font-family: 'Montserrat', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: -0.24px;
      }

      .content-value-small {
        align-items: end;
        color: #F84439;
        font-family: 'Montserrat', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: -0.14px;
      }

      .positive {
        color: #24CA49;
      }
      
      .negative {
        color: #F84439;
      }
    `;
  }
  
  render() {
    if (this.noBenchmarks) {
      return html`
          <div class="list-container">
              <div class="empty-card">
                <div class="empty-message-icon-container">
                  <box-icon class="empty-message-icon" name='bar-chart-square'></box-icon>
                </div>
                <text class="empty-message">You don't have any benchmarks added yet</text>
                <a class="empty-message-link" @click=${this.handleNewBenchmarkClick}>Add a benchmark now</a>
              </div>
          </div>
      `;
    }

    return html`
      <div class="list-container">
        ${this.benchmarks.length === 0
          ? html`
              <div class="loading-card">
                <spinner-element></spinner-element>
              </div>
            `
          : this.benchmarks.map(benchmark => {
              const dividendCountPercent = parseFloat(benchmark.dividendCountPercent);
              const capitalGainPercent = parseFloat(benchmark.capitalGainPercent);
              const portfolioReturnPercent = parseFloat(benchmark.portfolioReturnPercent);

              return html`
                <div class="benchmark-stats-card">
                  <div class="card-container">
                    <div class="header-container">
                      <text class="content-header">${benchmark.symbol}</text>
                      <text class="content-value">${benchmark.name}</text>
                    </div>
                    <div class="content-container">
                      <div class="content-block">
                        <text class="content-header">Dividends</text>
                        <div class="content-value-container">
                          <div class="content-value">$${benchmark.dividendCount}</div>
                          <div class="content-value-small ${dividendCountPercent >= 0 ? 'positive' : 'negative'}">(${benchmark.dividendCountPercent}%)</div>
                        </div>
                      </div>
                      <div class="content-block">
                        <text class="content-header">Capital Gains</text>
                        <div class="content-value-container">
                          <div class="content-value">$${benchmark.capitalGain}</div>
                          <div class="content-value-small ${capitalGainPercent >= 0 ? 'positive' : 'negative'}">(${benchmark.capitalGainPercent}%)</div>
                        </div>
                      </div>
                      <div class="content-block">
                        <text class="content-header">Total Return</text>
                        <div class="content-value-container">
                          <div class="content-value">$${benchmark.portfolioReturn}</div>
                          <div class="content-value-small ${portfolioReturnPercent >= 0 ? 'positive' : 'negative'}">(${benchmark.portfolioReturnPercent}%)</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              `;
          })}
      </div>
    `;
  }
}

customElements.define('benchmark-stats-card-element', BenchmarkStatsCardElement);
