import { LitElement, html, css } from 'lit';
import './modal-element';

class ChangelogModal extends LitElement {
  static get properties() {
    return {
      releaseDate: String,
    };
  }

  constructor() {
    super();
  }

  connectedCallback() {
    super.connectedCallback();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }

  handleCloseClick() {
    const modalElement = this.shadowRoot.querySelector('modal-element');
    modalElement.removeAttribute('show');
  }

  static get styles() {
    return css`
      .changelog-text {
        margin-bottom: 20px;
        color: #2E2E3A;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: -0.14px;
        max-height: 500px;
        overflow-y: auto;
      }

      .modal-element {
        --text-alignment: flex-start;
      }
    `;
  }
  
  render() {
    return html`
      <modal-element class="modal-element" @close=${this.handleCloseClick} isOneButton="${true}">
        <div slot="modal-slot">
          <div class="changelog-text">
            <h1>Changelog</h1>
            <p class="paragraph-body">
              v0.1.5.beta.1 - Apr 1, 2024
              <ol>
                <li>Fix bug where sometimes a sold position row would not be deleted</li>
                <li>Fix bug where the consolidated price number was wrongly calculating the weighted average of prices</li>
                <li>Fix bugs causing errors when the spreadsheet was just created or had no title rows and when there was no data at all - now we show all no data states properly</li>
                <li>Add commas and 2 decimal points for all numbers</li>
                <li>Updated current price placeholder to be above the current price input box - now you can click the current price placeholder to fill the price input box</li>
                <li>Fix bug where trying to delete a single row in individual positions would instead delete all rows for that ticker</li>
                <li>Capped date picker dropdown boxes so they no longer add a scroll bar to the buy/sell form</li>
                <li>Fix bug causing large negative returns to show as NaN</li>
                <li>Fix bug causing the total/gain loss(%) to show infinity when selling split shares</li>
              </ol><br>
              v0.1.4.beta.1 - Jan 26, 2024
              <ol>
                <li>NEW FEATURE: Reinvested rows are now the row type for dividends that have actually been reinvested - which is done through a new "All Dividends" section as part of the current positions</li>
                <ul>
                  <li>Dividend rows are no longer added automatically when adding a buy trade (though splits still will). You will need to manually change over dividends to be reinvested</li>
                </ul>
                <li>Rename "Total Value" chart to "Current Performance" to address that it looks at current positions only</li>
                <li>Fix a bug in the summary cards on the dashboard breaking when a split or dividend was the first row</li>
                <li>Added an error message when the user tries to sell more shares than they have at the specified date in the new trade modal</li>
                <li>Add a UUID field for current positions / sold positions and the Old Type field for sold positions (BREAKING CHANGE - you need to delete all information in Sheet1 and Sheet2)</li>
                <li>Add sell trades to the net equity chart</li>
                <li>Automatically check splits every 24 hours and add them to the spreadsheet</li>
                <li>Fix the bug that was causing split shares to lower the consolidated views weighted share price</li>
                <li>Fix a bug when the split was the first in the historical list, it not showing up automatically</li>
              </ol><br>
              v0.1.3.beta.1 - Nov 7, 2023
              <ol>
                <li>Change dashboard and performance area chart x-axis labels to be a bit more visually appealing</li>
                <li>Fix a problem where the benchmarks of the performance section had wrong ending data when viewing on a weekend day</li>
                <li>Disable the sell radio button if the user doesn't have that specific stock in their current positions to sell</li>
                <li>Fix problem with dates being off by 1 month</li>
                <li>Make sure authentication triggers when switching between nav items - not just when reloading</li>
              </ol><br>
              v0.1.2.beta.1 - Oct 30, 2023
              <ol>
                <li>Disable the submit button until every field is filled out when adding manual trades</li>
                <li>Fix bug causing graphs not to load randomly</li>
                <li>Fix some messages not loading when no data exists in current positions</li>
              </ol><br>
              v0.1.1.beta.1 - Oct 28, 2023
              <ol>
                <li>Add a "What's New" modal that will show when the most recent changes on first load</li>
                <li>Add a changelog nav menu modal to view any changes made since Beta release</li>
                <li>Fix wrong margin on dashboard net equity chart</li>
              </ol><br>
              v0.1.0.beta.1 - Oct 26, 2023
              <ol>
                <li>Initial Beta release</li>
              </ol><br>
            </p>
          </div>
        </div>
      </modal-element>
    `;
  }
}

customElements.define('changelog-modal', ChangelogModal);