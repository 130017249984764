import { LitElement, html, css } from 'lit';

import bell from './images/bell.svg';
import message from './images/message.svg';

const GOOGLE_API_KEY = 'AIzaSyAcwLc7fO1RjJxq7a3aFAFUHafO04rdDPc';
const DISCOVERY_DOC = 'https://sheets.googleapis.com/$discovery/rest?version=v4';

class HeaderBarElement extends LitElement {
  static get properties() {
    return {
      loggedInDetails: { type: Object },
      pageTitle: { type: String },
      showDropdown: { type: Boolean },
      spreadsheetId: { type: String },
    };
  }

  constructor() {
    super();
    this.loggedInDetails = null;
    this.pageTitle = '';
    this.showDropdown = false;
    this.spreadsheetId = localStorage.getItem('spreadsheet_id');
  }

  firstUpdated() {
    super.firstUpdated();
    this.updateLoginData();
  }

  handleDropdownClick() {
    this.showDropdown = !this.showDropdown;
    const chevronIcon = this.shadowRoot.querySelector('.chevron-down-icon');
    chevronIcon.classList.toggle('rotate');
  }

  handleSignoutClick() {
    this.dispatchEvent(new CustomEvent('signout'));
  }

  async updateLoginData() {
    if (!this.spreadsheetId) {
      return;
    }
  
    try {
      // Load the Google API client first
      // TODO: We should be loading client way before this even happens anyway
      await new Promise((resolve, reject) => {
        gapi.load('client', {
          callback: resolve,
          onerror: reject,
          timeout: 10000,
          ontimeout: reject,
        });
      });
      
      await gapi.client.init({
        apiKey: GOOGLE_API_KEY,
        discoveryDocs: [DISCOVERY_DOC],
      });
      await gapi.client.load('sheets', 'v4');
  
      const range = 'Sheet3!A1:C1';
      const response = await gapi.client.sheets.spreadsheets.values.get({
        spreadsheetId: this.spreadsheetId,
        range: range,
      });
      const values = response.result ? response.result.values : null;
  
      if (!values && this.loggedInDetails) {
        await gapi.client.sheets.spreadsheets.values.update({
          spreadsheetId: this.spreadsheetId,
          range: range,
          valueInputOption: 'RAW',
          resource: {
            values: [
              [this.loggedInDetails.name, this.loggedInDetails.email, this.loggedInDetails.picture],
            ],
          },
        });
      } else {
        // We have the values, set the logged-in details for reloading this page
        this.loggedInDetails = {
          name: values[0][0],
          email: values[0][1],
          picture: values[0][2],
        };
      }
    } catch (error) {
      console.error(error);
    }
  }

  static get styles() {
    return css`
      :host {
        display: flex;
        z-index: 2;
      }

      .header-left {
        float: left;
        margin: 26px 0px 26px 48px;
        color: #2E2E3A;
        font-family: 'Montserrat', sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: -0.24px;
      }

      .header-right {
        display: inline-flex;
        align-items: flex-start;
        float: right;
        margin-right: 48px;
        margin-left: auto;
      }

      .dropdown-toggle {
        display: flex;
        color: #2E2E3A;
        fill: #2E2E3A;
        align-items: center;
        cursor: pointer;
        margin-top: 15px;
      }

      .bubble {
        width: 44px;
        height: 44px;
        border-radius: 24px;
        background: #EFF1F7;
        margin-top: 15px;
        margin-right: 20px;
      }

      .bubble img {
        width: 20px;
        height: 20px;
        margin-left: 12px;
        margin-top: 12px;
      }

      .profile-picture {
        width: 44px;
        height: 44px;
        border-radius: 44px;
        margin-right: 10px;
      }

      .chevron-down-icon {
        display: flex;
        align-items: center;
        width: 24px;
        height: 24px;
        margin-top: 10px;
        margin-left: 10px;
        margin-bottom: 10px;
        transition: transform 0.2s ease-in-out;
      }

      .chevron-down-icon.rotate {
        transform: rotate(180deg);
      }

      .name {
        color: #2E2E3A;
        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: -0.16px;
      }

      .email {
        color: #535362;
        font-family: 'Montserrat', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: 12px;
        letter-spacing: -0.12px;
      }

      .dropdown-menu {
        position: absolute;
        width: 180px;
        top: 25px;
        background-color: #ffffff;
        border: 1px solid #cccccc;
        border-radius: 5px;
        padding: 10px;
        z-index: 10;
        pointer-events: auto;
        transition: opacity 0.2s ease-in-out;
        position: relative;
        left: 47px;
      }

      .dropdown-menu::before,
      .dropdown-menu::after {
        content: "";
        display: block;
        position: absolute;
        border-style: solid;
        border-width: 10px;
        left: 80%;
      }

      .dropdown-menu::before {
        border-color: transparent transparent #cccccc transparent;
        top: -20px;
      }

      .dropdown-menu::after {
        border-color: transparent transparent #ffffff transparent;
        top: -18px;
        z-index: 1;
      }

      .dropdown-item {
        display: block;
        padding: 5px 0;
        cursor: pointer;
      }

      .dropdown-item:hover {
        background-color: #f0f0f0;
      }
    `;
  }
  
  render() {
    return html`
      <div class="header-left">${this.pageTitle}</div>
      <div class="header-right">
        ${this.loggedInDetails ? html`
          <div class="bubble">
            <img src="${message}" alt="Message" />
          </div>
          <div class="bubble">
            <img src="${bell}" alt="Bell" />
          </div>
          <div class="dropdown">
            <div class="dropdown-toggle" @click="${this.handleDropdownClick}">
              <img src="${this.loggedInDetails.picture}" alt="Profile Picture" class="profile-picture" referrerpolicy="no-referrer">
              <div>
                <div class="name">${this.loggedInDetails.name}</div>
                <div class="email">${this.loggedInDetails.email}</div>
              </div>
              <div class="chevron-down-icon">
                <box-icon name='chevron-down' name="chevron-down"></box-icon>
              </div>
            </div>
            ${this.showDropdown ? html`
              <div class="dropdown-menu">
                <div class="dropdown-item" @click="${this.handleSignoutClick}">Logout</div>
              </div>
            ` : null}
          </div>
        ` : null}
      </div>
    `;
  }
}

customElements.define('header-bar-element', HeaderBarElement);